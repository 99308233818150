//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { msgData } from "../api/charts";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import "@/utils/simfang-normal.js";
export default {
  data() {
    return {
      data: [],
      dayPowerGenerationNum: 0,
      dayConsumePowerNum: 0,
      dayResidueCaloric: 0,
      dayPowerConsumption: 0,
      dayGasVolume: 0,
      timer: null,
      rawData: null,
      columns: [],
      rows: []
    };
  },
  mounted() {
    this.getRestult();
    const that = this;
    this.timer = setInterval(() => {
      that.getRestult();
    }, 60000);
  },
  methods: {
    getRestult() {
      msgData().then(res => {
        this.test = Number(res.data.dayPowerGenerationNum).toFixed(2);
        this.dayPowerGenerationNum = Number(res.data.dayPowerGenerationNum).toFixed(2);
        this.dayConsumePowerNum = Number(res.data.dayConsumePowerNum).toFixed(2);
        this.dayResidueCaloric = Number(res.data.dayResidueCaloric).toFixed(2);
        this.dayPowerConsumption = Number(res.data.dayPowerConsumption).toFixed(2);
        this.dayGasVolume = Number(res.data.dayGasVolume).toFixed(2);
        this.rawData = res.data; // 存储原始数据
      }).catch();
    },
    exportToPdf() {
      if (!this.rawData) {
        console.error("No data to export.");
        return;
      }

      //const doc = new jsPDF();
      // 添加中文字体

      // 添加中文字体

      // doc.setFont("simfang");
      // 定义表格的列标题和对应的字段名
      const columns = [{
        header: "字段名",
        dataKey: "name"
      }, {
        header: "数值",
        dataKey: "value"
      }];

      // 转换数据为适合生成表格的格式
      const rows = [{
        name: "  日累计",
        value: "  日累计数值"
      }, {
        name: "光伏发电量",
        value: this.dayPowerGenerationNum
      }, {
        name: "光伏消纳量",
        value: this.dayConsumePowerNum
      }, {
        name: "余热回收量",
        value: this.dayResidueCaloric
      }, {
        name: "电网耗电量",
        value: this.dayPowerConsumption
      }, {
        name: "耗气量",
        value: this.dayGasVolume
      }];
      //  this.columns = [...columns];
      //  this.rows = [...rows];
      //  console.log(this.columns, this.rows);
      let obj = {
        columns: columns,
        rows: rows
      };
      return obj;
      // 使用 autotable 生成表格
      // doc.autoTable({
      //   columns: columns,
      //   body: rows,
      //   styles: { font: "simfang" },
      // });

      // // 保存 PDF 文件
      // doc.save("table_msg.pdf");
    }
  }
};