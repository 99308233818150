//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tableData: {
      type: Array,
      // 或者 Object，具体取决于 `tableData` 的类型
      required: true // 如果这个属性是必须的
    }
  },

  data() {
    return {
      dialogVisible: false,
      tableDataShow: []
      //   tableData: [],
    };
  },

  components: {
    // ElButton,
    // ElDialog,
  },
  mounted() {},
  methods: {
    formatTimestamp(row, column, cellValue) {
      // console.log(cellValue, "cellValue");
      if (!cellValue) return "";
      const date = new Date(cellValue * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    tableRowClassName({
      row,
      rowIndex
    }) {
      // console.log(row, rowIndex, "tableRowClassName");
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    closeDialog() {
      console.log("单击了关闭按钮");
      this.$emit("closeAlarmInfo", null);
    },
    handleClose() {
      console.log("单击了关闭按钮");
      this.$emit("closeAlarmInfo", null);
    }
  },
  watch: {}
};