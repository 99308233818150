import "core-js/modules/es.array.push.js";
//
//
//
//
//
//

import { allEquipmentSearch } from "../../../api/charts";
export default {
  props: {
    deviceType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: "",
      xAxisArr: [],
      chartData: [],
      // getSearchData: {},
      params: {},
      result: {}
    };
  },
  props: ["searchData", "chooseFromMapIdArr"],
  mounted() {},
  methods: {
    getData6() {
      // console.log(this.searchData,"searchData==============")
      let paramsType = [],
        paramsArr = [];
      let key = this.searchData.code;
      let paramsCode = this.searchData.paramsCode;
      paramsType.push(this.searchData.code);
      paramsArr.push(paramsCode);
      this.searchData.chartTimeRange == null ? this.searchData.chartTimeRange = "" : this.searchData.chartTimeRange = this.searchData.chartTimeRange;
      let startTime = this.searchData.chartTimeRange != "" ? this.searchData.chartTimeRange[0].getTime().toString() : "";
      let endTime = this.searchData.chartTimeRange != "" ? this.searchData.chartTimeRange[1].getTime().toString() : "";
      this.params.createdStartTime = this.searchData.chartTimeRange != "" ? startTime.substring(0, startTime.length - 3) : "";
      this.params.createdEndTime = this.searchData.chartTimeRange != "" ? endTime.substring(0, endTime.length - 3) : "";
      this.params.paramType = paramsType;
      this.params.deviceId = this.searchData.childrenId;
      this.params.params = paramsArr;
      // console.log(this.params,"params")
      allEquipmentSearch(this.params).then(res => {
        // console.log(res,"====================")
        // 没选时间
        if (this.searchData.chartTimeRange == "" || this.searchData.chartTimeRange == null) {
          if (this.searchData.radio == "余热回收系统" && this.searchData.params == "余热回收效率" || this.searchData.radio == "空气源热泵" && this.searchData.params == "绿电占比" || this.searchData.radio == "高温水源热泵机房" && this.searchData.params == "绿电占比") {
            if (res.data[key].length != 0 && res.data[key] != undefined) {
              let result = [];
              res.data[key].forEach(element => {
                result.push({
                  name: element.name,
                  value: element[paramsCode] == null ? 0 : element[paramsCode]
                });
              });
              this.pieCharts(result, key);
            } else {
              this.$message.error("暂无数据");
              this.pieCharts([], key);
            }
          }
          if (this.searchData.radio == "燃气锅炉" && this.searchData.params == "蒸汽量" || this.searchData.radio == "蒸锅" && this.searchData.params == "蒸汽热量" || this.searchData.radio == "余热回收系统" && this.searchData.params == "回收余热量" || this.searchData.radio == "高温水源热泵机房" && this.searchData.params == "制热量" || this.searchData.radio == "高温水源热泵机房" && this.searchData.params == "耗电量" || this.searchData.radio == "空气源热泵" && this.searchData.params == "制热量" || this.searchData.radio == "空气源热泵" && this.searchData.params == "耗电量" || this.searchData.radio == "空气源热泵" && this.searchData.params == "平均COP" && this.searchData.children.length > 1) {
            if (res.data[key].length != 0 && res.data[key] != undefined) {
              let xAxisArr = [],
                barData = [],
                seriesData = [];
              res.data[key].forEach(element => {
                xAxisArr.push(element.name);
                barData.push(element[paramsCode]);
              });
              seriesData.push({
                // name: element.name,
                barWidth: res.data[key].length <= 4 ? 10 : 7,
                type: "bar",
                data: barData
              });
              this.barCharts(xAxisArr, "设备名称", res.data.params[paramsCode], seriesData);
            } else {
              this.$message.error("暂无数据");
            }
          }
          if (this.searchData.radio == "空气源热泵" && this.searchData.params == "平均COP" && this.searchData.children.length == 1) {
            if (res.data[key].length != 0 && res.data[key] != undefined) {
              this.gaugeCharts(res.data[key][0][paramsCode]);
            } else {
              this.$message.error("暂无数据");
            }
          }
          if (this.searchData.radio == "光伏系统" && this.searchData.params == "发电量") {
            if (res.data[key].length != 0 && res.data[key] != undefined) {
              let xAxisArr = [],
                seriesData = [],
                legendArr = [],
                lineData = [];
              res.data[key].forEach(element => {
                xAxisArr.push(element.name);
                lineData.push(element[paramsCode]);
                legendArr.push(element.name);
              });
              seriesData.push({
                data: lineData,
                type: "line",
                smooth: true,
                areaStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: "rgba(55,162,218,0.3)"
                    }, {
                      offset: 1,
                      color: "rgba(55,162,218,0)"
                    }], false),
                    shadowColor: "rgba(55,162,218, 0.5)",
                    shadowBlur: 1
                  }
                }
              });
              this.lineCharts(xAxisArr, legendArr, "设备", res.data.params[paramsCode], seriesData);
            } else {
              this.$message.error("暂无数据");
              this.lineCharts([], [], "设备", res.data.params[paramsCode], []);
            }
          }
        } else {
          // 选择日期大于一天
          if (this.searchData.chartTimeRange[1].getTime() - this.searchData.chartTimeRange[0].getTime() > 24 * 60 * 60 * 1000) {
            if (this.searchData.radio == "光伏系统") {
              if (res.data[key].length != 0 && res.data[key] != undefined) {
                // let xAxisArr = [],
                //   seriesData = [],
                //   legendArr = [];
                // res.data[key][0].data.forEach((element) => {
                //   xAxisArr.push(element.createTime);
                // });
                // res.data[key].forEach((element) => {
                //   let lineData = [];
                //   element.data.forEach((item) => {
                //     lineData.push(item[paramsCode]);
                //   });
                //   legendArr.push(element.name);
                //   seriesData.push({
                //     name: element.name,
                //     data: lineData,
                //     type: "line",
                //     smooth: true,
                //     areaStyle: {
                //       normal: {
                //         color: new this.$echarts.graphic.LinearGradient(
                //           0,
                //           0,
                //           0,
                //           1,
                //           [
                //             {
                //               offset: 0,
                //               color: "rgba(55,162,218,0.3)",
                //             },
                //             {
                //               offset: 1,
                //               color: "rgba(55,162,218,0)",
                //             },
                //           ],
                //           false
                //         ),
                //         shadowColor: "rgba(55,162,218, 0.5)",
                //         shadowBlur: 1,
                //       },
                //     },
                //   });
                // });
                // this.lineCharts(
                //   xAxisArr,
                //   legendArr,
                //   "天数333",
                //   res.data.params[paramsCode],
                //   seriesData
                // );

                if (res.data[key] && Array.isArray(res.data[key])) {
                  const data = res.data[key];
                  const names = data.map(item => item.name);
                  const legendArr = data[0].data.map(item => item.createTime);
                  const seriesData = legendArr.map(legItem => {
                    const datas = data.flatMap(item => {
                      const dataItem = item.data.find(itemElement => itemElement.createTime === legItem);
                      return dataItem ? parseFloat(dataItem.powerGenerationNum) : null;
                    });
                    return {
                      name: legItem,
                      type: "line",
                      data: datas
                    };
                  });
                  let xAxisArr = names;
                  const yAxisName = res.data.params[paramsCode] === "" ? this.getSearchData.params : `${this.getSearchData.params}(${res.data.params[paramsCode]})`;
                  this.lineCharts(xAxisArr, legendArr, "名称", yAxisName, seriesData);
                }
              } else {
                this.$message.error("暂无数据");
                this.lineCharts([], [], "名称", res.data.params[paramsCode], []);
              }
            } else {
              if (res.data[key].length != 0 && res.data[key] != undefined) {
                let xAxisArr = [],
                  legendArr = [],
                  seriesData = [];
                res.data[key][0].data.forEach(item => {
                  xAxisArr.push(item.createTime);
                });
                res.data[key].forEach(element => {
                  let barData = [];
                  legendArr.push(element.name);
                  element.data.forEach(item => {
                    barData.push(item[paramsCode]);
                  });
                  seriesData.push({
                    name: element.name,
                    barWidth: 10,
                    type: "bar",
                    data: barData
                  });
                });
                this.barCharts2(xAxisArr, legendArr, "天数", res.data.params[paramsCode], seriesData);
              } else {
                this.$message.error("暂无数据");
                this.barCharts2([], [], "天数", res.data.params[paramsCode], []);
              }
            }
          } else {
            //选择日期小于1天
            if (res.data[key].length != 0 && res.data[key] != undefined) {
              let xAxisArr = [],
                seriesData = [],
                legendArr = [];
              res.data[key][0].data.forEach(element => {
                xAxisArr.push(element.createTime);
              });
              res.data[key].forEach(element => {
                let lineData = [];
                element.data.forEach(item => {
                  lineData.push(item[paramsCode]);
                });
                legendArr.push(element.name);
                seriesData.push({
                  name: element.name,
                  data: lineData,
                  type: "line",
                  smooth: true,
                  areaStyle: {
                    normal: {
                      color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: "rgba(55,162,218,0.3)"
                      }, {
                        offset: 1,
                        color: "rgba(55,162,218,0)"
                      }], false),
                      shadowColor: "rgba(55,162,218, 0.5)",
                      shadowBlur: 1
                    }
                  }
                });
              });
              this.lineCharts(xAxisArr, legendArr, "时间/h", res.data.params[paramsCode], seriesData);
            } else {
              this.$message.error("暂无数据");
              this.lineCharts([], [], "时间/h", res.data.params[paramsCode], []);
            }
          }
        }
      }).catch();
    },
    barCharts(xAxisArr, xAxisName, yAxisName, seriesData) {
      this.$refs.chart1.style.display = "block";
      this.$refs.chart2.style.display = "none";
      const chart = this.$echarts.init(this.$refs.chart1);
      chart.clear();
      chart.setOption({
        tooltip: {
          trigger: "axis"
        },
        grid: {
          top: "20%",
          bottom: "20%",
          left: "15%",
          right: "10%"
        },
        legend: {
          show: false
        },
        color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
        xAxis: [{
          name: this.returnName(xAxisName),
          type: "category",
          data: xAxisArr,
          axisPointer: {
            type: "shadow"
          },
          axisLine: {
            lineStyle: {
              color: "#d8d8d8",
              width: 1
            }
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 12,
            padding: [8, 0, 0, -15],
            verticalAlign: "top"
          },
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              var ret = ""; //拼接加\n返回的类目项
              var maxLength = 4; //每项显示文字个数
              var valLength = value.length; //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1) {
                //如果类目项的文字大于5,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; //每次截取的字符串
                  var start = i * maxLength; //开始截取的位置
                  var end = start + maxLength; //结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }

                return ret;
              } else {
                return value;
              }
            }
          }
        }],
        yAxis: [{
          name: yAxisName,
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#c3d2dd",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,124,226,0.8)",
              type: "dotted"
            }
          }
        }],
        series: seriesData
      });
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    },
    barCharts2(xAxisArr, legendArr, xAxisName, yAxisName, seriesData) {
      this.$refs.chart1.style.display = "block";
      this.$refs.chart2.style.display = "none";
      const chart = this.$echarts.init(this.$refs.chart1);
      chart.clear();
      chart.setOption({
        tooltip: {
          trigger: "axis"
        },
        grid: {
          top: legendArr.length <= 3 ? "25%" : "35%",
          bottom: "17%",
          left: "15%",
          right: "8%"
        },
        legend: {
          top: "10px",
          icon: "circle",
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            color: []
          },
          data: legendArr
        },
        color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
        xAxis: [{
          name: xAxisName,
          type: "category",
          data: xAxisArr,
          axisPointer: {
            type: "shadow"
          },
          axisLine: {
            lineStyle: {
              color: "#d8d8d8",
              width: 1
            }
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 12,
            padding: [8, 0, 0, -20],
            verticalAlign: "top"
          },
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              var ret = ""; //拼接加\n返回的类目项
              var maxLength = 4; //每项显示文字个数
              var valLength = value.length; //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1) {
                //如果类目项的文字大于5,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; //每次截取的字符串
                  var start = i * maxLength; //开始截取的位置
                  var end = start + maxLength; //结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }

                return ret;
              } else {
                return value;
              }
            }
          }
        }],
        yAxis: [{
          type: "value",
          name: yAxisName,
          axisLine: {
            lineStyle: {
              color: "#c3d2dd",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,124,226,0.8)",
              type: "dotted"
            }
          }
        }],
        series: seriesData
      });
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    },
    lineCharts(xAxisArr, legendArr, xAxisName, yAxisName, seriesData) {
      this.$refs.chart1.style.display = "block";
      this.$refs.chart2.style.display = "none";
      const chart = this.$echarts.init(this.$refs.chart1);
      chart.clear();
      console.log(this.searchData, "this.searchData");
      if (this.searchData.code == "PV" && this.searchData.chartTimeRange == "") {
        if (this.searchData.children.length > 20) {
          chart.setOption({
            tooltip: {
              trigger: "axis",
              confine: true,
              enterable: true,
              //允许鼠标进入提示框浮层中，默认为false
              extraCssText: "max-height:83%;overflow:auto" //出现滚动条
            },

            legend: {
              top: "10px",
              textStyle: {
                color: []
              },
              data: legendArr
            },
            grid: {
              top: legendArr.length <= 3 ? "25%" : "20%",
              bottom: "20%",
              left: "12%",
              right: "10%"
            },
            color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
            xAxis: {
              name: xAxisName,
              type: "category",
              axisLine: {
                lineStyle: {
                  color: "#d8d8d8",
                  width: 1
                }
              },
              nameTextStyle: {
                fontFamily: "ABBvoice_WCNSG_Rg",
                color: "#9f9f9f",
                fontSize: 12,
                padding: [8, 0, 0, -15],
                verticalAlign: "top"
              },
              axisLabel: {
                interval: 0,
                formatter: function (value) {
                  return value.match(/\d+/g);
                }
              },
              data: xAxisArr
            },
            yAxis: {
              name: yAxisName,
              type: "value",
              axisLine: {
                lineStyle: {
                  color: "#c3d2dd",
                  width: 1
                }
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "rgba(0,124,226,0.8)",
                  type: "dotted"
                }
              }
            },
            dataZoom: [{
              type: "slider",
              start: 0,
              end: 20,
              bottom: 10,
              //下滑块距离x轴底部的距离
              height: 10,
              //下滑块手柄的高度调节
              fillerColor: "rgba(167,183,204,0.4)",
              textStyle: {
                color: "#eee"
              }
            }, {
              start: 0,
              end: 20,
              bottom: 10,
              //下滑块距离x轴底部的距离
              height: 10,
              //下滑块手柄的高度调节
              fillerColor: "rgba(167,183,204,0.4)",
              textStyle: {
                color: "#eee"
              }
            }],
            series: seriesData
          });
        } else {
          chart.setOption({
            tooltip: {
              trigger: "axis",
              confine: true,
              enterable: true,
              //允许鼠标进入提示框浮层中，默认为false
              extraCssText: "max-height:83%;overflow:auto" //出现滚动条
            },

            legend: {
              top: "10px",
              textStyle: {
                color: []
              },
              data: legendArr
            },
            grid: {
              top: legendArr.length <= 3 ? "25%" : "20%",
              bottom: "20%",
              left: "12%",
              right: "10%"
            },
            color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
            xAxis: {
              name: xAxisName,
              type: "category",
              axisLine: {
                lineStyle: {
                  color: "#d8d8d8",
                  width: 1
                }
              },
              nameTextStyle: {
                fontFamily: "ABBvoice_WCNSG_Rg",
                color: "#9f9f9f",
                fontSize: 12,
                padding: [8, 0, 0, -15],
                verticalAlign: "top"
              },
              axisLabel: {
                interval: 0,
                formatter: function (value) {
                  return value.match(/\d+/g);
                }
              },
              data: xAxisArr
            },
            yAxis: {
              name: yAxisName,
              type: "value",
              axisLine: {
                lineStyle: {
                  color: "#c3d2dd",
                  width: 1
                }
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "rgba(0,124,226,0.8)",
                  type: "dotted"
                }
              }
            },
            series: seriesData
          });
        }
      } else if (this.searchData.code == "PV" && this.searchData.chartTimeRange.length > 0) {
        chart.setOption({
          tooltip: {
            trigger: "axis",
            confine: true,
            enterable: true,
            //允许鼠标进入提示框浮层中，默认为false
            extraCssText: "max-height:83%;overflow:auto" //出现滚动条
          },

          legend: {
            width: 350,
            type: "scroll",
            top: "20px",
            textStyle: {
              color: "#eee"
            },
            pageTextStyle: {
              // 设置上一页、下一页文字样式
              color: "#eee"
            },
            pageIconColor: "#eee",
            pageIconInactiveColor: "#999",
            data: legendArr
          },
          grid: {
            top: legendArr.length <= 3 ? "25%" : "30%",
            bottom: "20%",
            left: "12%",
            right: "10%"
          },
          color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
          xAxis: {
            name: xAxisName,
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#d8d8d8",
                width: 1
              }
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 12,
              padding: [8, 0, 0, -15],
              verticalAlign: "top"
            },
            // axisLabel: {
            //   interval: 0,
            //   formatter: function (value) {
            //     return value.match(/\d+/g);
            //   },
            // },
            data: xAxisArr
          },
          yAxis: {
            name: yAxisName,
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#c3d2dd",
                width: 1
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0,124,226,0.8)",
                type: "dotted"
              }
            }
          },
          series: seriesData
        });
      } else {
        chart.setOption({
          tooltip: {
            trigger: "axis"
          },
          legend: {
            top: "10px",
            textStyle: {
              color: []
            },
            data: legendArr
          },
          grid: {
            top: legendArr.length <= 3 ? "25%" : "35%",
            bottom: "17%",
            left: "12%",
            right: "10%"
          },
          color: "#37a2da",
          xAxis: {
            name: xAxisName,
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#d8d8d8",
                width: 1
              }
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 12,
              padding: [8, 0, 0, -15],
              verticalAlign: "top"
            },
            axisLabel: {
              interval: 0,
              formatter: function (value) {
                var ret = ""; //拼接加\n返回的类目项
                var maxLength = 4; //每项显示文字个数
                var valLength = value.length; //X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1) {
                  //如果类目项的文字大于5,
                  for (var i = 0; i < rowN; i++) {
                    var temp = ""; //每次截取的字符串
                    var start = i * maxLength; //开始截取的位置
                    var end = start + maxLength; //结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }

                  return ret;
                } else {
                  return value;
                }
              }
            },
            data: xAxisArr
          },
          yAxis: {
            name: yAxisName,
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#c3d2dd",
                width: 1
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0,124,226,0.8)",
                type: "dotted"
              }
            }
          },
          series: seriesData
        });
      }
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    },
    pieCharts(data, code) {
      this.$refs.chart1.style.display = "none";
      this.$refs.chart2.style.display = "block";
      const chart = this.$echarts.init(this.$refs.chart2);
      const arrName = this.getArrayValue(data, "name");
      const arrValue = this.getArrayValue(data, "value");
      const sumValue = eval(arrValue.join("+"));
      this.sumValue = sumValue;
      const objData = this.array2obj(data, "name");
      const optionData = this.getData(data);
      let gridObj = {};
      if (code == "caloric_recup") {
        gridObj = {
          top: data.length > 3 ? "15%" : "13%",
          bottom: data.length > 3 ? "56%" : "66%",
          left: "35%",
          containLabel: false
        };
      }
      if (code == "waterPump" || code == "PV") {
        gridObj = {
          top: "0%",
          bottom: "83%",
          left: "35%",
          containLabel: false
        };
      }
      if (code == "airPump") {
        gridObj = {
          top: data.length > 2 ? "5%" : "13%",
          bottom: data.length > 2 ? "5%" : "70%",
          left: "35%",
          containLabel: false
        };
      }
      chart.clear();
      const option = chart.getOption();
      option.series = [];
      option.yAxis = [];
      // 重新显示，第二个参数是将之前的数据丢掉
      chart.setOption(option, true);
      chart.setOption({
        legend: {
          show: true,
          icon: "circle",
          top: "5%",
          left: "65%",
          data: arrName,
          width: 150,
          itemHeight: 10,
          // 图例icon高度
          itemWidth: 10,
          // 图例icon宽度
          itemGap: 10,
          formatter: function (name) {
            if (sumValue == 0) {
              return "{title|" + name + "}\n{value|" + 0 + "%}";
            } else {
              return "{title|" + name + "}\n{value|" + (objData[name].value / sumValue * 100).toFixed(2) + "%}";
            }
          },
          textStyle: {
            rich: {
              title: {
                fontSize: 12,
                // lineHeight: 15,
                color: "rgb(0, 178, 246)"
              },
              value: {
                fontSize: 12,
                // lineHeight: 20,
                color: "#fff"
              }
            }
          }
        },
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{b}:{c}({d}%)"
        },
        color: ["#fdd13b", "#00d22a", "#33eaf5", "#019eff"],
        grid: gridObj,
        yAxis: [{
          type: "category",
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            inside: true,
            textStyle: {
              color: "#fff",
              fontSize: 12
            },
            show: true
          },
          data: optionData.yAxis
        }],
        xAxis: [{
          show: false
        }],
        series: optionData.series
      });
      chart.resize();
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    },
    gaugeCharts(data) {
      this.$refs.chart1.style.display = "block";
      this.$refs.chart2.style.display = "none";
      var axislineColor = new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0.1,
        color: "#E43F3D"
      }, {
        offset: 0.15,
        color: "#E98E2C"
      }, {
        offset: 0.2,
        color: "#DDBD4D"
      }, {
        offset: 0.8,
        color: "#7CBB55"
      }, {
        offset: 1,
        color: "#9CD6CE"
      }]);
      const chart = this.$echarts.init(this.$refs.chart1);
      chart.clear();
      chart.setOption({
        series: [{
          name: "车辆总数",
          type: "gauge",
          z: 3,
          min: 0,
          max: 3,
          center: ["50%", "60%"],
          radius: window.innerHeight > 900 ? "90%" : "90%",
          splitNumber: 10,
          axisLine: {
            // 坐标轴线
            lineStyle: {
              // 属性lineStyle控制线条样式
              width: 15,
              color: [[1, axislineColor]]
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            distance: 0,
            textStyle: {
              color: "#fff",
              fontSize: 12
            }
          },
          splitLine: {
            // 分隔线
            show: false
          },
          detail: {
            show: true,
            textStyle: {
              color: "#eee",
              fontSize: 24
            }
          },
          pointer: {
            show: true,
            width: 3,
            length: "60%"
          },
          itemStyle: {
            normal: {
              color: "#367bad"
            }
          },
          data: [{
            value: data,
            name: ""
          }]
        }, {
          name: "",
          type: "gauge",
          z: 2,
          min: 0,
          max: 3,
          center: ["50%", "60%"],
          radius: window.innerHeight > 900 ? "75%" : "68%",
          splitNumber: 10,
          axisLine: {
            // 坐标轴线
            show: false,
            lineStyle: {
              // 属性lineStyle控制线条样式
              width: 0,
              color: [[1, axislineColor]]
            }
          },
          title: {
            show: false
          },
          detail: {
            show: false
          },
          axisTick: {
            show: true,
            length: 5,
            lineStyle: {
              width: 1,
              color: "#EBF3FE"
            }
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            // 分隔线
            show: false
          }
        }]
      });
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    },
    getArrayValue(array, key) {
      var key = key || "value";
      var res = [];
      if (array) {
        array.forEach(function (t) {
          res.push(t[key]);
        });
      }
      return res;
    },
    array2obj(array, key) {
      var resObj = {};
      for (var i = 0; i < array.length; i++) {
        resObj[array[i][key]] = array[i];
      }
      return resObj;
    },
    getData(data) {
      var res = {
        series: [],
        yAxis: []
      };
      for (let i = 0; i < data.length; i++) {
        // console.log([70 - i * 15 + '%', 67 - i * 15 + '%']);
        res.series.push({
          name: "",
          type: "pie",
          clockWise: false,
          //顺时加载
          hoverAnimation: false,
          //鼠标移入变大
          radius: [73 - i * 13 + "%", 68 - i * 13 + "%"],
          center: ["35%", "53%"],
          label: {
            show: false
          },
          itemStyle: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            borderWidth: 5
          },
          data: [{
            value: data[i].value,
            name: data[i].name
          }, {
            value: this.sumValue - data[i].value,
            name: "",
            itemStyle: {
              color: "rgba(0,0,0,0)",
              borderWidth: 0
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          }]
        });
        res.series.push({
          name: "",
          type: "pie",
          silent: true,
          z: 1,
          clockWise: false,
          //顺时加载
          hoverAnimation: false,
          //鼠标移入变大
          radius: [73 - i * 13 + "%", 68 - i * 13 + "%"],
          center: ["35%", "53%"],
          label: {
            show: false
          },
          itemStyle: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            borderWidth: 5
          },
          data: [{
            value: 7.5,
            itemStyle: {
              color: "rgba(165,165,165,0.3)",
              borderWidth: 0
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          }, {
            value: 2.5,
            name: "",
            itemStyle: {
              color: "rgba(0,0,0,0)",
              borderWidth: 0
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          }]
        });
        if (this.sumValue == 0) {
          res.yAxis.push("0%");
        } else {
          res.yAxis.push((data[i].value / this.sumValue * 100).toFixed(2) + "%");
        }
      }
      return res;
    },
    returnName(name) {
      const len = name.length;
      if (len > 2) {
        return name.substring(0, 2) + "\n" + name.substring(2, 4);
      }
    }
  },
  computed: {
    getSearchData() {
      return this.$store.getters.getSearchData;
    }
  }
};