import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allEquipmentSearch } from "../../../api/charts";
export default {
  props: {
    deviceType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "",
      data: [],
      params: {},
      sumValue: 0,
      timer: null,
      isButtonDisabled: false,
      //初始状态不禁用
      currentResult: []
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.timer = setInterval(() => {
    //   }, 60000);
    // });
  },
  beforeDestroy() {
    clearInterval(this.timer); // 取消定时器
  },

  computed: {
    getSearchData() {
      return this.$store.getters.getSearchData;
    }
  },
  methods: {
    removeComponent() {
      this.$emit("closeComm", "");
    },
    showChart(sessionName) {
      /* eslint-disable no-debugger */
      debugger;
      /* eslint-enable no-debugger */

      console.log(this.currentResult, "this.currentResult");
      if (this.currentResult.length == 0) {
        console.log("this.currentResult 为空", this.currentResult);
        this.getDataInfo(sessionName);
      } else {
        const getOldData = JSON.parse(sessionStorage.getItem(sessionName));
        console.log("this.currentResult 非空，直接展示 this.currentResult: ", this.currentResult);
        setTimeout(() => {
          this.charts(this.currentResult, getOldData.code);
        }, 1500);
      }
    },
    getDataInfo(sessionName) {
      console.log(sessionName, "sessionName");
      const getOldData = JSON.parse(sessionStorage.getItem(sessionName));
      console.log(getOldData, "getOldDatagetOldDatagetOldDatagetOldData");
      let paramsType = [],
        paramsArr = [];
      let key = getOldData.code;
      let paramsCode = getOldData.paramsCode;
      paramsType.push(getOldData.code);
      paramsArr.push(paramsCode);
      this.params.paramType = paramsType;
      this.params.createdStartTime = "";
      this.params.createdEndTime = "";
      this.params.deviceId = getOldData.childrenId;
      this.params.params = paramsArr;
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = true; //获取到数据之前禁用
        allEquipmentSearch(this.params).then(res => {
          this.isButtonDisabled = false;
          if (res.data[key].length != 0 && res.data[key] != undefined) {
            const data = res.data[key];
            this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
            let result = [];
            data.forEach(element => {
              result.push({
                name: element.name,
                value: element[paramsCode] == null ? 0 : element[paramsCode]
              });
            });
            this.currentResult = [...result];
            this.charts(result, getOldData.code);
          }
        }).catch(() => {
          this.isButtonDisabled = false; //获取到数据之后可以继续操作;
        });
      }
    },

    getData5() {
      let paramsType = [],
        paramsArr = [];
      let key = this.getSearchData.code;
      let paramsCode = this.getSearchData.paramsCode;
      paramsType.push(this.getSearchData.code);
      paramsArr.push(paramsCode);
      this.params.paramType = paramsType;
      this.params.createdStartTime = "";
      this.params.createdEndTime = "";
      this.params.deviceId = this.getSearchData.childrenId;
      this.params.params = paramsArr;
      allEquipmentSearch(this.params).then(res => {
        // console.log(res,"res环形图返回值");
        if (res.data.length != 0 && res.data[key] != undefined) {
          const data = res.data[key];
          this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
          let result = [];
          data.forEach(element => {
            result.push({
              name: element.name,
              value: element[paramsCode] == null ? 0 : element[paramsCode]
            });
          });
          this.currentResult = this.currentResult = [...result];
          this.charts(result, this.getSearchData.code);
          console.log(result, "result");
          console.log(this.currentResult, " this.currentResult");
        } else {
          this.$message.error("暂无数据");
        }
      }).catch();
    },
    charts(data, code) {
      const chart = this.$echarts.init(this.$refs.piechart2);
      const arrName = this.getArrayValue(data, "name");
      const arrValue = this.getArrayValue(data, "value");
      const sumValue = eval(arrValue.join("+"));
      this.sumValue = sumValue;
      const objData = this.array2obj(data, "name");
      const optionData = this.getData(data);
      chart.clear();
      let gridObj = {};
      if (code == "caloric_recup") {
        gridObj = {
          top: "5%",
          bottom: "56%",
          left: "35%",
          containLabel: false
        };
      }
      if (code == "waterPump" || code == "PV") {
        gridObj = {
          top: "0%",
          bottom: "60%",
          left: "56%",
          containLabel: false
        };
      }
      if (code == "airPump") {
        gridObj = {
          top: "5%",
          bottom: "75%",
          left: "35%",
          containLabel: false
        };
      }
      chart.setOption({
        legend: {
          show: true,
          icon: "circle",
          top: window.innerHeight > 900 ? "5%" : "2%",
          left: "65%",
          data: arrName,
          width: 150,
          itemHeight: 10,
          // 图例icon高度
          itemWidth: 10,
          // 图例icon宽度
          itemGap: window.innerHeight > 900 ? 10 : 3,
          formatter: function (name) {
            // if(sumValue == 0){
            //     return "{title|" + name + "}\n{value|" + 0 +"%}"
            // }else{
            //     return "{title|" + name + "}\n{value|" + ((objData[name].value)/sumValue * 100).toFixed(2) +"%}"
            // }
            return "{title|" + name + "}";
          },
          textStyle: {
            rich: {
              title: {
                fontSize: 12,
                // lineHeight: 15,
                color: "rgb(0, 178, 246)"
              },
              value: {
                fontSize: 12,
                // lineHeight: 20,
                color: "#fff"
              }
            }
          }
        },
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{b}:{c}({d}%)"
        },
        color: ["#fdd13b", "#00d22a", "#33eaf5", "#019eff"],
        grid: gridObj,
        yAxis: [{
          type: "category",
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            inside: true,
            textStyle: {
              color: "#fff",
              fontSize: 12
            },
            show: true
          },
          data: optionData.yAxis
        }],
        xAxis: [{
          show: false
        }],
        series: optionData.series
      });
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    },
    getArrayValue(array, key) {
      var key = key || "value";
      var res = [];
      if (array) {
        array.forEach(function (t) {
          res.push(t[key]);
        });
      }
      return res;
    },
    array2obj(array, key) {
      var resObj = {};
      for (var i = 0; i < array.length; i++) {
        resObj[array[i][key]] = array[i];
      }
      return resObj;
    },
    getData(data) {
      var res = {
        series: [],
        yAxis: []
      };
      for (let i = 0; i < data.length; i++) {
        res.series.push({
          name: "",
          type: "pie",
          clockWise: false,
          //顺时加载
          hoverAnimation: false,
          //鼠标移入变大
          radius: [73 - i * 13 + "%", 68 - i * 13 + "%"],
          center: ["35%", "43%"],
          label: {
            show: false
          },
          itemStyle: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            borderWidth: 5
          },
          data: [{
            value: data[i].value,
            name: data[i].name
          }, {
            value: this.sumValue - data[i].value,
            name: "",
            itemStyle: {
              color: "rgba(0,0,0,0)",
              borderWidth: 0
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          }]
        });
        res.series.push({
          name: "",
          type: "pie",
          silent: true,
          z: 1,
          clockWise: false,
          //顺时加载
          hoverAnimation: false,
          //鼠标移入变大
          radius: [73 - i * 13 + "%", 68 - i * 13 + "%"],
          center: ["35%", "43%"],
          label: {
            show: false
          },
          itemStyle: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            borderWidth: 5
          },
          data: [{
            value: 7.5,
            itemStyle: {
              color: "rgba(165,165,165,0.3)",
              borderWidth: 0
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          }, {
            value: 2.5,
            name: "",
            itemStyle: {
              color: "rgba(0,0,0,0)",
              borderWidth: 0
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          }]
        });
        if (this.sumValue == 0) {
          res.yAxis.push("0%");
        } else {
          res.yAxis.push((data[i].value / this.sumValue * 100).toFixed(2) + "%");
        }
      }
      return res;
    }
  }
};