//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//let alarmList = [];
export default {
  name: "filterDialog",
  data() {
    return {
      form: {
        name: "",
        region: "",
        checkedAlarms: [],
        // alarms: this.alarmList,
        isIndeterminate: true,
        checkAll: false,
        alarmList: []
      }
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    },
    alarmsType: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClose() {
      // 在关闭对话框前执行
      this.$emit("close");
    },
    handleCancel() {
      // 取消按钮逻辑
      this.$emit("close");
    },
    handleSubmit() {
      // 提交表单逻辑
      this.$emit("submit", this.form);
      this.$emit("close");
    },
    handleCheckAllChange(val) {
      this.form.checkedAlarms = val ? this.form.alarmList : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.form.checkAll = checkedCount === this.form.alarmList.length;
      this.form.isIndeterminate = checkedCount > 0 && checkedCount < this.form.alarmList.length;
    }
  },
  watch: {
    alarmsType() {
      console.log(this.alarmsType, "this.alarmsType");
      //获取到了报警数据类型，或者数据类型发生了变化
      this.form.alarmList = this.alarmsType.map(item => item.description);
      if (this.form.checkedAlarms.length === 0) {
        this.form.checkedAlarms = this.form.alarmList.map(item => item);
      }
      console.log(this.form.alarmList, "this.form.alarmList");
    }
  }
};