import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";

// import {mapState, mapGetters, mapMutations} from 'vuex'
// 引入Three.js扩展库
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// 引入gltf模型加载库GLTFLoader.js
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { GroundedSkybox } from "three/examples/jsm/objects/GroundedSkybox.js";
// 文字标签
import { CSS2DRenderer, CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer.js";
// import {CSS3DRenderer,CSS3DObject,CSS3DSprite  } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
// 导入DRACOLoader解码器
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import headerCom from "../components/header.vue";
import msg from "../components/msg.vue";
import alarmList from "../components/alarmList.vue";
import footerCom from "../components/footer.vue";
import lineCharts from "../components/charts/line-charts/lineCharts.vue";
import lineChartsPv from "../components/charts/line-charts/lineChartsPv.vue";
import barCharts2 from "../components/charts/bar-charts/barCharts2.vue";
import barCharts from "../components/charts/bar-charts/barCharts.vue";
import gaugeCharts from "../components/charts/gauge-charts/gaugeCharts.vue";
import pieCharts2 from "../components/charts/pie-charts/pieCharts2.vue";
import lineChartsOther from "../components/charts/line-charts/lineChartsOther.vue";
import compareCharts from "../components/charts/line-charts/compareCharts.vue";
import { equipmentMsg } from "../api/charts";
import { allEquipmentSearch } from "../api/charts";
import { timelineData, timelineDataByPage } from "../api/charts";
import moment from "moment";
import simulateC from "@/views/simulateControl.vue";
import alarmInfo from "@/views/alarmInfo.vue";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import alramDetail from "../components/alramDetail.vue";
//import { exportPdf } from "@/utils/exportPdf.js";
//纹理贴图
const texLoader = new THREE.TextureLoader();
var texture = texLoader.load("/img/head.png"); //这个代码有问题，不生效的，没有做更改

export default {
  data() {
    return {
      currentTime: "",
      week: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      iszIndex: true,
      visible: false,
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      css2Renderer: new CSS2DRenderer(),
      labelRenderer: new CSS2DRenderer(),
      gltf: null,
      width: window.innerWidth,
      height: window.innerHeight,
      composer: null,
      outlinePass: null,
      renderPass: null,
      clickArr: [],
      timelineArr: [],
      // 两侧图表列表开关
      switchList: {
        value1: false,
        value2: false,
        value3: false,
        value4: false,
        value5: false,
        value6: false,
        value7: false
      },
      // 时间轴
      timeLineFlag: false,
      speed: "采数",
      isPlay: "el-icon-video-play",
      timer: null,
      timeline: 0,
      min: 0,
      max: 0,
      startTime: null,
      startTimeOrigin: null,
      endTime: null,
      //修改标签样式
      tooltipStyle: {
        color: "#FFFFFF",
        width: "58px",
        height: "22px",
        background: "linear-gradient(259.87deg, #7595FA 0.83%, #5D74FC 100%)",
        borderRadius: "16px",
        padding: "3px 10px"
      },
      isDisabled: false,
      equipmentArr: [],
      //点击的设备集合
      timelineParams: {},
      //动态获取数据的查询参数
      timeLineResult: {},
      //动态数据返回的result
      // 搜索结果
      searchData: {},
      equipmentData: [],
      chooseFromMapArr: [],
      chooseFromMapIdArr: [],
      equipmentList: [],
      isMapStatus: undefined,
      isTimeline: false,
      isChooseFromMapFlag: undefined,
      colorClone: {},
      gfbOrinColor: {},
      cloneDivObj: [],
      interactWithObjectTimer: null,
      currentA: 0,
      ratio: {
        value: 0
      },
      next: 0,
      mesh: null,
      //网格模型对象Mesh
      sphere: null,
      sphereGeometry: new THREE.SphereGeometry(0.28, 32, 16),
      pathConvergeData: {
        //汇聚路线
        pathOne: [[22.032, -0.037, 45.586], [23.126, -0.037, 45.586], [23.126, -0.037, 13.071], [-24.632, -0.037, 13.071], [-24.636, -0.037, 3.037]],
        // [-24.508, - 0.037, - 58.862], [-18.557, -0.037, -58.717]
        pathTwo: [[-50.319, -0.037, 3.053], [-24.636, -0.037, 3.039], [-24.508, -0.037, -58.862], [-19.029, -0.037, -58.627]],
        pathThree: [[116.985, -0.037, -27.127], [119.117, -0.037, -27.127], [119.255, -0.037, 6.726], [-24.632, -0.037, 6.735]]
        // [-24.508, - 0.037, - 58.862], [-19.029, -0.037, -58.627]
      },

      pathDisperseData: {
        pathTwo: [[-19.029, -0.037, -58.627], [-24.508, -0.037, -58.862], [-24.636, -0.037, 3.039], [-50.319, -0.037, 3.053]],
        pathThree: [[-24.632, -0.037, 6.848], [119.255, -0.037, 6.726], [119.117, -0.037, -27.127], [116.985, -0.037, -27.127]],
        //[-19.029, - 0.037, -58.627], [-24.508,  - 0.037, - 58.862],
        pathOne: [[-24.636, -0.037, 3.039], [-24.632, -0.037, 13.071], [23.126, -0.037, 13.071], [23.126, -0.037, 45.586], [22.032, -0.037, 45.586]]
        // [-24.508, - 0.037, - 58.862], [-18.557, -0.037, -58.717]
      },

      timers: [],
      tweens: [],
      TagDialogInfo: [],
      buddingSkin: {
        originalColor: undefined,
        //建筑外墙原始颜色
        originalIndoorColor: undefined,
        //建筑内的灯光颜色
        originaldm: undefined,
        originalLED: undefined,
        originaldx: undefined
      },
      modelsOriginSkin: {},
      //存储模型文件原始数据

      isShowSimulateC: false,
      //默认不显示控制窗口
      isShowAlarmInfo: false,
      //默认不显示报警窗口
      isShowAlarmWin: true,
      //默认显示右上角报警窗口
      modelConfig: undefined,
      //读配置文件数据
      timeLineResultAll: undefined,
      //完整的动态返回数据，包含分页数据
      componentsQueue: [],
      //页面弹出的框组合
      componentCounter: undefined,
      canvas: undefined,
      connectionStatus: "connecting",
      // WEBSOCKET状态：connecting, connected, disconnected, error
      webSocketMessage: [],
      heartbeatInterval: null,
      // 用于存储心跳定时器
      alarmDetailTag: null,
      alarmDetailInfo: {},
      alarmListPropsUpdateFlag: false,
      //展示title导航选项卡对应的窗口信息是否展示，数组0对应第一个导航，数组1对应第二个导航
      titleShowFlag: [{
        isShow: true
      }, {
        isShow: false
      }]
    };
  },
  components: {
    lineCharts,
    lineChartsPv,
    lineChartsOther,
    barCharts2,
    barCharts,
    pieCharts2,
    gaugeCharts,
    headerCom,
    msg,
    alarmList,
    footerCom,
    compareCharts,
    simulateC,
    alarmInfo,
    alramDetail
  },
  mounted() {
    this.componentCounter = 0;
    console.log(process.env);
    setInterval(() => {
      this.updateTime();
    }, 1000);
    this.init();
    window.getSingleEquipmentData = this.getSingleEquipmentData;
    //添加监听辅助轨道进行了缩放
    this.controls.addEventListener("change", this.handleZoom);

    //读取模型配置文件
    this.loadConfigFile();

    //设置 一个定时器，每一分钟取一遍数据
    this.updateComponentData();
    //链接websocket
    this.connectWebSocket();
  },
  beforeDestroy() {
    console.log("进入beforedestroy");
    this.timers.forEach(el => clearInterval(el));
    this.tweens.forEach(el => el.stop());
    this.scene.traverse(obj => {
      if (!obj.isMesh) return;
      obj.geometry.dispose();
      obj.material.dispose();
    });
    this.alarmDetailTag = null;
    this.scene = null;
    //清除心跳定时器
    clearInterval(this.heartbeatInterval);
    if (this.websocket) {
      this.websocket.close();
    }
  },
  methods: {
    onChangeHeader(lindex) {
      //导航切换了，更改导航选中的状态
      console.log(lindex, "lindex");
      this.titleShowFlag.forEach(item => {
        item.isShow = false;
      });
      this.titleShowFlag[lindex].isShow = true;
      //切换导航页面后，重新获取一遍数据
      if (lindex === 0) {
        this.iterateRefsNew();
      }
    },
    alramListComClickItem(param) {
      //用户单击 了报警信息的行数据，模型需要
      //1.将摄像机对准该设备，调整好位置。
      //2.设备变色为选中状态
      //3.弹出报警信息框
      // this.camera.position.set(-250, 360, 0); // 设置相机位置

      //   this.camera.lookAt(new THREE.Vector3(10, 40, 0)); // 设置相机方向

      console.log(param, "para,");
      this.setMaterialColor(param.name);
      this.alarmDetailInfo = JSON.parse(JSON.stringify(param));
      console.log(this.alarmDetailInfo, "this.alarmDetailInfo");
    },
    resetDeviceColor() {
      this.gltf.scene.traverse(obj => {
        if (obj.name.includes("sb-gfb")) {
          //点击设备变色前，先全部恢复为原色后再上色
          console.log("设置颜色sb-gfb");
          obj.material.color.set(this.gfbOrinColor);
        } else if (obj.name.includes("sb")) {
          //点击设备变色前，先全部恢复为原色后再上色
          console.log("设置颜色sb- ");
          obj.material.color.set(this.colorClone);
          return;
        }
      });
    },
    //设置设备选中的颜色操作
    setMaterialColor(value) {
      this.resetDeviceColor();
      if (value) {
        this.changeDeviceColor(value);
      }
    },
    changeDeviceColor(value) {
      this.gltf.scene.traverse(obj => {
        if (obj.deviceName == value) {
          obj.material.color.set(0x00ffff);
          console.log(obj, "scenceObj))))))))))))))))s");

          //调整摄像机通过动画看向目标平滑过渡
          this.setCameraTargetTween(obj);
          this.showAlarmTag(obj);
        }
      });
    },
    setCameraTargetTween(objSce) {
      const pos = new THREE.Vector3();
      objSce.getWorldPosition(pos); //获取三维场景中对象世界坐标
      // 相机飞行到的位置和观察目标拉开300的距离
      const pos2 = pos.clone().addScalar(100); //向量的x、y、z坐标分别在pos基础上增加30
      // 相机从当前位置camera.position飞行三维场景中某个世界坐标附近
      new TWEEN.Tween({
        // 相机开始坐标
        x: this.camera.position.x,
        y: this.camera.position.y,
        z: this.camera.position.z,
        // 相机开始指向的目标观察点
        tx: this.controls.target.x,
        ty: this.controls.target.y,
        tz: this.controls.target.z
      }).to({
        // 相机结束坐标
        x: pos2.x,
        y: pos2.y,
        z: pos2.z,
        // 相机结束指向的目标观察点
        tx: pos.x,
        ty: pos.y,
        tz: pos.z
      }, 500).onUpdate(obj => {
        // 动态改变相机位置
        this.camera.position.set(obj.x, obj.y, obj.z);
        // 动态计算相机视线
        // this.camera.lookAt(obj.tx, obj.ty, obj.tz);
        // 动态计算相机视线
        // camera.lookAt(obj.tx, obj.ty, obj.tz);
        this.controls.target.set(obj.tx, obj.ty, obj.tz);
        this.controls.update();
      }).start();
    },
    initAlramDetailTag() {
      const alarmTagDiv = document.getElementById("alarmTag");
      console.log(alarmTagDiv, "div alarmTag");
      // HTML元素转化为threejs的CSS2模型对象
      this.alarmDetailTag = new CSS2DObject(alarmTagDiv);
      alarmTagDiv.style.pointerEvents = "auto";
      alarmTagDiv.style.zIndex = 0;
      // alarmTagDiv.style.visibility = "visible";
    },

    showAlarmTag(obj) {
      // tag.position.set(obj.position);
      // this.initAlramDetailTag();

      const offset = 10;
      this.alarmDetailTag.position.set(obj.position.x, obj.position.y + offset, obj.position.z);
      this.scene.add(this.alarmDetailTag);
      console.log(this.scene);
    },
    removeAlramTagAndDelList() {
      //删除tag
      this.removeAlramDetailTag();
      //删除数组中的信息
      console.log(this.alarmDetailInfo, "this.alarmDetailInfodddddddddddddddddddddd");
      let idToRemove = this.alarmDetailInfo.id;
      console.log("removeAlramTagAndDelList", this.webSocketMessage, this.alarmDetailInfo);
      const filterList = this.webSocketMessage.filter(item => item.id !== idToRemove);
      console.log(this.filterList, "删除后的数组");
      this.alarmListPropsUpdateFlag = true;
      this.webSocketMessage = filterList;
    },
    removeAlramDetailTag() {
      //移除模型中的tag，并且恢复颜色
      this.scene.remove(this.alarmDetailTag);
      this.resetDeviceColor();
    },
    connectWebSocket() {
      //  let envurl= process.env.VUE_APP_API_URL;
      // this.websocket = new WebSocket(
      //   "wss://apitest.zhiboredian.net/device/alarm"
      // );
      this.websocket = new WebSocket(`${process.env.VUE_APP_WSS_URL}/device/alarm`);
      this.websocket.onopen = () => {
        console.log("WebSocket connection opened");
        this.connectionStatus = "connected";
        // 启动心跳定时器，每隔五分钟发送一次心跳
        // this.heartbeatInterval = setInterval(() => {
        //   if (this.websocket.readyState === WebSocket.OPEN) {
        //     this.websocket.send(JSON.stringify({ type: "heartbeat" }));
        //     console.log("Heartbeat sent");
        //   }
        // }, 300000); // 300000 毫秒 = 5 分钟
        this.startHeartbeat();
      };
      this.websocket.onmessage = event => {
        console.log("WebSocket message received:", event.data);
        let res = JSON.parse(event.data);
        if (res.code == "201") {
          this.webSocketMessage = res.data;
          console.log(this.webSocketMessage, "table数据");
          if (this.webSocketMessage.length === 1) {
            //长度为1时认为是更新数据来了
            this.alarmListPropsUpdateFlag = true;
          } else {
            //长度不为1时，认为是页面数据为空初始化数据
            this.alarmListPropsUpdateFlag = false;
          }
          //this.$refs.footerComRef.startBlinking(); //报警闪烁
        }
      };

      this.websocket.onclose = () => {
        console.log("WebSocket connection closed");
        this.connectionStatus = "disconnected";
        // 清除心跳定时器
        // clearInterval(this.heartbeatInterval);
        this.stopHeartbeat();
        this.reconnectWebSocket();
        this.resetData();
      };
      this.websocket.onerror = error => {
        console.error("WebSocket error:", error);
        this.connectionStatus = "error";
      };
    },
    startHeartbeat() {
      this.heartbeatInterval = setInterval(() => {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.send(JSON.stringify({
            type: "ping"
          }));
        }
      }, 30000); // 30 秒发送一次心跳包
    },

    stopHeartbeat() {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    },
    reconnectWebSocket() {
      if (this.reconnectTimeout) {
        clearTimeout(this.reconnectTimeout);
      }
      this.reconnectTimeout = setTimeout(() => {
        console.log("Attempting to reconnect WebSocket...");
        this.connectWebSocket();
      }, this.reconnectInterval);
    },
    resetData() {
      //重连websocket后重置数据，否则会数据冲突
      this.webSocketMessage = [];
    },
    onclickComm(component) {
      console.log("进入了onclickComm", component);
    },
    logerrorInfo(err, vm, info) {
      // 全局或父组件级别的错误捕获
      console.error("Caught an error:", err);
      console.error("Caught an vm:", vm);
      console.error("Caught an info:", info);
      // 这里你可以根据需要进行错误处理，比如发送错误报告、记录日志等
      // 返回 false 可以阻止错误继续向上冒泡
      return false;
    },
    removeComponent(index) {
      console.log(index, "！！！！！！！！！！！！！！删除掉的index值为");
      // // 移除对应索引的组件
      this.componentCounter -= 1;
      this.componentsQueue.splice(index, 1);
      console.log(this.componentsQueue, "this.componentsQueue");
      if (index <= 2 && this.componentsQueue.length >= 3) {
        this.$nextTick(() => {
          this.updateDataByIndexNew(2);
        });
      }
    },
    updateDataByIndex(index) {
      let cache = {
        ...this.componentsQueue[index]
      };
      console.log(cache, "删除后index索引的现在数据为");
      console.log(this.$refs, "this.$refs");
      try {
        if (cache.type == "gaugeCharts" && cache.deviceType == "") {
          this.$refs.componentgaugeCharts[0].getDataInfo("gaugeCharts");
        } else if (cache.deviceType == "Yure") {
          console.log(this.$refs.componentpieCharts2Yure[0], "this.$refs.componentpieCharts2Yure[0]");
          console.log(this.$refs.componentpieCharts2Yure[0].testData, "this.$refs.componentpieCharts2Yure[0].testData");
          this.$refs.componentpieCharts2Yure[0].showChart(cache.deviceType);
        } else if (cache.deviceType == "Gaowen") {
          console.log(this.$refs.componentpieCharts2Gaowen[0], "this.$refs.componentpieCharts2Gaowen[0]");
          //this.$refs.componentpieCharts2Gaowen[0].getDataInfo(cache.deviceType);
          this.$refs.componentpieCharts2Gaowen[0].showChart(cache.deviceType);
        } else if (cache.deviceType == "Kongqi") {
          console.log(this.$refs.componentpieCharts2Kongqi[0], "this.$refs.componentpieCharts2Kongqi[0]");
          //this.$refs.componentpieCharts2Kongqi[0].getDataInfo(cache.deviceType);
          this.$refs.componentpieCharts2Kongqi[0].showChart(cache.deviceType);
        } else if (cache.deviceType == "RZhengqiL") {
          this.$refs.componentbarCharts2RZhengqiL[0].getDataInfo(cache.deviceType);
        } else if (cache.deviceType == "ZZhengqiRL") {
          this.$refs.componentbarCharts2ZZhengqiRL[0].getDataInfo(cache.deviceType);
        } else if (cache.deviceType == "YHuishou") {
          //  this.$nextTick(() => {
          console.log(this.$refs.componentbarCharts2YHuishou[0]);
          this.$refs.componentbarCharts2YHuishou[0].getDataInfo(cache.deviceType);
          //  });
        } else if (cache.deviceType == "GZhire") {
          this.$refs.componentbarCharts2GZhire[0].getDataInfo(cache.deviceType);
        } else if (cache.deviceType == "GHaodian") {
          this.$refs.componentbarCharts2GHaodian[0].getDataInfo(cache.deviceType);
        } else if (cache.deviceType == "KZhire") {
          this.$refs.componentbarCharts2KZhire[0].getDataInfo(cache.deviceType);
        } else if (cache.deviceType == "KHaodian") {
          this.$refs.componentbarCharts2KHaodian[0].getDataInfo(cache.deviceType);
        } else if (cache.deviceType == "KAverageCOP") {
          this.$refs.componentbarCharts2KAverageCOP[0].getDataInfo(cache.deviceType);
        } else if (cache.deviceType == "Fadianl") {
          this.$refs.componentlineChartsPvFadianl[0].getDataPvInf(cache.deviceType);
        } else if (cache.deviceType == "CommonMoreOneday") {
          this.$refs.componentbarChartsCommonMoreOneday[0].getDataInfo(cache.deviceType);
        } else if (cache.deviceType == "CommonLessOneday") {
          this.$refs.componentlineChartsCommonLessOneday[0].getDataInfo(cache.deviceType);
        }
      } catch (error) {
        console.log("updateDataByIndex error", error);
      }
    },
    updateDataByIndexNew(index) {
      let cache = {
        ...this.componentsQueue[index]
      };
      console.log(cache, "删除后index索引的现在数据为");
      console.log(this.$refs, "this.$refs");
      const carryOutData = (refName, fetchMethod, deviceType) => {
        this.$nextTick(() => {
          console.log(refName, "refName INFO");
          this.$refs[refName][0][fetchMethod](deviceType);
        });
      };
      const handleDeviceType = (cache, type, chartRef, barChartRef, lineChartRef) => {
        if (cache.deviceType == type) {
          //显示实时数据
          if (cache.type == "pieCharts2") {
            this.$refs[chartRef][0].showChart(cache.deviceType);
          } else if (cache.type == "barCharts2") {
            this.$refs[chartRef][0].getDataInfo(cache.deviceType);
          } else if (cache.type == "lineChartsPv") {
            this.$refs[chartRef][0].getDataPvInf(cache.deviceType);
          }
        } else if (cache.deviceType.includes("moreOneDay")) {
          //显示大于一天的数据
          console.log("11111111111111111111moreOneDay");
          carryOutData(barChartRef, "getDataInfo", `moreOneday${type}`);
        } else if (cache.deviceType.includes("lessOneDay")) {
          //显示小于一天的数据
          console.log("2222222222222222222lessOneDay");
          carryOutData(lineChartRef, "getDataInfo", `lessOneday${type}`);
        }
      };
      try {
        //***************这段是根据高温水源热泵机房**平均COP的三种情况1：没选时间2：大于两天的时间，3：小于一天的时间**************** */
        if (cache.type == "gaugeCharts" && cache.deviceType == "") {
          //组件gaugeCharts不选时间时cache.deviceType == ""
          this.$refs.componentgaugeCharts[0].getDataInfo("gaugeCharts");
        } else if ((cache.type == "barCharts" || cache.type.includes("lineCharts")) && cache.deviceType.includes("COP") && !cache.deviceType.includes("KAverageCOP")) {
          //选了时间的图表只有barCharts或者lineCharts两种方式
          //选了大于一天的时间高温水源热泵机房COP
          if (cache.deviceType.includes("moreOneDay")) {
            let sessionName = `moreOnedaygaugeCharts`;
            carryOutData("componentbarChartsmoreOneDayCOP", "getDataInfo", sessionName);
          } else if (cache.deviceType.includes("lessOneDay")) {
            //选了小于一天的时间
            let sessionName = `lessOnedaygaugeCharts`;
            carryOutData("componentlineChartsOtherlessOneDayCOP", "getDataInfo", sessionName);
          }
          //***************这段是根据高温水源热泵机房**平均COP的三种情况1：没选时间2：大于两天的时间，3：小于一天的时间****************** */
        } else if (cache.deviceType.includes("Yure")) {
          /**************************************余热回收系统*余热回收效率******************************* */
          handleDeviceType(cache, "Yure", "componentpieCharts2Yure", "componentbarChartsmoreOneDayYure", "componentlineChartsOtherlessOneDayYure");
        } else if (cache.deviceType.includes("Gaowen")) {
          /***********************************高温水源热泵机房 绿电占比***************************************/
          handleDeviceType(cache, "Gaowen", "componentpieCharts2Gaowen", "componentbarChartsmoreOneDayGaowen", "componentlineChartsOtherlessOneDayGaowen");
        } else if (cache.deviceType.includes("Kongqi")) {
          /***************************************空气源热泵**绿电占比**************************************** */
          handleDeviceType(cache, "Kongqi", "componentpieCharts2Kongqi", "componentbarChartsmoreOneDayKongqi", "componentlineChartsOtherlessOneDayKongqi");
        } else if (cache.deviceType.includes("RZhengqiL")) {
          /***********************************燃气锅炉 蒸汽量*****************************************/
          handleDeviceType(cache, "RZhengqiL", "componentbarCharts2RZhengqiL", "componentbarChartsmoreOneDayRZhengqiL", "componentlineChartsOtherlessOneDayRZhengqiL");
        } else if (cache.deviceType.includes("ZZhengqiRL")) {
          /*********************************蒸锅*蒸汽热量*****************************************/
          handleDeviceType(cache, "ZZhengqiRL", "componentbarCharts2ZZhengqiRL", "componentbarChartsmoreOneDayZZhengqiRL", "componentlineChartsOtherlessOneDayZZhengqiRL");
        } else if (cache.deviceType.includes("YHuishou")) {
          /*********************************余热回收系统*回收余热量*****************************************/
          handleDeviceType(cache, "YHuishou", "componentbarCharts2YHuishou", "componentbarChartsmoreOneDayYHuishou", "componentlineChartsOtherlessOneDayYHuishou");
        } else if (cache.deviceType.includes("GZhire")) {
          /*********************************高温水源热泵机房*制热量*****************************************/
          handleDeviceType(cache, "GZhire", "componentbarCharts2GZhire", "componentbarChartsmoreOneDayGZhire", "componentlineChartsOtherlessOneDayGZhire");
        } else if (cache.deviceType.includes("GHaodian")) {
          /*********************************高温水源热泵机房*耗电量*****************************************/
          handleDeviceType(cache, "GHaodian", "componentbarCharts2GHaodian", "componentbarChartsmoreOneDayGHaodian", "componentlineChartsOtherlessOneDayGHaodian");
        } else if (cache.deviceType.includes("KZhire")) {
          /*********************************空气源热泵*制热量*****************************************/
          handleDeviceType(cache, "KZhire", "componentbarCharts2KZhire", "componentbarChartsmoreOneDayKZhire", "componentlineChartsOtherlessOneDayKZhire");
        } else if (cache.deviceType.includes("KHaodian")) {
          /*********************************空气源热泵*耗电量*****************************************/
          handleDeviceType(cache, "KHaodian", "componentbarCharts2KHaodian", "componentbarChartsmoreOneDayKHaodian", "componentlineChartsOtherlessOneDayKHaodian");
        } else if (cache.deviceType.includes("KAverageCOP")) {
          /*********************************空气源热泵*平均COP*****************************************/

          handleDeviceType(cache, "KAverageCOP", "componentbarCharts2KAverageCOP", "componentbarChartsmoreOneDayKAverageCOP", "componentlineChartsOtherlessOneDayKAverageCOP");
        } else if (cache.deviceType.includes("Fadianl")) {
          /*********************************光伏系统*发电量*****************************************/
          handleDeviceType(cache, "Fadianl", "componentlineChartsPvFadianl", "componentbarChartsmoreOneDayFadianl", "componentlineChartslessOneDayFadianl");
        }
      } catch (error) {
        console.log("updateDataByIndex error", error);
      }
    },
    updateComponentData() {
      this.$nextTick(() => {
        this.timer = setInterval(() => {
          console.log("大刷新一次页面数据");
          //获取页面数据
          this.iterateRefsNew();
        }, 60000);
      });
    },
    loadConfigFile() {
      let modelConfig = {};
      fetch("/static/config.json").then(response => response.json()).then(data => {
        modelConfig = data;
        //initializeScene(); // 当配置加载完成后初始化场景
        console.log(modelConfig, "modelConfig");
        //浅拷贝
        this.modelConfig = {
          ...modelConfig
        };
        this.modelsOriginSkin = JSON.parse(JSON.stringify(modelConfig));
        console.log(this.modelConfig, "this.modelConfig");
        console.log(this.modelsOriginSkin, "this.modelConfig");
      }).catch(error => console.error("Error loading the configuration:", error));
    },
    handleZoom(event) {
      // 获取当前缩放因子
      const zoomFactor = this.controls.object.position.distanceTo(this.controls.target);
      // console.log("监测到移动了this.control");

      //this.controls.target.set(0, 0, 0);
      //console.log(this.TagDialogInfo);
      // 在这里添加更新功能，根据缩放因子进行相应的操作
      // 例如，更新模型的大小或者重新渲染场景等
      // this.updateSomething(zoomFactor);
    },

    formatTime(seconds) {
      let showTime = this.computedShowCurTimeMoment(this.startTimeOrigin, this.endTime, seconds, this.max);
      return showTime;
    },
    // 补零
    pad(value) {
      return value.toString().padStart(2, "0");
    },
    getEquipmentList(value) {
      this.equipmentList = value;
    },
    getIsMapStatus(value) {
      // console.log(value)
      this.isMapStatus = value;
    },
    getChooseFromMapFlag(value) {
      this.isChooseFromMapFlag = value;
      this.chooseFromMapArr = [];
      this.chooseFromMapIdArr = [];
    },
    onChangeColor(value) {
      console.log(value, "home ---onChangeColorvalue");
      if (value === 0) {
        // 原色
        this.changeColorToOrin();
      } else if (value == 1) {
        //配置色
        this.changeColorToConfigColor();
      }
    },
    changeColorToConfigColor() {
      //第一步：切换皮肤为配置色,目前配置色为深色
      //第二步：存入session中
      //第三步:加载时读取session
      this.gltf.scene.traverse(obj => {
        // 根据配置文件读出的数据和模型的obj进行匹配，正则运算匹配上模型元素和配置文件项的时候更改为配置文件中的数据
        this.modelConfig.models.forEach(item => {
          const namePattern = new RegExp(item.name);
          if (namePattern.test(obj.name) && namePattern.test(obj.name)) {
            const materialColor = new THREE.Color(item.material);
            const emissiveColor = new THREE.Color(item.emissive);
            obj.material.color.set(materialColor);
            obj.material.emissive.set(emissiveColor);
          }
        });
        //写法更精简了，上面代码已注释的代码可以全部删掉
      });
      //
      this.setSpriteToDark();
      this.hideSkybox();
    },
    changeColorToOrin() {
      //第一步：切换皮肤为原始颜色
      //第二步：存入session中
      //第三步:加载时读取session
      console.log("changeColorToOrin");
      // let orginColors = { ...this.buddingSkin };
      // console.log(orginColors, "orginColors");
      this.gltf.scene.traverse(obj => {
        this.modelsOriginSkin.models.forEach(modelItem => {
          const namePattern = new RegExp(modelItem.name);
          if (namePattern.test(obj.name)) {
            const materialColor = new THREE.Color(modelItem.material);
            const emissiveColor = new THREE.Color(modelItem.emissive);
            obj.material.color.set(materialColor);
            obj.material.emissive.set(emissiveColor);
          }
        });
      });
      this.recorverSpriteColor();
      this.showSkybox();
    },
    recorverSpriteColor() {
      //恢复精灵模型颜色
      this.scene.traverse(obj => {
        if (obj.isSprite) {
          // 对精灵模型进行处理
          console.log(`recorver sprite: ${obj.name}`);
          // 恢复原始颜色
          if (obj.userData.originalColor) {
            // 获取当前精灵的材质
            let spriteMaterial = obj.material;
            spriteMaterial.copy(obj.userData.originalColor); // 0.5表示混合程度，可以调整
            //spriteMaterial.opacity = 0.99;
          }
        }
      });
    },

    setSpriteToDark() {
      console.log(this.scene, "@@@@@@@@@@@@@@@@@@@@@@@@@@this.scene");
      this.scene.traverse(obj => {
        if (obj.isSprite) {
          // 对精灵模型进行处理
          console.log(`Found sprite: ${obj.name}`);
          // 获取当前精灵的材质
          let spriteMaterial = obj.material;

          // 保存原始颜色
          if (!obj.userData.originalColor) {
            console.log("保存原始颜色保存原始颜色");
            obj.userData.originalColor = obj.material.clone();
            console.log(obj.userData);
          }
          this.$nextTick(() => {
            // // 叠加颜色，例如：叠加红色
            let overlayColor = new THREE.Color(0x1f3341);
            // // 将现有颜色和叠加颜色混合
            spriteMaterial.color.lerp(overlayColor, 0.5); // 0.5表示混合程度，可以调整
          });
        }
      });
    },

    // 显示天空盒子
    showSkybox() {
      if (this.skybox) {
        this.skybox.visible = true;
      }
    },
    // 隐藏天空盒子
    hideSkybox() {
      if (this.skybox) {
        this.skybox.visible = false;
      }
    },
    updateTime() {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString();
    },
    // 初始化
    init() {
      this.createScene(); // 创建场景

      this.loadGLTF(); // 加载GLTF模型

      // this.loadTreePng(); //加载精灵模型
      this.createLight(); // 创建光源
      this.createCamera(); // 创建相机
      this.createRender(); // 创建渲染器
      this.createControls(); // 创建控件对象

      this.createCSS2DRenderer(), window.onclick = this.onMouseClick; //用户点击事件
      this.render(); // 渲染

      this.resizeFn(); // 适应窗口
    },

    // 创建场景
    createScene() {
      this.scene = new THREE.Scene();
    },
    // 加载GLTF模型
    loadGLTF() {
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      //setDecoderPath需要把这个draco拷贝出来，位置在nodemodules->three->examples->jsm->libs
      dracoLoader.setDecoderPath("./draco/");
      loader.setDRACOLoader(dracoLoader);
      loader.load(`/static/b8.glb`, gltf => {
        this.loadSky(); //加载天空
        this.loadTreePng(); //加载树

        this.gltf = gltf;
        this.scene.add(gltf.scene);
        const that = this;
        //获取jz-开头qt结尾的原模型的原颜色保存入变量。用户需要变回深色模式时取值
        gltf.scene.traverse(obj => {
          this.modelsOriginSkin.models.forEach(oriItem => {
            const namePattern = new RegExp(oriItem.name);
            if (namePattern.test(obj.name)) {
              //初始化
              oriItem.material = obj.material.color.clone();
              oriItem.emissive = obj.material.emissive.clone();
            }
          });

          //下面这段实现每个对象都接收阴影
          if (obj.isMesh) {
            obj.castShadow = true; // 设置网格投射阴影
            obj.receiveShadow = true; // 设置网格接收阴影
          }
        });

        gltf.scene.traverse(function (obj) {
          that.controls.addEventListener("change", function () {
            if (that.isMapStatus == undefined) {
              if (that.controls.getDistance() < 200) {
                if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
                  obj.material.transparent = true;
                  obj.material.opacity = 0.1;
                }
              } else {
                if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
                  obj.material.transparent = true;
                  obj.material.opacity = 0.9;
                }
              }
            }
          });

          //复制了一个回水管道，仅改变y轴
          if (obj.name == "sb-gd-01") {
            obj.material.transparent = true;
            const gd = obj.clone();
            gd.name = "sb-gd-022";
            gd.position.y = obj.position.y + 2;
            that.scene.add(gd);
          }
          if (obj.name.indexOf("sb-") > -1) {
            // console.log(obj.material.color, "obj.material.color");
            that.colorClone = obj.material.color.clone();
            if (obj.name.includes("sb-gfb")) {
              that.gfbOrinColor = obj.material.color.clone();
            }
            const deviceNameArr = obj.name.split("-");
            let deviceName = "",
              deviceCode = "",
              childCode = "";
            if (deviceNameArr[1] == "zg") {
              deviceName = "蒸锅" + deviceNameArr[2].replace(/\b(0+)/gi, "");
              deviceCode = "steamer";
            }
            if (deviceNameArr[1] == "hrq") {
              deviceName = "余热回收系统" + deviceNameArr[2].replace(/\b(0+)/gi, "");
              deviceCode = "caloric_recup";
            }
            if (deviceNameArr[1] == "kqyrb") {
              deviceName = "空气源热泵" + deviceNameArr[2].replace(/\b(0+)/gi, "");
              deviceCode = "airPump";
            }
            if (deviceNameArr[1] == "gwsyrb") {
              deviceName = "高温水源热泵机房1";
              deviceCode = "waterPump";
            }
            if (deviceNameArr[1] == "rqzqgl") {
              deviceName = "燃气锅炉1";
              deviceCode = "gasBoiler";
            }
            if (deviceNameArr[1] == "gfb") {
              deviceName = "光伏系统" + deviceNameArr[2].replace(/\b(0+)/gi, "");
              deviceCode = "PV";
            }
            obj.deviceName = deviceName;
            obj.deviceCode = deviceCode;
            that.equipmentList.forEach(element => {
              if (obj.deviceName != "") {
                const eName = obj.deviceName.match(/[\u4e00-\u9fa5]+/g).join("");
                if (eName == element.name) {
                  element.children.forEach(item => {
                    if (obj.deviceName == item.name) {
                      obj.childId = item.id;
                      obj.childCode = item.code;
                    }
                  });
                }
              }
            });
            obj.flag = "device";
            that.clickArr.push(obj);
          }
          if (obj.name.indexOf("bl") > -1 || obj.name.indexOf("dm") > -1) {
            obj.material.transparent = true;
            obj.material.opacity = 0.7;
            obj.renderOrder = 999;
          }
          if (obj.name.includes("sb-gfb")) {
            obj.material.transparent = true;
            obj.material.opacity = 1;
            obj.renderOrder = 999;
          }
        });
        document.getElementById("loading-box").style.display = "none";
        //管道运行起来
        this.start();
        this.initAlramDetailTag(); //初始化报警详情窗口
      });
    },

    //加载天空
    async loadSky() {
      const params = {
        height: 15,
        radius: 470,
        //天空盒子半径
        enabled: true
      };
      let skybox;
      const hdrLoader = new RGBELoader();
      const envMap = await hdrLoader.loadAsync("./static/sky.hdr");
      envMap.mapping = THREE.EquirectangularReflectionMapping;
      skybox = new GroundedSkybox(envMap, params.height, params.radius);
      skybox.position.y = params.height - 30.01; //天空盒子设置的只比模型微低0.01
      //skybox.position.y = params.height + 30.01;
      this.scene.add(skybox);
      this.scene.environment = envMap;
      this.skybox = skybox;
    },
    loadTreePng() {
      this.loadGrassSpirit();
      this.loadStoneVat();
      this.loadTreeSpirit();
    },
    loadStoneVat() {
      // 加载石头鱼缸纹理贴图
      let textureTree = new THREE.TextureLoader().load("./spirit/gang_small.png");
      var spriteMaterial = new THREE.SpriteMaterial({
        map: textureTree,
        //设置精灵纹理贴图
        transparent: true,
        alphaTest: 0.05,
        // 设置一个透明度测试阈值
        alphaToCoverage: true
      });
      spriteMaterial.map.colorSpace = "srgb"; //人眼睛可接受的颜色
      var sprite = new THREE.Sprite(spriteMaterial);
      sprite.scale.set(1.5, 1.5, 1);
      this.scene.add(sprite);
      sprite.position.set(6.7, 15.8, 42);
    },
    loadGrassSpirit() {
      // 加载草纹理贴图
      let textureTree = new THREE.TextureLoader().load("./spirit/grass1_small.png");
      let textureTree2 = new THREE.TextureLoader().load("./spirit/grass2_small.png");

      //主楼门前的小草
      var spriteMaterial = new THREE.SpriteMaterial({
        map: textureTree,
        //设置精灵纹理贴图
        transparent: true,
        alphaTest: 0.05,
        // 设置一个透明度测试阈值
        alphaToCoverage: true
      });
      spriteMaterial.map.colorSpace = "srgb"; //人眼睛可接受的颜色

      //主楼一楼门前草
      for (let i = 0; i < 63; i++) {
        // 创建精灵模型对象
        let sprite = new THREE.Sprite(spriteMaterial);
        // 控制精灵大小,
        sprite.scale.set(2, 2, 1);
        // 添加精灵模型到场景
        this.scene.add(sprite);
        // var k1 = Math.random() - 0.5;
        // var k2 = Math.random() - 0.5;
        // 设置精灵模型位置，在xoz平面上随机分布，位置
        // sprite.position.set(1000 * k1, 5, 1000 * k2);
        let leftTreeNum = 29;
        if (i < leftTreeNum) {
          //前面12颗种在大楼右边
          sprite.position.set(1.2 * i + 13, 1, 62);
        } else {
          // 左面的草
          var symmetricIndex = i - leftTreeNum; // 获取对称的索引
          sprite.position.set(1.2 * symmetricIndex * -1 + 2, 1, 62);
        }
      }
      //主楼二楼草
      for (let i = 0; i < 67; i++) {
        //主楼房顶的小草
        let sprite = new THREE.Sprite(spriteMaterial);
        this.scene.add(sprite);
        // 控制精灵大小,
        sprite.scale.set(2, 2, 1);
        if (i < 21) {
          sprite.position.set(1.2 * i - 26, 16, 56);
        } else if (i < 29) {
          let symmetricIndex = i - 21;
          sprite.position.set(1.2 * symmetricIndex + 18, 16, 56);
        } else if (i < 42) {
          let symmetricIndex = i - 21;
          sprite.position.set(1.2 * symmetricIndex + 18, 16.3, 56);
        } else if (i < 46) {
          let xIndex = 42 - 23;
          let zIndex = i - 42 + 1;
          sprite.position.set(1.2 * xIndex + 18, 16.2, 56 - zIndex * 1.3);
        } else if (i < 50) {
          let symmetricIndex = i - 46;
          sprite.position.set(1.2 * symmetricIndex + 28, 16, 50.5);
        } else if (i < 54) {
          let symmetricIndex = i - 46;
          sprite.position.set(1.2 * symmetricIndex + 33, 16, 50.5);
        } else if (i < 63) {
          let xIndex = 0;
          let zIndex = i - 54 + 1;
          sprite.position.set(1.2 * xIndex - 26, 16, 56 - zIndex * 1.3);
        } else {
          let symmetricIndex = i - 64;
          sprite.position.set(1.2 * symmetricIndex - 25.8, 15.8, 43.5);
          sprite.scale.set(1.7, 1.5, 1);
        }
      }

      //花坛草
      var spriteMaterialHua = new THREE.SpriteMaterial({
        map: textureTree2,
        //设置精灵纹理贴图
        transparent: true,
        alphaTest: 0.05,
        // 设置一个透明度测试阈值
        alphaToCoverage: true
      });
      spriteMaterialHua.map.colorSpace = "srgb"; //人眼睛可接受的颜色
      // 创建精灵模型对象
      let spriteHua = new THREE.Sprite(spriteMaterialHua);
      spriteHua.scale.set(4, 5, 1);
      this.scene.add(spriteHua);
      spriteHua.position.set(69, 3, 43);
    },
    loadTreeSpirit() {
      // 加载树纹理贴图
      let textureTree = new THREE.TextureLoader().load("./spirit/greenTree2_small.png");
      let textureTree2 = new THREE.TextureLoader().load("./spirit/greenTree1_small.png");
      let textrureTreeHua = new THREE.TextureLoader().load("./spirit/redTree_small.png");
      let textrureTree5 = new THREE.TextureLoader().load("./spirit/greenTree5_small.png");
      //主楼一楼
      let spriteMaterial = new THREE.SpriteMaterial({
        map: textureTree,
        //设置精灵纹理贴图
        transparent: true,
        alphaTest: 0.05,
        // 设置一个透明度测试阈值
        alphaToCoverage: true
      });
      for (let i = 0; i < 10; i++) {
        spriteMaterial.map.colorSpace = "srgb"; //人眼睛可接受的颜色
        var sprite = new THREE.Sprite(spriteMaterial);
        // 控制精灵大小,
        sprite.scale.set(4, 7, 1);
        // 添加精灵模型到场景
        this.scene.add(sprite);
        if (i < 5) {
          //前面12颗种在大楼右边
          sprite.position.set(8 * i + 14, 3, 61.8);
        } else {
          // 左面的草
          var symmetricIndex = i - 5; // 获取对称的索引
          sprite.position.set(8 * symmetricIndex * -1, 3, 61.8);
        }
      }
      let spriteMaterialTree2 = new THREE.SpriteMaterial({
        map: textureTree2,
        //设置精灵纹理贴图
        transparent: true,
        alphaTest: 0.05,
        // 设置一个透明度测试阈值
        alphaToCoverage: true
      });
      spriteMaterialTree2.map.colorSpace = "srgb"; //人眼睛可接受的颜色
      //主楼二楼的树
      for (let i = 0; i < 3; i++) {
        let sprite2 = new THREE.Sprite(spriteMaterialTree2);
        sprite2.scale.set(4, 6, 1);
        this.scene.add(sprite2);
        if (i < 2) {
          sprite2.position.set(26 - i * 5, 17, 39);
        } else {
          sprite2.scale.set(7, 10, 1);
          sprite2.position.set(-7, 19, 39);
        }
      }
      //花坛的树
      let spriteMaterialTreePink = new THREE.SpriteMaterial({
        map: textrureTreeHua,
        //设置精灵纹理贴图
        transparent: true,
        alphaTest: 0.05,
        // 设置一个透明度测试阈值
        alphaToCoverage: true
      });
      spriteMaterialTreePink.map.colorSpace = "srgb"; //人眼睛可接受的颜色
      let spriteHua = new THREE.Sprite(spriteMaterialTreePink);
      spriteHua.scale.set(12, 15, 1);
      this.scene.add(spriteHua);
      spriteHua.position.set(73, 8, 39);

      //外围树
      let spriteMaterialTreeWai = new THREE.SpriteMaterial({
        map: textrureTree5,
        //设置精灵纹理贴图
        transparent: true,
        alphaTest: 0.05,
        // 设置一个透明度测试阈值
        alphaToCoverage: true
      });
      spriteMaterialTreeWai.map.colorSpace = "srgb"; //人眼睛可接受的颜色
      for (let i = 0; i < 33; i++) {
        let sprite = new THREE.Sprite(spriteMaterialTreeWai);
        sprite.scale.set(5, 8, 1);
        this.scene.add(sprite);
        sprite.position.set(i * 11.39 - 228.56, 4, 103.5);
      }
    },
    // 创建光源
    createLight() {
      // 环境光
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.3); // 创建环境光
      this.scene.add(ambientLight); // 将环境光添加到场景
      // const spotLight = new THREE.SpotLight(0xffffff, 1); // 创建聚光灯,聚光灯具有强反射注释掉后续按需使用，
      // spotLight.intensity = 50; //光照强度
      // spotLight.decay = 0.0; //设置光源不随距离衰减
      // spotLight.position.set(-200, 100, -200);
      // this.scene.add(spotLight);
      // // 聚光源辅助对象，可视化聚光源
      // const spotLightHelper = new THREE.SpotLightHelper(spotLight, 0xffffff);
      // this.scene.add(spotLightHelper);
      // const lightness = new THREE.AmbientLight(0xffffff, 1);
      // this.scene.add(lightness);
      //平行光
      const shadowLight = new THREE.DirectionalLight(0xfbf5fa, 5);
      //const shadowLight = new THREE.DirectionalLight(0xa0a0a0, 8);
      shadowLight.position.set(50, 60, 50);
      shadowLight.castShadow = true; // 使光源投射阴影
      shadowLight.shadow.bias = -0.000009;
      this.scene.add(shadowLight);
      //平行光2
      const shadowLight2 = new THREE.DirectionalLight(0xf0f0f0, 1);
      shadowLight2.position.set(-50, 70, -50);
      shadowLight2.shadow.bias = -0.00009;
      this.scene.add(shadowLight2);

      //console.log(this.scene, "@@@@@@@@@@@@@@@@this.scene");
      // 设置阴影的参数
      shadowLight.shadow.mapSize.width = 2048; // 阴影贴图大小
      shadowLight.shadow.mapSize.height = 2048;
      shadowLight.shadow.camera.near = 0.5;
      shadowLight.shadow.camera.far = 400;
      shadowLight.shadow.camera.left = -200;
      shadowLight.shadow.camera.right = 200;
      shadowLight.shadow.camera.top = 200;
      shadowLight.shadow.camera.bottom = -200;
    },
    // 创建相机
    createCamera() {
      const element = document.getElementById("container");
      const width = element.clientWidth; // 窗口宽度
      const height = element.clientHeight; // 窗口高度
      const k = width / height; // 窗口宽高比
      // PerspectiveCamera( fov, aspect, near, far )
      this.camera = new THREE.PerspectiveCamera(25, k, 10, 3000);
      this.camera.position.set(250, 360, 250); // 设置相机位置

      this.camera.lookAt(new THREE.Vector3(10, 40, 0)); // 设置相机方向
      this.scene.add(this.camera);

      // 创建相机辅助
      // const cameraHelper = new THREE.CameraHelper(this.camera);
      // this.scene.add(cameraHelper);
    },

    // 创建渲染器
    createRender() {
      const element = document.getElementById("container");
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        logarithmicDepthBuffer: true
      });
      this.renderer.setSize(element.clientWidth, element.clientHeight); // 设置渲染区域尺寸
      this.renderer.shadowMap.enabled = true; // 显示阴影
      this.renderer.shadowMap.type = THREE.VSMShadowMap; //VSMShadowMap模式更柔和，没有边框没有条纹
      //this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      // this.renderer.setClearColor(0xffffff, 1); // 设置背景颜色
      //  this.renderer.outputEncoding = THREE.sRGBEncoding;
      //this.renderer.outputColorSpace = THREE.SRGBColorSpace;

      element.appendChild(this.renderer.domElement);
    },
    //创建标签
    createCSS2DRenderer() {
      this.css2Renderer.setSize(this.width, this.height);
      // HTML标签<div id="tag"></div>外面父元素叠加到canvas画布上且重合
      this.css2Renderer.domElement.style.position = "absolute";
      this.css2Renderer.domElement.style.top = "0px";
      //设置.pointerEvents=none，解决HTML元素标签对threejs canvas画布鼠标事件的遮挡
      this.css2Renderer.domElement.style.pointerEvents = "none";
      document.body.appendChild(this.css2Renderer.domElement);
    },
    // 创建控件对象
    createControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.minPolarAngle = 0;
      //   this.controls.maxPolarAngle = Math.PI / 2.5;
      this.controls.maxPolarAngle = Math.PI / 2.3; //用户旋转模型最大角度
      this.controls.minAzimuthAngle = 10;
      this.controls.target.set(0, 0, 0);
      this.controls.minDistance = 80; // 设置最小距离为800
      this.controls.maxDistance = 750; // 设置最大距离为1800
      this.controls.update();
      this.controls.addEventListener("end", this.onControlsEnd);
      //设置带阻尼的惯性
      // this.controls.enableDamping = true;
      // this.controls.dampingFactor = 0.5;
    },

    onControlsEnd(event) {
      //鼠标拖动右键结束
      console.log("onControlsEndon", event);
    },
    // 点击模型事件
    onMouseClick(event) {
      // .offsetY、.offsetX以canvas画布左上角为坐标原点,单位px
      console.log(event, "event", this.width, this.height, "鼠标偏移", event.offsetX, event.offsetY);
      const element = document.getElementById("container");
      let getBoundingClientRect = element.getBoundingClientRect();
      // const px = event.offsetX;
      // const py = event.offsetY;
      // const x = (px / this.width) * 2 - 1;
      // const y = -(py / this.height) * 2 + 1;
      const x = (event.clientX - getBoundingClientRect.left) / element.offsetWidth * 2 - 1;
      const y = -((event.clientY - getBoundingClientRect.top) / element.offsetHeight) * 2 + 1;
      //  this.scene.updateMatrixWorld(true);
      const raycaster = new THREE.Raycaster();
      // raycaster.params = {
      //   Mesh: { threshold: 0.2 }, // 根据需要调整阈值确定选中精度
      // };
      raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
      if (!this.isTimeline) {
        console.log("!this.isTimeline");
        // const intersects = raycaster.intersectObjects(this.scene.children, true);
        // console.log(this.clickArr, "this.clickArr");
        const intersects = raycaster.intersectObjects(this.clickArr, true);
        if (intersects.length > 0) {
          console.log(intersects[0].object, "射线返回的对象intersects");
          console.log(intersects, "射线返回的对象intersects[0].object");
          this.interactWithObject(event, intersects[0].object);
        }
      } else {
        console.log(this.isTimeline, "this.isTimeline");
        const intersects = raycaster.intersectObjects(this.timelineArr, true);
        if (intersects.length > 0) {
          this.interactWithObject(event, intersects[0].object);
        }
      }
    },
    // 执行渲染
    render() {
      this.css2Renderer.render(this.scene, this.camera);
      this.renderer.render(this.scene, this.camera);
      texture.offset.x -= 0.05;
      if (this.composer) {
        this.composer.render();
      }
      this.next += 0.12;
      this.ratio.value = this.next;
      requestAnimationFrame(this.render);
      TWEEN.update();
      //  console.log(this.camera.position);
    },

    // 画布变化大小自适应
    resizeFn() {
      // window.addEventListener("resize", () => {
      //   this.camera.aspect = window.innerWidth / window.innerHeight;
      //   this.camera.updateProjectionMatrix();
      //   this.renderer.setSize(window.innerWidth, window.innerHeight);
      //   this.renderer.setPixelRatio(window.devicePixelRatio);
      //   this.width = window.innerWidth;
      //   this.height = window.innerHeight;
      //   this.css2Renderer.setSize(this.width, this.height);

      //   console.log(this.width, "this.width", this.height, "this.height");
      // });
      this.resizeRenderer(); //初始调用
      window.addEventListener("resize", () => {
        this.resizeRenderer(); //页面大小发生改变时调用
      });
    },

    resizeRenderer() {
      const element = document.getElementById("container");
      const width = element.clientWidth;
      const height = element.clientHeight;
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.width = width;
      this.height = height;
      this.css2Renderer.setSize(this.width, this.height);
      console.log(this.width, "this.width", this.height, "this.height");
    },
    // 点击设备弹出信息
    interactWithObject(event, object) {
      // console.log(object,";;;;;;;;;;;;;")
      // 如果是地图选中状态 —— 点击设备变色
      if (this.isMapStatus == true) {
        const {
          deviceName,
          childId,
          material
        } = object;
        const {
          radio
        } = this.searchData;
        if (deviceName.indexOf(radio) === -1 || deviceName === "") return;
        if (radio === "光伏系统" && (this.chooseFromMapArr.length >= 6 || this.chooseFromMapIdArr.length >= 6)) {
          this.$message.error("光伏系统最多可选择6个子设备！");
          return;
        }
        // 检查是否已经存在相同的设备名或子ID
        if (this.chooseFromMapArr.includes(deviceName) || this.chooseFromMapIdArr.includes(childId)) {
          this.$message.warning("设备已经存在！");
          return;
        }
        this.chooseFromMapArr.push(deviceName);
        this.chooseFromMapIdArr.push(childId);
        material.color.set(0x00ffff);
      } else {
        // 阻止点击穿透
        console.log("进入阻止点击穿透！！");
        console.log(HTMLCanvasElement, "HTMLCanvasElement info");
        if (!(event.target instanceof HTMLCanvasElement)) {
          console.log("没选中");
          return;
        }
        let deviceArr = [];
        deviceArr.push(object.childId);
        let params = {
          paramType: object.deviceCode,
          deviceId: deviceArr
        };
        if (!this.isTimeline) {
          // 非地图选中状态 选中弹窗
          console.log("非地图选中状态");
          equipmentMsg(params).then(res => {
            if (res.data.length != 0) {
              this.addTag(params, res.data[0], object, 15, event);
            } else if (object.name.indexOf("jk") > -1) {
              this.addVideoTag(object, 20);
            } else {
              this.$message.error("暂无数据");
            }
          }).catch();
        } else {
          console.log("地图选中状态");
          for (let i in this.timeLineResult) {
            if (object.childId == i) {
              let index = 0;
              if (this.timeline == 0) {
                index = 0;
              } else {
                index = this.timeline - 1;
              }
              this.addTag(params, this.timeLineResult[i][index], object, 20);
            }
          }
        }
      }
    },
    // 根据设备类型 向模型中新增设备信息弹出框
    addTag(params, data, object, offset, event) {
      console.log("jinruaddTag");
      const div = document.getElementById("infoBox").cloneNode(true);
      div.style.pointerEvents = "auto";
      div.classList.add("cloneDiv");
      let str = ``;
      if (params.paramType == "gasBoiler") {
        str = `
        <div class="equipment-msg" style="height:120px;">
          <p class="a-name" deviceId="` + data.deviceId + `">` + data.name + `</p>
          <p class="detail">燃气量` + data.gasVolume + `m³/h</p>
          <p class="detail">蒸汽量` + data.steamVolume + `t/h<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'steamVolume')"></i></p>
          <p class="detail">燃气入口压力` + data.inletPressure + `kPa</p>
          <p class="detail">蒸汽温度` + data.steamTemp + `℃</p>
          <p class="detail">蒸汽压力` + data.steamPressure + `Mpa</p>
        </div>`;
      }
      if (params.paramType == "steamer") {
        str = `
        <div class="equipment-msg">
          <p class="a-name" deviceId="` + data.deviceId + `">` + data.name + `</p>
          <p class="detail">蒸汽热量` + data.stearmCaloric + `kW<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'stearmCaloric')"></i></p>
        </div>`;
      }
      if (params.paramType == "caloric_recup") {
        str = `
        <div class="equipment-msg" style="height:120px;">
          <p class="a-name" deviceId="` + data.deviceId + `">` + data.name + `</p>
          <p class="detail">余热量` + data.residueCaloric + `kW<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'residueCaloric')"></i></p>
          <p class="detail">余热回收率` + data.residueCaloricRate + `%<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'residueCaloricRate')"></i></p>
          <p class="detail">进水温度` + data.waterTempIn + `℃</p>
          <p class="detail">出水温度` + data.waterTempOut + `℃</p>
          <p class="detail">水流量` + data.waterFlowNum + `t/h</p>
        </div>`;
      }
      if (params.paramType == "waterPump") {
        str = `
        <div class="equipment-msg" style="height:140px;">
          <p class="a-name" deviceId="` + data.deviceId + `">` + data.name + `</p>
          <p class="detail">高温热泵制蒸汽量` + data.steamVolume + `t/h</p>
          <p class="detail">COP` + data.waterPumpCOP + `<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'waterPumpCOP')"></i></p>
          <p class="detail">耗电量` + data.powerConsumption + `kWh<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'powerConsumption')"></i></p>
          <p class="detail">绿电占比` + data.greenPowerRate + `%<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'greenPowerRate')"></i></p>
          <p class="detail">消纳绿电` + data.consumeGreenPower + `kWh</p>
          <p class="detail">热泵制热量` + data.heatTempNum + `kW<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'heatTempNum')"></i></p>
          <p class="detail">热泵冷凝器水流量` + data.condWaterNum + `t/h</p>
          <p class="detail">热泵冷凝器进水温度` + data.condWaterTempIn + `℃</p>
          <p class="detail">热泵冷凝器出水温度` + data.condWaterTempOut + `℃</p>
          <p class="detail">热泵蒸发器进水温度` + data.evapWaterTempIn + `℃</p>
          <p class="detail">热泵蒸发器出水温度` + data.evapWaterTempOut + `℃</p>
        </div>`;
      }
      if (params.paramType == "PV") {
        str = `
        <div class="equipment-msg" style="height:140px;">
          <p class="a-name" deviceId="` + data.deviceId + `">` + data.name + `</p>
          <p class="detail">发电量` + data.powerGenerationNum + `kWh<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'powerGenerationNum')"></i></p>
          <p class="detail">发电效率` + data.powerGenerationRate + `%</p>
          <p class="detail">消纳电量` + data.consumePowerNum + `kWh</p>
          <p class="detail">上网电量` + data.internetPowerNum + `kWh</p>
          <p class="detail">光照强度` + data.lightIntensity + `</p>
          <p class="detail">背板温度` + data.backboardTemp + `℃</p>
        </div>`;
      }
      if (params.paramType == "airPump") {
        str = `
        <div class="equipment-msg" style="height:140px;">
          <p class="a-name" deviceId="` + data.deviceId + `">` + object.deviceName + `</p>
          <p class="detail">供热量` + data.heatTempNum + `kW<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'heatTempNum')"></i></p>
          <p class="detail">COP` + data.airPumpCOP + `<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'airPumpCOP')"></i></p>
          <p class="detail">耗电量` + data.powerConsumption + `kWh<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'powerConsumption')"></i></p>
          <p class="detail">绿电占比` + data.greenPowerRate + `%<i class="el-icon-s-marketing el-icon--right params-chart-icon" onclick="getSingleEquipmentData(` + JSON.stringify(params).replace(/"/g, "&quot;") + `,` + data.deviceId + `,'greenPowerRate')"></i></p>
          <p class="detail">消纳绿电` + data.consumeGreenPower + `kWh</p>
          <p class="detail">水流量` + data.waterFlowNum + `t/h</p>
          <p class="detail">空气源热泵供水温度` + data.airPumpWaterTempIn + `℃</p>
          <p class="detail">空气源热泵回水温度` + data.airPumpWaterTempOut + `℃</p>
        </div>`;
      }
      div.childNodes[0].innerHTML = str; //标签数据填写
      div.childNodes[0].style.pointerEvents = "auto";

      // 关闭按钮
      let closebtn = document.createElement("div");
      closebtn.innerHTML = '<a class="close-btn"><i class="el-icon-close el-icon--right"></i></a>';
      div.appendChild(closebtn);
      closebtn.style.pointerEvents = "auto";
      const that = this;
      closebtn.addEventListener("click", function (event) {
        that.scene.remove(tag);
      });

      // 展开折叠详情按钮及点击事件
      if (object.deviceCode != "steamer") {
        var showDetail = document.createElement("div");
        showDetail.innerHTML = '<a style="font-size:12px;margin-left:20px;cursor:pointer;">展开详情<i class="el-icon-arrow-down el-icon--right"></i></a>';
        div.appendChild(showDetail);
        showDetail.style.pointerEvents = "auto";
        showDetail.addEventListener("click", function (event) {
          if (showDetail.innerHTML.indexOf("展开") > -1) {
            showDetail.innerHTML = '<a style="font-size:12px;margin-left:20px;cursor:pointer;">收起详情<i class="el-icon-arrow-up el-icon--right"></i></a>';
            div.childNodes[1].childNodes[0].nextSibling.style.height = "auto";
            div.style.height = "auto";
          } else {
            showDetail.innerHTML = '<a style="font-size:12px;margin-left:20px;cursor:pointer;">展开详情<i class="el-icon-arrow-down el-icon--right"></i></a>';
            if (object.deviceCode == "gasBoiler" || object.deviceCode == "caloric_recup") {
              div.childNodes[1].childNodes[0].nextSibling.style.height = "120px";
              div.style.height = "200px";
            }
            if (object.deviceCode == "waterPump" || object.deviceCode == "airPump" || object.deviceCode == "PV") {
              div.childNodes[1].childNodes[0].nextSibling.style.height = "140px";
              div.style.height = "200px";
            }
          }
        });
      } else {
        var showDetail = document.createElement("div");
        div.appendChild(showDetail);
      }

      // 切换按钮
      let tabBtn = document.createElement("div");
      tabBtn.classList.add("tab");
      tabBtn.innerHTML = "<span>设备信息</span>";
      div.firstChild.before(tabBtn);
      tabBtn.style.pointerEvents = "auto";
      tabBtn.firstChild.addEventListener("click", function (event) {
        div.childNodes[1].style.display = "block";
        div.childNodes[2].style.display = "none";
        showDetail.style.display = "block";
      });

      // 图表返回设备信息
      div.childNodes[2].firstChild.addEventListener("click", function (event) {
        div.childNodes[0].style.display = "block";
        div.childNodes[1].style.display = "block";
        div.childNodes[2].style.display = "none";
        div.childNodes[3].style.display = "block";
        div.childNodes[4].style.display = "block";
      });
      this.cloneDivObj.push(div);

      // 标签显示
      const tag = new CSS2DObject(div);
      tag.scale.set(0.15, 0.15, 0.15); //适当缩放模型标签
      tag.name = "设备名称";

      //这段代码修复弹窗可能会超出界面的问题
      let obOffsetY = offset;
      let obOffsetX = 0;
      console.log(event, "addTag event");
      if (event) {
        if (event.offsetY < 230) {
          console.log("event.offsetY < 230)  执行obOffsetY = 0", obOffsetY);
          obOffsetY = 0;
        }
        if (event.offsetX < 310) {
          if (event.offsetX < 100) {
            obOffsetX = 20;
          } else {
            obOffsetX = 15;
          }
        }
      }
      this.css2Renderer.setSize(this.width, this.height);
      tag.position.set(object.position.x + obOffsetX, object.position.y + obOffsetY, object.position.z);

      // this.removeTag();
      this.scene.add(tag);
      const cameraPosition = this.camera.position.clone();
      tag.lookAt(cameraPosition);
    },
    // 弹出监控设备信息框
    addVideoTag(object, offset) {
      const div = document.getElementById("infoBox").cloneNode(true);
      div.style.pointerEvents = "auto";
      div.classList.add("cloneDiv");
      let str = ``;
      if (object.name.indexOf("jk") > -1) {
        str = `<video id="media" src="http://ie.zhiboredian.net/static/video.mp4" class="video" controls controlslist="nodownload noremoteplayback noplaybackrate  foobar"
        autoplay muted playsinline width="90%" heigt="95%" style="margin:13% 5%;">您的浏览器不支持播放此视频。</video>`;
        // str = `<video id="media" src="http://ie.zhiboredian.net/static/video.mp4"  autoplay muted playsinline width="90%" heigt="95%" style="margin:13% 5%;"></video>`;

        div.childNodes[1].innerHTML = str; //标签数据填写
        div.childNodes[1].style.pointerEvents = "auto";
      }
      div.childNodes[0].innerHTML = str; //标签数据填写
      div.childNodes[0].style.pointerEvents = "auto";

      // 关闭按钮
      let closebtn = document.createElement("div");
      closebtn.innerHTML = '<a class="close-btn"><i class="el-icon-close el-icon--right"></i></a>';
      div.appendChild(closebtn);
      closebtn.style.pointerEvents = "auto";
      const that = this;
      closebtn.addEventListener("click", function (event) {
        that.scene.remove(tag);
      });

      // 标签显示
      const tag = new CSS2DObject(div);
      tag.scale.set(0.15, 0.15, 0.15); //适当缩放模型标签
      tag.name = "设备名称";
      tag.position.set(object.position.x, object.position.y + offset, object.position.z);
      // this.removeTag();
      this.scene.add(tag);
      const cameraPosition = this.camera.position.clone();
      tag.lookAt(cameraPosition);
      setTimeout(function () {
        const video = document.querySelector("#media");
        video.play();
      }, 1000);
    },
    // 删除全部弹出的设备信息标签
    removeTag() {
      const meshes = this.scene.children.filter(o => {
        // 这里的Line 是上图中的Line， mespoint 就是白色小球， 由于这里我把css2dObject 实例添加到了line中（这可能就是我用上面博客中的方法不生效的原因？？？），所以我这里需要，找到line， 当然切换的话，肯定小球，线，标签都需要remove
        return o.name == "设备名称";
      });
      meshes.forEach(l => {
        l.remove(...l.children); // 这里就是关键的地方，将 line下的 child 移除掉，
      });

      this.scene.remove(...meshes); // 最后将line,小球一并移除掉
    },

    // 获取单个设备图表信息
    getSingleEquipmentData(a, deviceId, paramsCode) {
      if (deviceId != "" || deviceId != null || deviceId != undefined) {
        let params = {},
          paramsType = [],
          deviceIdArr = [];
        let startTime = new Date(new Date().toLocaleDateString()).getTime().toString();
        let endTime = new Date().getTime().toString();
        paramsType.push(a.paramType);
        deviceIdArr.push(deviceId);
        params.createdStartTime = startTime.substring(0, startTime.length - 3);
        params.createdEndTime = endTime.substring(0, endTime.length - 3);
        params.paramType = paramsType;
        params.deviceId = deviceIdArr;
        params.params = paramsCode;
        let key = a.paramType;
        const that = this;
        this.cloneDivObj.forEach(element => {
          let tagId = element.childNodes[1].childNodes[1].childNodes[1].getAttribute("deviceId");
          let chartDom = element.childNodes[2].lastChild;
          if (deviceId == tagId) {
            element.childNodes[0].style.display = "none";
            element.childNodes[1].style.display = "none";
            element.childNodes[2].style.display = "block";
            element.childNodes[3].style.display = "none";
            element.childNodes[4].style.display = "none";
            allEquipmentSearch(params).then(res => {
              // console.log(res,"====================")
              if (res.data.length != 0 && res.data[key] != undefined) {
                let xAxisArr = [],
                  seriesData = [],
                  legendArr = [],
                  lineData = [];
                res.data[key].forEach(element => {
                  element.data.forEach(item => {
                    lineData.push(item[paramsCode]);
                  });
                  legendArr.push(element.name);
                  seriesData.push({
                    name: element.name,
                    data: lineData,
                    type: "line",
                    smooth: true,
                    areaStyle: {
                      normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                          offset: 0,
                          color: "rgba(55,162,218,0.3)"
                        }, {
                          offset: 1,
                          color: "rgba(55,162,218,0)"
                        }], false),
                        shadowColor: "rgba(55,162,218, 0.5)",
                        shadowBlur: 1
                      }
                    }
                  });
                });
                res.data[key][0].data.forEach(element => {
                  xAxisArr.push(element.createTime);
                });
                that.equipmentTabChart(chartDom, xAxisArr, legendArr, "时间/h", res.data.params[paramsCode], seriesData);
              } else {
                that.equipmentTabChart(chartDom, [], [], "时间/h", "", []);
              }
            }).catch();
          }
        });
      }
    },
    // 单个设备图表
    equipmentTabChart(chartDom, xAxisArr, legendArr, xAxisName, yAxisName, seriesData) {
      const chart = this.$echarts.init(chartDom);
      chart.clear();
      chart.setOption({
        tooltip: {
          trigger: "axis"
        },
        legend: {
          top: "10px",
          textStyle: {
            color: []
          },
          data: legendArr
        },
        grid: {
          top: legendArr.length <= 3 ? "25%" : "35%",
          bottom: "17%",
          left: "17%",
          right: "10%"
        },
        color: "#37a2da",
        xAxis: {
          name: xAxisName,
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#d8d8d8",
              width: 1
            }
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 12,
            padding: [8, 0, 0, -15],
            verticalAlign: "top"
          },
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              var ret = ""; //拼接加\n返回的类目项
              var maxLength = 4; //每项显示文字个数
              var valLength = value.length; //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1) {
                //如果类目项的文字大于5,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; //每次截取的字符串
                  var start = i * maxLength; //开始截取的位置
                  var end = start + maxLength; //结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }

                return ret;
              } else {
                return value;
              }
            }
          },
          data: xAxisArr
        },
        yAxis: {
          name: yAxisName,
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#c3d2dd",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,124,226,0.8)",
              type: "dotted"
            }
          }
        },
        series: seriesData
      });
    },
    // 清除从地图中选中的设备数据
    clearArr() {
      this.chooseFromMapArr = [];
      this.chooseFromMapIdArr = [];
    },
    processAllChecked(value, param) {
      // 表盘图
      const createAndFetchData = (type, prefix, refName, fetchMethod) => {
        const hasType = this.componentsQueue.some(component => {
          return component.type == type && component.deviceType == prefix;
        });
        if (!hasType) {
          this.addComponentNew(type, prefix);
        }
        this.$nextTick(() => {
          this.$refs[refName][0][fetchMethod]();
        });
      };
      if (value.radio == "高温水源热泵机房" && value.params == "平均COP") {
        this.iszIndex = false;
        if (param === "") {
          // 判断componentsQueue中是否包含了gaugeCharts，如果没有，就新建，包含了就只刷新一遍数据
          createAndFetchData("gaugeCharts", "", "componentgaugeCharts", "getData3");
        } else {
          // 带了参数
          let prefix = `${param}COP`;
          if (param == "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayCOP", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayCOP", "getData1");
          }
        }
      }
      // 环形图
      //~~~~~~~~~~~~~~~~~~~~~~~~~~pieCharts2~~Yure~~余热回收系统&余热回收效率~~~~~~~~~~~~~~~~~~~1~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "余热回收系统" && value.params == "余热回收效率") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("pieCharts2", "Yure", "componentpieCharts2Yure", "getData5");
        } else {
          // 带了参数
          let prefix = `${param}Yure`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayYure", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图

            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayYure", "getData1");
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~pieCharts2~~Kongqi~~空气源热泵&绿电占比~~~~~~~~~~~~~~~~~~~~~2~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "空气源热泵" && value.params == "绿电占比") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("pieCharts2", "Kongqi", "componentpieCharts2Kongqi", "getData5");
        } else {
          // 带了参数
          let prefix = `${param}Kongqi`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayKongqi", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayKongqi", "getData1");
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~pieCharts2~~Gaowen~~高温水源热泵机房&绿电占比~~~~~~~~~~~~~~3~~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "高温水源热泵机房" && value.params == "绿电占比") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("pieCharts2", "Gaowen", "componentpieCharts2Gaowen", "getData5");
        } else {
          // 带了参数
          let prefix = `${param}Gaowen`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayGaowen", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayGaowen", "getData1");
          }
        }
      }

      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~RZhengqiL~~燃气锅炉&蒸汽量~~~~~~~~4~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "燃气锅炉" && value.params == "蒸汽量") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("barCharts2", "RZhengqiL", "componentbarCharts2RZhengqiL", "getData4");
        } else {
          // 带了参数
          let prefix = `${param}RZhengqiL`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayRZhengqiL", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayRZhengqiL", "getData1");
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~ZZhengqiRL~~蒸锅&蒸汽热量~~~~~~~~~~~5~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "蒸锅" && value.params == "蒸汽热量") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("barCharts2", "ZZhengqiRL", "componentbarCharts2ZZhengqiRL", "getData4");
        } else {
          // 带了参数
          let prefix = `${param}ZZhengqiRL`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayZZhengqiRL", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayZZhengqiRL", "getData1");
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~YHuishou~~余热回收系统&回收余热量~~~~~~~~~~6~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "余热回收系统" && value.params == "回收余热量") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("barCharts2", "YHuishou", "componentbarCharts2YHuishou", "getData4");
        } else {
          // 带了参数
          let prefix = `${param}YHuishou`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayYHuishou", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayYHuishou", "getData1");
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~GZhire~~高温水源热泵机房&制热量~~~~~~~~~~~~7~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "高温水源热泵机房" && value.params == "制热量") {
        if (param === "") {
          createAndFetchData("barCharts2", "GZhire", "componentbarCharts2GZhire", "getData4");
        } else {
          // 带了参数
          let prefix = `${param}GZhire`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayGZhire", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayGZhire", "getData1");
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~GHaodian~~高温水源热泵机房&耗电量~~~~~~~~~~8~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "高温水源热泵机房" && value.params == "耗电量") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("barCharts2", "GHaodian", "componentbarCharts2GHaodian", "getData4");
        } else {
          // 带了参数
          let prefix = `${param}GHaodian`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayGHaodian", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayGHaodian", "getData1");
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~KZhire~~空气源热泵&制热量~~~~~~~~~~~~~9~~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "空气源热泵" && value.params == "制热量") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("barCharts2", "KZhire", "componentbarCharts2KZhire", "getData4");
        } else {
          // 带了参数
          let prefix = `${param}KZhire`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayKZhire", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayKZhire", "getData1");
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~KHaodian~~空气源热泵&耗电量~~~~~~~~~~~~~~~10~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "空气源热泵" && value.params == "耗电量") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("barCharts2", "KHaodian", "componentbarCharts2KHaodian", "getData4");
        } else {
          // 带了参数
          let prefix = `${param}KHaodian`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayKHaodian", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayKHaodian", "getData1");
            // createAndFetchData(
            //   "lineCharts",
            //   prefix,
            //   "componentlineChartslessOneDayKHaodian",
            //   "getData1"
            // );
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~barCharts2~~KAverageCOP~~空气源热泵&平均COP~~~~~~~~~~~~~~~11~~~~~~~~~~~~~~~~~~~~~~~~
      if (value.radio == "空气源热泵" && value.params == "平均COP") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("barCharts2", "KAverageCOP", "componentbarCharts2KAverageCOP", "getData4");
        } else {
          // 带了参数
          let prefix = `${param}KAverageCOP`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayKAverageCOP", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图
            createAndFetchData("lineChartsOther", prefix, "componentlineChartsOtherlessOneDayKAverageCOP", "getData1");
            // createAndFetchData(
            //   "lineCharts",
            //   prefix,
            //   "componentlineChartslessOneDayKAverageCOP",
            //   "getData1"
            // );
          }
        }
      }
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //~~~~~~~~~~~~~~~~~~~~~~~~~~lineChartsPv~~Fadianl~~光伏系统&绿电占比~~发电量~~~~~~~~~~12~~~~~~~~~~~~~~~~~
      if (value.radio == "光伏系统" && value.params == "发电量") {
        this.iszIndex = false;
        if (param === "") {
          createAndFetchData("lineChartsPv", "Fadianl", "componentlineChartsPvFadianl", "getDataPv");
        } else {
          // 带了参数
          let prefix = `${param}Fadianl`;
          if (param === "moreOneDay") {
            // 大于一天显示柱状图
            createAndFetchData("barCharts", prefix, "componentbarChartsmoreOneDayFadianl", "getData2");
          } else if (param == "lessOneDay") {
            // 小于一天折线图

            createAndFetchData("lineCharts", prefix, "componentlineChartslessOneDayFadianl", "getData1");
          }
        }
      }
    },
    // 搜索(搜索对比图 / 设备信息)
    getearchData(value) {
      console.log(value, "------------");
      this.searchData = value;
      this.$store.dispatch("asyncUpdateSearchData", value);

      // 搜索对比图表
      if (value.type == "search") {
        this.isChooseFromMapFlag = false;
        if (value.children.length == 0) {
          this.$message.error("请选择设备");
        } else if (value.params == "") {
          this.$message.error("请选择参数");
        } else {
          if (value.checkAll) {
            //全选
            //未选时间 - 实时数据
            if (value.chartTimeRange == null || value.chartTimeRange.length <= 0) {
              //执行数据处理展示部分
              //  this.processAllCheckedNoSelectTime(value);
              this.processAllChecked(value, "");
            } else {
              // 选了时间 - 历史数据
              // 选择日期大于一天
              if (value.chartTimeRange[1].getTime() - value.chartTimeRange[0].getTime() > 24 * 60 * 60 * 1000) {
                this.processAllChecked(value, "moreOneDay");
              } else {
                //选择日期小于1天
                this.processAllChecked(value, "lessOneDay");
              }
            }
          } else {
            // 非全选
            // this.isMapStatus = true
            /* 点击搜索前 清空上一次的线 */
            this.removeCompareMeshs();
            /* 点击搜索前 清空上一次的线 */
            /* 添加新的线和图表 */
            let deviceCodeArr = [];
            const that = this;
            this.gltf.scene.traverse(function (obj) {
              if (obj.name.indexOf("sb-") > -1) {
                obj.material.color.set(that.colorClone);
              }
              if (obj.name.includes("sb-gfb")) {
                obj.material.color.set(that.gfbOrinColor);
              }
              value.children.forEach(element => {
                if (obj.deviceName == element) {
                  deviceCodeArr.push(obj.name);
                  obj.material.color.set(0x00ffff);
                }
              });
              that.controls.addEventListener("change", function () {
                if (that.controls.getDistance() < 200) {
                  if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
                    obj.material.transparent = true;
                    obj.material.opacity = 0.1;
                  }
                } else {
                  if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
                    obj.material.transparent = true;
                    obj.material.opacity = 0.9;
                  }
                }
              });
              if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
                obj.material.transparent = true;
                obj.material.opacity = 0.1;
              }
            });
            this.compareCharts(deviceCodeArr, deviceCodeArr.length); //测试效果
          }
        }
      } else if (value.type == "timeline") {
        if (value.chartTimeRange == null || value.chartTimeRange.length <= 0) {
          this.$message.error("请选择起止时间！");
        } else if (value.children.length == 0) {
          this.$message.error("请选择子设备！");
        } else {
          if (!this.isMapStatus || this.isMapStatus == undefined || this.isMapStatus == "select") {
            let timelineArr = [];
            this.timelineArr.forEach(element => {
              timelineArr.push(element.childId);
            });

            // 构造时间轴接口参数
            let timelinestartTime = value.chartTimeRange[0].getTime().toString();
            let timelineendTime = value.chartTimeRange[1].getTime().toString();
            this.timelineParams.paramType = value.code;
            this.timelineParams.createdStartTime = timelinestartTime.substring(0, timelinestartTime.length - 3);
            this.timelineParams.createdEndTime = timelineendTime.substring(0, timelineendTime.length - 3);
            this.timelineParams.deviceId = timelineArr;
            this.timelineParams.pageNum = 1;
            this.timelineParams.pageSize = 100; //默认一次返回500个数据

            //测试用实际中传this.timelineParams
            // let timeParams = { pageNum: 1, pageSize: 100 };
            this.timeLineResultAll = {}; //再次发送请求前清空数据
            //timelineDataByPage(timeParams)
            timelineDataByPage(this.timelineParams).then(res => {
              if (res.data != "") {
                this.setOperationPanel(value); //初始化配置操作面板
                this.timeLineResultAll = res.data;
                this.timeLineResult = res.data.list;
                this.max = res.data.total;
                this.timeLineDeviceColor("init", this.timelineParams.paramType, 0);
                //触发后续请求
                //this.countdownTimer(this.timeLineResultAll);
                this.countdownByAsyncAwait(this.timeLineResultAll);
              } else {
                console.log("选中的设备数据为空，请联系相关人员确定原因。");
              }
            }).catch();
          } else {
            this.$message.error("请选择设备进行对比");
          }
        }
      } else {
        this.removeCompareMeshs();
        // this.isMapStatus = false
        this.timeLineFlag = false;
        const that = this;
        this.gltf.scene.traverse(function (obj) {
          if (obj.name.indexOf("sb-") > -1) {
            obj.material.color.set(that.colorClone);
          }
          if (obj.name.includes("sb-gfb")) {
            obj.material.color.set(that.gfbOrinColor);
          }
        });
      }
    },
    setOperationPanel(value) {
      this.timelineArr = [];
      this.speed = "采数";
      this.isTimeline = true;
      this.timeLineFlag = true;
      this.timeline = 0;
      clearInterval(this.timer);
      this.isPlay = "el-icon-video-play";
      this.removeTag();
      let startTime = this.dateParse(value.chartTimeRange[0]);
      let endTime = this.dateParse(value.chartTimeRange[1]);
      this.startTime = startTime;
      this.startTimeOrigin = startTime;
      this.endTime = endTime;
      const that = this;
      this.gltf.scene.traverse(function (obj) {
        if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
          obj.material.transparent = true;
          obj.material.opacity = 0.1;
        }
        if (obj.name.indexOf("sb-") > -1) {
          obj.material.color.set(that.colorClone);
        }
        if (obj.name.includes("sb-gfb")) {
          obj.material.color.set(that.gfbOrinColor);
        }
        that.controls.addEventListener("change", function () {
          if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
            obj.material.transparent = true;
            obj.material.opacity = 0.1;
          }
        });
        value.children.forEach(element => {
          if (element == obj.deviceName) {
            that.timelineArr.push(obj);
          }
        });
      });
    },
    //取一个数组中长度最大的长度值
    getMaxLength(data) {
      console.log(data, "data");
      let maxLength = 0;
      for (let i in data) {
        if (maxLength < data[i].length) {
          maxLength = data[i].length;
          console.log(maxLength, "MAXlength");
        }
      }
      return maxLength;
    },
    // 日期格式转换
    dateParse(date) {
      var dateString = date;
      var dateObj = new Date(dateString);
      var year = dateObj.getFullYear();
      var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      var day = ("0" + dateObj.getDate()).slice(-2);
      var hours = ("0" + dateObj.getHours()).slice(-2);
      var minutes = ("0" + dateObj.getMinutes()).slice(-2);
      var seconds = ("0" + dateObj.getSeconds()).slice(-2);
      var formattedDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
      return formattedDate;
    },
    // 对比图表 + 线
    compareCharts(arr, equipmentNum) {
      console.log("@@@@@compareCharts.arr", arr);
      var test = [];
      var len = arr.length;
      var ax = 0,
        ay = 0,
        az = 0;
      for (let i = 0; i < arr.length; i++) {
        var points = [];
        const objs = this.gltf.scene.getObjectByName(arr[i]);
        if (len > 6) {
          ax += objs.position.x / 600;
          ay += objs.position.y / 600;
          az += objs.position.z / 600;
        } else {
          ax += objs.position.x / len;
          ay += objs.position.y / len;
          az += objs.position.z / len;
        }
        points.push(new THREE.Vector3(objs.position.x, objs.position.y + 1, objs.position.z));
        test.push(points);
      }
      // 设置线段坐标点
      let position;
      if (len <= 1) {
        position = new THREE.Vector3(ax, ay * (len - 1) + 30, az);
      } else {
        position = new THREE.Vector3(ax, ay * (len - 1) + 30, az);
      }
      for (let j in test) {
        console.log(j, "test.j");
        console.log(position, "position");
        test[j].push(position);
        const lineGeometry = new THREE.BufferGeometry().setFromPoints(test[j]);
        const lineMaterial = new THREE.LineBasicMaterial({
          color: 0x2773e6
        });
        const line = new THREE.LineSegments(lineGeometry, lineMaterial);
        line.name = "compareObj";
        let pointGeometry = new THREE.BufferGeometry().setFromPoints(test[j]);
        const pointsMaterial = new THREE.PointsMaterial({
          color: 0x2773e6,
          size: 10.0,
          //点对象像素尺寸
          sizeAttenuation: true
        });
        // lineGeometry.translate(0, 10, 0);
        // pointGeometry.translate(0, 10, 0);
        const points = new THREE.Points(pointGeometry, pointsMaterial);
        points.name = "compareObj";
        this.scene.add(line);
        this.scene.add(points);
        console.log(points, "points", line, "line");
      }

      // 添加echarts对比图到css3dobject并设置位置及朝向
      let domEle = document.getElementById("compare-echarts");
      let domEleObj = new CSS2DObject(domEle);
      domEle.style.pointerEvents = "auto";
      domEle.style.zIndex = 0;
      domEle.style.visibility = "visible";
      this.$nextTick(() => {
        this.$refs.componentf.getData6();
      });
      domEleObj.scale.set(0.1, 0.1, 1);
      // len <= 1
      //   ? domEleObj.position.set(ax - 5, ay * len + 30, az)
      //   : domEleObj.position.set(ax - 5, ay * len + 50, az);
      len <= 1 ? domEleObj.position.set(ax, ay * (len - 1) + 30, az) : domEleObj.position.set(ax, ay * (len - 1) + 30, az);
      this.scene.add(domEleObj);
      const cameraPosition = this.camera.position.clone();
      domEleObj.lookAt(cameraPosition);
      domEle.firstChild.style.pointerEvents = "auto";
      const that = this;
      domEle.firstChild.addEventListener("click", event => {
        console.log("触发了click关闭事件");
        that.removeCompareMeshs();
        that.isMapStatus = false;
        that.clearArr();
        that.restoreMash(true);
      });
    },
    //恢复原有的透明度
    restoreMash(isClose = false) {
      console.log("jinru restoreMash");
      const that = this;
      that.gltf.scene.traverse(function (obj) {
        if (that.controls.getDistance() < 200) {
          if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
            obj.material.transparent = true;
            obj.material.opacity = 0.1;
          }
        } else {
          if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
            obj.material.transparent = true;
            obj.material.opacity = 0.9;
          }
        }
        if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
          obj.material.transparent = false;
          obj.material.opacity = 1;
        }
        if (isClose) {
          if (obj.name.indexOf("sb-") > -1) {
            obj.material.color.set(that.colorClone);
          }
          if (obj.name.includes("sb-gfb")) {
            obj.material.color.set(that.gfbOrinColor);
          }
        }
      });
    },
    // 清空对比图表 + 线
    removeCompareMeshs() {
      let domEle = document.getElementById("compare-echarts");
      const meshes = this.scene.children.filter(o => {
        return o.name == "compareObj";
      });
      meshes.forEach(l => {
        l.remove(...l.children);
      });
      this.scene.remove(...meshes);
      domEle.style.visibility = "hidden";
      // this.isMapStatus = false
    },

    // 时间轴设备随数据变化随之变色
    timeLineDeviceColor(type, paramType, timelineProcess) {
      let timelineProcessData = timelineProcess - 1 < 0 ? 0 : timelineProcess - 1;
      let key = null,
        min = 0,
        max = 0;
      const color1 = "#3f9d23"; //绿色
      const color2 = "#e01f1f"; //红色
      if (paramType == "gasBoiler") {
        key = "steamVolume";
        min = 0;
        max = 0.05;
      } else if (paramType == "steamer") {
        key = "stearmCaloric";
        min = 0;
        max = 80;
      } else if (paramType == "caloric_recup") {
        key = "residueCaloric";
        min = 0;
        max = 20;
      } else if (paramType == "waterPump") {
        key = "heatTempNum";
        min = 0;
        max = 20;
      } else if (paramType == "PV") {
        key = "powerGenerationNum";
        min = 0;
        max = 10;
      } else if (paramType == "airPump") {
        key = "heatTempNum";
        min = 0;
        max = 10;
      }
      if (type == "init") {
        const that = this;
        // console.log(this.timeLineResult, "this.timeLineResult");
        // console.log(this.timelineArr, "this.timelineArr");
        // console.log(key, "!!!!!!!!!!!!!!!!!!!!key");
        for (let i in this.timeLineResult) {
          //for in i为索引值
          that.timelineArr.forEach(element => {
            if (i == element.childId) {
              const initData = Number(that.timeLineResult[i][0][key]);
              const percent = (initData - min) / (max - min);
              const colorCode = that.generateGradientColor(color1, color2, percent);
              element.material.color.setStyle(colorCode);
            }
          });
        }
      } else {
        const that = this;
        // console.log(this.timeLineResult, "this.timeLineResult");
        // console.log(this.timelineArr, "this.timelineArr");
        let num = 0;
        for (let i in this.timeLineResult) {
          this.timelineArr.forEach(element => {
            if (i == element.childId) {
              if (timelineProcess > that.max) {
                that.timeline = 0;
                clearInterval(that.timer);
                timelineProcessData = that.max - 1;
                that.isPlay = "el-icon-video-play";
                that.isDisabled = false;
                const initData = Number(this.timeLineResult[i][0][key]);
                const percent = (initData - min) / (max - min);
                const colorCode = that.generateGradientColor(color1, color2, percent);
                element.material.color.setStyle(colorCode);
              }
              const initData = Number(this.timeLineResult[i][timelineProcessData][key]);
              const percent = (initData - min) / (max - min);
              // console.log(percent,"percent")
              const colorCode = that.generateGradientColor(color1, color2, percent);
              // console.log(colorCode,"colorCode")
              element.material.color.setStyle(colorCode);
            }
          });
        }
      }
    },
    // 点击播放按钮
    isPlayFun() {
      let that = this;
      this.isPlay === "el-icon-video-play" ? this.isPlay = "el-icon-video-pause" : this.isPlay = "el-icon-video-play";

      //用户单击了播放按钮
      if (this.isPlay === "el-icon-video-pause") {
        this.isDisabled = true;
        const childEquipmentDiv = document.querySelectorAll(".cloneDiv");
        let getTimelineParams = this.timelineParams.paramType;
        //这里要考虑如果数据没有回来，需要暂停等待
        that.timer = setInterval(() => {
          const resultkey = Object.keys(this.timeLineResult)[0];
          if (that.timeline >= this.timeLineResult[resultkey].length) {
            //timeLine为已播放的进度，当已播放的进度已经到达已返回数据的最大值时，暂停操作
            console.log("受网络环境影响，未接收到后续数据，将暂停等待，请继续等待或停止播放。");
            //这段未测试，没有测试环境，调接口时测一测
            this.$nextTick(() => {
              this.isPlayFun();
            });
            return;
          } else {
            console.log("that.timeline < this.timeLineResult[resultkey].length");
          }

          //判断当前进度是否超过已经取回数据的的数据，如果超了为false，不能继续累加，，没超过就正常走

          if (that.speed == "采数") {
            let timeLineEnable = that.timeline + 1 < this.timeLineResult[resultkey].length;
            if (timeLineEnable) {
              that.timeline += 1;
            } else {
              return;
            }
          } else if (that.speed == 10) {
            let timeLineEnable = that.timeline + 10 < this.timeLineResult[resultkey].length;
            if (timeLineEnable) {
              that.timeline += 10;
            } else {
              return;
            }
          } else if (that.speed == 30) {
            let timeLineEnable = that.timeline + 30 < this.timeLineResult[resultkey].length;
            if (timeLineEnable) {
              that.timeline += 30;
            } else {
              return;
            }
          } else if (that.speed == 60) {
            let timeLineEnable = that.timeline + 60 < this.timeLineResult[resultkey].length;
            if (timeLineEnable) {
              that.timeline += 60;
            } else {
              return;
            }
          }

          //手动改变时间后将startTime置位为初始位置
          this.startTime = this.computStartTimeMoment(this.startTimeOrigin, this.endTime, that.timeline, this.max);

          // 有设备信息弹出框时
          if (childEquipmentDiv.length != 0) {
            //设备信息随时间轴变化
            childEquipmentDiv.forEach((element, index) => {
              let nameElement = element.childNodes[1].firstChild.nextSibling.firstChild.nextSibling;
              for (let i in that.timeLineResult) {
                if (that.timeline > that.max) {
                  that.timeline = 0;
                  clearInterval(that.timer);
                  that.isPlay = "el-icon-video-play";
                  that.isDisabled = false;
                  that.startTime = that.dateParse(that.$store.getters.getSearchData.chartTimeRange[0]);
                  that.timeline = 0;
                }
                if (nameElement.innerHTML == that.timeLineResult[i][index].name) {
                  let a = 0;
                  if (that.timeline != 0) {
                    a = that.timeline - 1;
                    that.currentA = a;
                  } else {
                    a = 0;
                  }
                  let currentResult = that.timeLineResult[i][a];
                  let str = ``;
                  if (getTimelineParams == "gasBoiler") {
                    str = `
                    <div class="equipment-msg" style="height:auto;">
                      <p class="a-name">` + currentResult.name + `</p>
                      <p class="detail">燃气量` + currentResult.gasVolume + `m³/h</p>
                      <p class="detail">燃气锅炉蒸汽量` + currentResult.steamVolume + `t/h</p>
                      <p class="detail">燃气入口压力` + currentResult.inletPressure + `kPa</p>
                      <p class="detail">燃气锅炉蒸汽温度` + currentResult.steamTemp + `℃</p>
                      <p class="detail">燃气锅炉蒸汽压力` + currentResult.steamPressure + `Mpa</p>
                    </div>`;
                  }
                  if (getTimelineParams == "steamer") {
                    str = `
                    <div class="equipment-msg">
                      <p class="a-name">` + currentResult.name + `</p>
                      <p class="detail">蒸汽热量` + currentResult.stearmCaloric + `kW</p>
                    </div>`;
                  }
                  if (getTimelineParams == "caloric_recup") {
                    str = `
                    <div class="equipment-msg" style="height:auto;">
                      <p class="a-name">` + currentResult.name + `</p>
                      <p class="detail">余热量` + currentResult.residueCaloric + `kW</p>
                      <p class="detail">余热回收率` + currentResult.residueCaloricRate + `%</p>
                      <p class="detail">进水温度` + currentResult.waterTempIn + `℃</p>
                      <p class="detail">出水温度` + currentResult.waterTempOut + `℃</p>
                      <p class="detail">水流量` + currentResult.waterFlowNum + `t/h</p>
                    </div>`;
                  }
                  if (getTimelineParams == "waterPump") {
                    str = `
                    <div class="equipment-msg" style="height:auto;">
                      <p class="a-name">` + currentResult.name + `</p>
                      <p class="detail">高温热泵制蒸汽量` + currentResult.steamVolume + `t/h</p>
                      <p class="detail">COP` + currentResult.waterPumpCOP + `%</p>
                      <p class="detail">耗电量` + currentResult.powerConsumption + `kWh</p>
                      <p class="detail">绿电占比` + currentResult.greenPowerRate + `%</p>
                      <p class="detail">消纳绿电` + currentResult.consumeGreenPower + `kWh</p>
                      <p class="detail">热泵制热量` + currentResult.heatTempNum + `kW</p>
                      <p class="detail">热泵冷凝器水流量` + currentResult.condWaterNum + `t/h</p>
                      <p class="detail">热泵冷凝器进水温度` + currentResult.condWaterTempIn + `℃</p>
                      <p class="detail">热泵冷凝器出水温度` + currentResult.condWaterTempOut + `℃</p>
                      <p class="detail">热泵蒸发器进水温度` + currentResult.evapWaterTempIn + `℃</p>
                      <p class="detail">热泵蒸发器出水温度` + currentResult.evapWaterTempOut + `℃</p>
                    </div>`;
                  }
                  if (getTimelineParams == "PV") {
                    str = `
                    <div class="equipment-msg" style="height:auto;">
                        <p class="a-name">` + currentResult.name + `</p>
                        <p class="detail">光伏发电量` + currentResult.powerGenerationNum + `kWh</p>
                        <p class="detail">光伏发电效率` + currentResult.powerGenerationRate + `m³/h</p>
                        <p class="detail">消纳电量` + currentResult.consumePowerNum + `kWh</p>
                        <p class="detail">上网电量` + currentResult.internetPowerNum + `kWh</p>
                        <p class="detail">光照强度` + currentResult.lightIntensity + `</p>
                        <p class="detail">光伏背板温度` + currentResult.backboardTemp + `℃</p>
                      </div>`;
                  }
                  if (getTimelineParams == "airPump") {
                    str = `
                    <div class="equipment-msg" style="height:auto;">
                      <p class="a-name">` + object.deviceName + `</p>
                      <p class="detail">供热量` + currentResult.heatTempNum + `kW</p>
                      <p class="detail">COP` + currentResult.airPumpCOP + `%</p>
                      <p class="detail">耗电量` + currentResult.powerConsumption + `kWh</p>
                      <p class="detail">绿电占比` + currentResult.greenPowerRate + `%</p>
                      <p class="detail">消纳绿电` + currentResult.consumeGreenPower + `kWh</p>
                      <p class="detail">水流量` + currentResult.waterFlowNum + `t/h</p>
                      <p class="detail">空气源热泵供水温度` + currentResult.airPumpWaterTempIn + `℃</p>
                      <p class="detail">空气源热泵回水温度` + currentResult.airPumpWaterTempOut + `℃</p>
                    </div>`;
                  }
                  that.$nextTick(() => {
                    element.childNodes[1].innerHTML = "";
                    element.childNodes[1].innerHTML = str; //标签数据填写
                    element.style.height = "auto";
                    element.lastChild.style.display = "none";
                  });
                }
              }
            });
          }
          if (that.timeline > that.max) {
            that.timeline = 0;
            clearInterval(that.timer);
            that.isPlay = "el-icon-video-play";
            that.isDisabled = false;
            that.startTime = that.dateParse(that.$store.getters.getSearchData.chartTimeRange[0]);
            that.timeline = 0;
          }
          // 设备颜色随时间轴变化
          console.log(this.timeLine, "~~~~~~~~~~~~~~~~~~this.timeLine");
          that.timeLineDeviceColor("play", getTimelineParams, that.timeline);
        }, 1000);
      } else {
        //用户单击了暂停按钮
        this.isDisabled = false;
        clearInterval(that.timer);
      }
    },
    //通过异步的方式，根据page页数，执行多少次的循环请求，从第2页开始请求
    async countdownByAsyncAwait(timeLineResultAll) {
      console.log("timeLineResultAll@@", timeLineResultAll);
      let pageCout = timeLineResultAll.pageNum + 1;
      let counter = timeLineResultAll.pages - 1;
      for (let i = 0; i < counter; i++) {
        try {
          console.log(i); // 打印当前计数
          //请求接口
          this.timelineParams.pageNum = pageCout;
          this.timelineParams.pageSize = 100;
          //let timeParams = { pageNum: pageCout, pageSize: 500 };
          //console.log(timeParams, "timeParams");
          //const res = await timelineDataByPage(timeParams); // 等待异步数据加载
          const res = await timelineDataByPage(this.timelineParams, false); // 等待异步数据加载
          if (res.data != "") {
            const combinedData = {};
            Object.keys(this.timeLineResult).forEach(key => {
              if (res.data.list[key]) {
                combinedData[key] = this.timeLineResult[key].concat(res.data.list[key]);
              } else {
                combinedData[key] = this.timeLineResult[key];
              }
            });
            this.timeLineResult = combinedData; // 更新 Vue 组件的数据
            console.log(this.timeLineResult, "this.timeLineResult");
          }
          pageCout += 1;
        } catch (error) {
          console.error("Error fetching data:", error);
          break; // 如果发生错误，退出循环
        }
      }

      console.log("All operations completed.");
    },
    //根据page页数，执行多少次的循环请求，从第2页开始请求，计时器方式已弃用
    countdownTimer(timeLineResultAll) {
      console.log("timeLineResultAll", timeLineResultAll);
      let pageCout = timeLineResultAll.pageNum + 1;
      let counter = timeLineResultAll.page - 1;
      const intervalId = setInterval(() => {
        console.log(counter); // 打印当前计数
        counter--; // 减少计数
        //因为第一页的数据已经获取过了不需要重复获取，因此这里从第二页开始获取数据

        //请求接口
        this.timelineParams.pageNum = pageCout;
        this.timelineParams.pageSize = 100;
        let timeParams = {
          pageNum: pageCout,
          pageSize: 500
        };
        console.log(timeParams, "timeParams");
        timelineDataByPage(timeParams).then(res => {
          console.log(res.data, "res.data");
          if (res.data != "") {
            const combinedData = {};
            Object.keys(this.timeLineResult).forEach(key => {
              if (res.data.list[key]) {
                combinedData[key] = this.timeLineResult[key].concat(res.data.list[key]);
              } else {
                combinedData[key] = this.timeLineResult[key];
              }
            });
            console.log(combinedData, "@@@@@@combinedData");
            this.timeLineResult = combinedData;
          } else {
            console.log("...");
          }
        });
        if (counter <= 0) {
          clearInterval(intervalId); // 当计数结束时停止定时器
          console.log(" Countdown finished.");
        }
        pageCout += 1;
      }, 1000); // 设置每次间隔为1秒
    },

    /**
     * originTime初始时间
     * timeLine相加的时间
     */
    computStartTimeMoment(originTime, endTime, timeLine, maxLine) {
      // const startTimeMoment = moment(originTime, "YYYY-MM-DD HH:mm:ss"); // 解析初始时间
      // const newStartTimeMoment = startTimeMoment.add(timeLine, "seconds"); // 增加指定秒数
      // //手动改变时间后将startTime置位为初始位置
      // let computedTime = newStartTimeMoment.format("YYYY-MM-DD HH:mm:ss"); // 格式化新的时间并返回
      // return computedTime;

      // 将 startTime 和 endTime 解析为 Moment.js 对象
      const startTimeMoment = moment(originTime, "YYYY-MM-DD HH:mm:ss");
      const endTimeMoment = moment(endTime, "YYYY-MM-DD HH:mm:ss");

      // 计算时间范围内的总秒数
      const totalSeconds = endTimeMoment.diff(startTimeMoment, "seconds");

      // 计算每个时间间隔的秒数
      const intervalSeconds = totalSeconds / maxLine;

      // 计算当前时间点所处的秒数
      const currentSeconds = intervalSeconds * timeLine;
      // 使用 startTimeMoment 加上当前秒数得到当前时间的 Moment.js 对象
      const showTimeMoment = startTimeMoment.add(currentSeconds, "seconds");

      // 格式化当前时间为 HH:mm:ss 格式
      const showTime = showTimeMoment.format("YYYY-MM-DD HH:mm:ss");
      return showTime;
    },
    //进度条显示当前进度
    computedShowCurTimeMoment(originTime, endTime, curLine, maxLine) {
      const startTimeMoment = moment(originTime, "YYYY-MM-DD HH:mm:ss");
      const endTimeMoment = moment(endTime, "YYYY-MM-DD HH:mm:ss");

      // 计算时间范围内的总秒数
      const totalSeconds = endTimeMoment.diff(startTimeMoment, "seconds");

      // 计算每个时间间隔的秒数
      const intervalSeconds = totalSeconds / maxLine;

      // 计算当前时间点所处的秒数
      const currentSeconds = intervalSeconds * curLine;
      // 使用 startTimeMoment 加上当前秒数得到当前时间的 Moment.js 对象
      const showTimeMoment = startTimeMoment.add(currentSeconds, "seconds");

      // 格式化当前时间为 HH:mm:ss 格式
      const showTime = showTimeMoment.format("MM-DD HH:mm:ss");
      return showTime;
    },
    //    // 时间轴手动变化时触发(源代码备份)
    // sliderChange() {
    //   console.log(this.timeline, "sliderChange");
    // },
    // 时间轴手动变化时触发
    sliderChange(value) {
      console.log(value, "value3333");
      this.timeline = value;
      this.startTime = this.computStartTimeMoment(this.startTimeOrigin, this.endTime, value, this.max);
    },
    // 两侧图表关闭时触发
    closeCharts(name) {
      if (name == "value1") {
        this.switchList.value1 = false;
      } else if (name == "value2") {
        this.switchList.value2 = false;
      } else if (name == "value3") {
        this.switchList.value3 = false;
      } else if (name == "value4") {
        this.switchList.value4 = false;
      } else if (name == "value5") {
        this.switchList.value5 = false;
      } else if (name == "value6") {
        this.switchList.value6 = false;
      } else if (name == "value7") {
        this.switchList.value7 = false;
      }
      let arr = [];
      for (let i in this.switchList) {
        arr.push(this.switchList[i] ? "1" : "0");
      }
      if (arr.includes("1") == false) {
        this.iszIndex = true;
      }
    },
    // 关闭timeline
    closeTimeLine() {
      this.timeLineFlag = false;
      this.isTimeline = false;
      this.removeTag();
      this.timelineArr = [];
      this.speed = "采数";
      this.isPlay = "el-icon-video-play";
      this.isDisabled = false;
      const that = this;
      this.gltf.scene.traverse(function (obj) {
        that.controls.addEventListener("change", function () {
          if (that.controls.getDistance() < 200) {
            if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
              obj.material.transparent = true;
              obj.material.opacity = 0.1;
            }
          } else {
            if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
              obj.material.transparent = true;
              obj.material.opacity = 0.9;
            }
          }
        });
        if (obj.name.indexOf("jz-01") > -1 || obj.name.indexOf("jz-02") > -1 || obj.name.indexOf("jz-03") > -1 || obj.name.indexOf("jz-04") > -1 || obj.name.indexOf("jz-05") > -1) {
          obj.material.transparent = false;
          obj.material.opacity = 1;
        }
        if (obj.name.indexOf("sb-") > -1) {
          obj.material.color.set(that.colorClone);
        }
      });
      clearInterval(this.timer);
    },
    // 时间轴 选择采数时触发
    speedChoose(speed) {
      // console.log(speed)
      this.speed = speed;
      this.visible = false; // 隐藏弹出框
    },

    // 颜色根据比例不同返回不同的颜色色值
    generateGradientColor(color1, color2, percentage) {
      // 将颜色转换为 RGB 格式
      function hexToRgb(hex) {
        const bigint = parseInt(hex.replace("#", ""), 16);
        const r = bigint >> 16 & 255;
        const g = bigint >> 8 & 255;
        const b = bigint & 255;
        return {
          r,
          g,
          b
        };
      }

      // 将 RGB 值转换为十六进制格式
      function rgbToHex(rgb) {
        const {
          r,
          g,
          b
        } = rgb;
        return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
      }
      const color1Rgb = hexToRgb(color1);
      const color2Rgb = hexToRgb(color2);

      // 计算中间颜色的 RGB 值
      const r = Math.round(color1Rgb.r + (color2Rgb.r - color1Rgb.r) * percentage);
      const g = Math.round(color1Rgb.g + (color2Rgb.g - color1Rgb.g) * percentage);
      const b = Math.round(color1Rgb.b + (color2Rgb.b - color1Rgb.b) * percentage);

      // 将 RGB 值转换为十六进制格式
      const gradientColor = rgbToHex({
        r,
        g,
        b
      });
      return gradientColor;
    },
    moveObjectBetweenCoordinates(coordinateSystem1, coordinateSystem2, speed, object, p = null) {
      const distance = object.position.distanceTo(coordinateSystem2);
      const time = distance / speed;
      const tween = new TWEEN.Tween(coordinateSystem1).to(coordinateSystem2, time).easing(TWEEN.Easing.Linear.None).onUpdate(e => {
        object.position.set(e.x, e.y, e.z);
        const c1 = new THREE.Color("#ff0000");
        const c2 = new THREE.Color(0x00ffff);
        const timeDifference = Date.parse(new Date()) - object.userData.startTime;
        const percent = timeDifference / 27000; //点索引值相对所有点数量的百分比
        //根据顶点位置顺序大小设置颜色渐变
        const c = c1.clone().lerp(c2, percent); //颜色插值计算
        const material = new THREE.MeshBasicMaterial({
          color: c
        });
        object.material = material;
      }).start();
      tween.onComplete(() => {
        if (p) {
          this.createObjM(p.obj, object, p.path, p.indexD + 1);
        }
      });
      this.tweens.push(tween);
    },
    start() {
      this.objectMotion(this.pathConvergeData, "sb-gd-01");
      this.objectMotion(this.pathDisperseData, "sb-gd-022");
    },
    createObjM(obj, sphere, path, indexD) {
      if (path[indexD]) {
        this.moveObjectBetweenCoordinates({
          x: path[indexD - 1][0],
          y: path[indexD - 1][1],
          z: path[indexD - 1][2]
        }, {
          x: path[indexD][0],
          y: path[indexD][1],
          z: path[indexD][2]
        }, 0.01, sphere, {
          obj,
          path,
          indexD
        });
      } else {
        // console.log('删除')
        // this.scene.remove(sphere)
      }
    },
    objectMotion(pathData, name) {
      this.scene.traverse(el => {
        if (el.name == name) {
          el.material.opacity = 0.3;
          const timer = setInterval(() => {
            // // 每隔5次循环移除一次之前创建的球体
            el.children.forEach(child => {
              child.userData.timeCount += 1;
              if (child.userData.isSphere && child.userData.timeCount >= 15) {
                // console.log(
                //   child.userData.timeCount,
                //   "child.userData.timeCount"
                // );

                el.remove(child);
              }
            });
            for (let key in pathData) {
              const material = new THREE.MeshBasicMaterial({
                color: new THREE.Color("#ff0000")
              });
              const sphere = new THREE.Mesh(this.sphereGeometry, material);
              sphere.userData.startTime = Date.parse(new Date());
              sphere.userData.isSphere = true;
              sphere.position.set(...pathData[key][0]);
              sphere.userData.timeCount = 0;
              el.add(sphere);
              this.createObjM(el, sphere, pathData[key], 1);
            }
          }, 3000);
          this.timers.push(timer);
        }
      });
    },
    stopObjMove() {
      this.tweens.forEach(el => el.stop());
      this.timers.forEach(el => clearInterval(el));
      this.scene.traverse(obj => {
        if (obj.name == "sb-gd-01" || obj.name == "sb-gd-022") {
          obj.children = [];
          obj.material.opacity = 1;
        }
      });
    },
    footerClickSimulateC() {
      console.log("home收到单击消息");
      this.isShowSimulateC = true;
    },
    footerClickAlarmInfo(isHiddenAlarm) {
      console.log("home收到单击报警消息");
      // this.isShowAlarmInfo = true;

      //用户单击了报警按钮，隐藏右上角报警窗口
      this.isShowAlarmWin = !isHiddenAlarm;
    },
    //用户单击了导出数据按钮
    footerClickExportData() {
      console.log("收到导出数据按钮消息");
      //获取到msg的数据信息
      // let msgObj = this.$refs.msg.exportToPdf();

      // console.log(msgObj, "this.$refs.msg.msgObj");
      // let columns = [],
      //   rows = [];
      // //合并数组
      // // columns = [...msgObj.columns,...colums2];
      // columns = [...msgObj.columns];
      // rows = [...msgObj.rows];
      //由前端生成pdf更改为由后端生成，访问接口即可触发下载
      //exportPdf(columns, rows);

      let url = `${process.env.VUE_APP_PUB_URL}/export/day`;
      this.downloadFile(url);
    },
    downloadFile(url) {
      // 创建一个隐藏的链接元素
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", ""); // 设置 download 属性

      // 将链接添加到文档中
      document.body.appendChild(link);

      // 触发点击事件
      link.click();

      // 从文档中移除链接
      document.body.removeChild(link);
    },
    OnclickcloseSimulateControl() {
      console.log("收到关闭消息");
      this.isShowSimulateC = false;
    },
    OnClinkCloseAlarmInfo() {
      this.isShowAlarmInfo = false;
    },
    addComponentNew(componentType, deviceType) {
      this.componentsQueue.push({
        type: componentType,
        deviceType: deviceType,
        ComponentCounter: this.componentCounter
      }); //往尾部插入一个
      this.$nextTick(() => {
        if (this.componentsQueue.length >= 7) {
          //大于等于7的时候再触发刷新
          this.updateDataByIndexNew(2);
        }
      });
      if (this.componentsQueue.length >= 8) {
        this.componentsQueue.shift(); // 删除第一个元素
      }
    },

    addComponent(componentType, deviceType) {
      if (this.componentsQueue.length >= 7) {
        let newComponent = {
          type: componentType,
          deviceType: deviceType,
          ComponentCounter: this.componentCounter
        };

        //this.componentCounter累加一共点击过的组件数量，超过7个以后，取余确定替换的索引值
        this.componentCounter += 1;
        this.componentsQueue.splice((this.componentCounter - 1) % 7, 1, newComponent);
      } else {
        this.componentCounter += 1;
        this.componentsQueue.push({
          type: componentType,
          deviceType: deviceType,
          ComponentCounter: this.componentCounter
        }); //往尾部插入一个
      }

      if (this.componentCounter >= 14) {
        this.componentCounter = 0;
      }
    },
    iterateRefsNew() {
      const carryOutData = (refName, fetchMethod, deviceType) => {
        this.$nextTick(() => {
          console.log(refName, "refName INFO");
          this.$refs[refName][0][fetchMethod](deviceType);
        });
      };
      const handleDeviceType = (cache, type, chartRef, barChartRef, lineChartRef) => {
        if (cache.deviceType == type) {
          if (cache.deviceType == "Fadianl") {
            this.$refs[chartRef][0].getDataPvInf(cache.deviceType);
          } else {
            this.$refs[chartRef][0].getDataInfo(cache.deviceType);
          }
        } else if (cache.deviceType.includes("moreOneDay")) {
          carryOutData(barChartRef, "getDataInfo", `moreOneday${type}`);
        } else if (cache.deviceType.includes("lessOneDay")) {
          carryOutData(lineChartRef, "getDataInfo", `lessOneday${type}`);
        }
      };
      Object.keys(this.$refs).forEach(refKey => {
        const ref = this.$refs[refKey];
        if (Array.isArray(ref)) {
          ref.forEach(cache => {
            try {
              console.log(cache.type, cache.deviceType, "iteraternew s cache");
              //***************这段是根据高温水源热泵机房**平均COP的三种情况1：没选时间2：大于两天的时间，3：小于一天的时间**************** */
              if (cache.deviceType == "") {
                //组件gaugeCharts不选时间时cache.deviceType == ""
                this.$refs.componentgaugeCharts[0].getDataInfo("gaugeCharts");
              } else if ((cache.type == "barCharts" || cache.type === "lineCharts") && cache.deviceType.includes("COP") && !cache.deviceType.includes("KAverageCOP")) {
                //选了时间的图表只有barCharts或者lineCharts两种方式
                //选了大于一天的时间高温水源热泵机房COP
                if (cache.deviceType.includes("moreOneDay")) {
                  let sessionName = `moreOnedaygaugeCharts`;
                  carryOutData("componentbarChartsmoreOneDayCOP", "getDataInfo", sessionName);
                } else if (cache.deviceType.includes("lessOneDay")) {
                  //选了小于一天的时间
                  let sessionName = `lessOnedaygaugeCharts`;
                  carryOutData("componentlineChartsOtherlessOneDayCOP", "getDataInfo", sessionName);
                }
              } else if (cache.deviceType.includes("Yure")) {
                /**************************************余热回收系统*余热回收效率******************************* */
                handleDeviceType(cache, "Yure", "componentpieCharts2Yure", "componentbarChartsmoreOneDayYure", "componentlineChartsOtherlessOneDayYure");
              } else if (cache.deviceType.includes("Gaowen")) {
                /***********************************高温水源热泵机房 绿电占比***************************************/
                handleDeviceType(cache, "Gaowen", "componentpieCharts2Gaowen", "componentbarChartsmoreOneDayGaowen", "componentlineChartsOtherlessOneDayGaowen");
              } else if (cache.deviceType.includes("Kongqi")) {
                /***************************************空气源热泵**绿电占比**************************************** */
                handleDeviceType(cache, "Kongqi", "componentpieCharts2Kongqi", "componentbarChartsmoreOneDayKongqi", "componentlineChartsOtherlessOneDayKongqi");
              } else if (cache.deviceType.includes("RZhengqiL")) {
                /***********************************燃气锅炉 蒸汽量*****************************************/
                handleDeviceType(cache, "RZhengqiL", "componentbarCharts2RZhengqiL", "componentbarChartsmoreOneDayRZhengqiL", "componentlineChartsOtherlessOneDayRZhengqiL");
              } else if (cache.deviceType.includes("ZZhengqiRL")) {
                /*********************************蒸锅*蒸汽热量*****************************************/
                handleDeviceType(cache, "ZZhengqiRL", "componentbarCharts2ZZhengqiRL", "componentbarChartsmoreOneDayZZhengqiRL", "componentlineChartsOtherlessOneDayZZhengqiRL");
              } else if (cache.deviceType.includes("YHuishou")) {
                /*********************************余热回收系统*回收余热量*****************************************/
                handleDeviceType(cache, "YHuishou", "componentbarCharts2YHuishou", "componentbarChartsmoreOneDayYHuishou", "componentlineChartsOtherlessOneDayYHuishou");
              } else if (cache.deviceType.includes("GZhire")) {
                /*********************************高温水源热泵机房*制热量*****************************************/
                handleDeviceType(cache, "GZhire", "componentbarCharts2GZhire", "componentbarChartsmoreOneDayGZhire", "componentlineChartsOtherlessOneDayGZhire");
              } else if (cache.deviceType.includes("GHaodian")) {
                /*********************************高温水源热泵机房*耗电量*****************************************/
                handleDeviceType(cache, "GHaodian", "componentbarCharts2GHaodian", "componentbarChartsmoreOneDayGHaodian", "componentlineChartsOtherlessOneDayGHaodian");
              } else if (cache.deviceType.includes("KZhire")) {
                /*********************************空气源热泵*制热量*****************************************/
                handleDeviceType(cache, "KZhire", "componentbarCharts2KZhire", "componentbarChartsmoreOneDayKZhire", "componentlineChartsOtherlessOneDayKZhire");
              } else if (cache.deviceType.includes("KHaodian")) {
                /*********************************空气源热泵*耗电量*****************************************/
                handleDeviceType(cache, "KHaodian", "componentbarCharts2KHaodian", "componentbarChartsmoreOneDayKHaodian", "componentlineChartsOtherlessOneDayKHaodian");
              } else if (cache.deviceType.includes("KAverageCOP")) {
                /*********************************空气源热泵*平均COP*****************************************/

                handleDeviceType(cache, "KAverageCOP", "componentbarCharts2KAverageCOP", "componentbarChartsmoreOneDayKAverageCOP", "componentlineChartsOtherlessOneDayKAverageCOP");
              } else if (cache.deviceType.includes("Fadianl")) {
                /*********************************光伏系统*发电量*****************************************/
                handleDeviceType(cache, "Fadianl", "componentlineChartsPvFadianl", "componentbarChartsmoreOneDayFadianl", "componentlineChartslessOneDayFadianl");
              }
            } catch (error) {
              console.log("updateDataByIndex error", error);
            }
          });
        }
      });
    }
  },
  computed: {
    // ...mapState(['searchData']),
    updateDate() {
      const now = new Date();
      return now.getFullYear() + "年" + (now.getMonth() + 1) + "月" + now.getDate() + "日 ";
    },
    weekData() {
      return this.week[new Date().getDay()];
    },
    displayedComponents() {
      console.log(this.componentsQueue, "触发监视.componentsQueue.slice");
      return this.componentsQueue.slice(0, 7);
    },
    leftComponents() {
      //console.log(this.componentsQueue.slice(3, 7));
      return this.componentsQueue.slice(3, 7);
      // return this.displayedComponents.slice(3, 7);
    },

    rightComponents() {
      //console.log(this.componentsQueue.slice(0, 3));
      return this.componentsQueue.slice(0, 3);
      // return this.displayedComponents.slice(0, 3);
    },

    chunkComponents: function () {
      return {
        right: this.componentsQueue.slice(0, 3),
        left: this.componentsQueue.slice(3, 7)
      };
    },
    computedZIndex() {
      //配置z-index的值，保证不显示动态图表时不覆盖后面的模型，影响模型的选中
      return this.chunkComponents.left.length >= 1 ? 2 : -1;
    }
  },
  watch: {
    componentsQueue(newValue, oldValue) {
      console.log(newValue, oldValue, "new,old");
    }
  }
};