// 引入 axios
import Vue from "vue";
import axios from "axios";
import { Notification, MessageBox, Message, Loading } from "element-ui";

// 不同环境不同地址
const apis = {
  //production: "https://api.zhiboredian.net", // 线上 (生成环境)
  production: process.env.VUE_APP_API_URL,
  development: "/api", // 本地 (开发环境)
  //development: "https://api.zhiboredian.net",
  accessHomeData: "https://api.zhiboredian.net", // 其他api
};
// 封装 baseURL
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
const request = axios.create({
  //baseURL: "https://api.zhiboredian.net",
  //baseURL: process.env.VUE_APP_API_URL,
  baseURL:
    process.env.NODE_ENV === "production" ? apis.production : apis.development,
  // baseURL:"/static/json",
  timeout: 1000000,
  // headers:{
  //     'Access-Control-Allow-Origin':"*"
  // }
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    /* 添加loading S */
    if (config.config.showLoading === true) {
      showLoading();
    }
    /* 添加loading E */

    return config;
  },
  function (error) {
    // 对请求错误做些什么

    /* 关闭loading S */
    hideLoading();
    /* 关闭loading E */

    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么

    /* 关闭loading S */
    hideLoading();
    /* 关闭loading E */

    return response;
  },
  function (error) {
    // 对响应错误做点什么

    /* 关闭loading S */
    hideLoading();
    /* 关闭loading E */

    // 请求超时处理
    if (error.message.indexOf("timeout") !== -1) {
      alert("请求超时，请重新刷新页面！");
    }

    return Promise.reject(error);
  }
);

// 加载函数Loading S
let loading;
let needLoadingRequestCount = 0;

function showLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
}

function hideLoading() {
  setTimeout(tryCloseLoading, 300);
}

function tryCloseLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}

function startLoading() {
  loading = Vue.prototype.$loading({
    lock: true,
    text: "加载中……",
    background: "rgba(0, 0, 0, .5)",
    target: document.querySelector(".loading-g"), // 设置加载动画区域
  });
}

function endLoading() {
  Vue.nextTick(function () {
    // DOM 更新了
    loading.close();
  });
}
// 加载函数Loading E

// 向外暴露 request
export default request;
