import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allEquipmentSearch } from "../../../api/charts";
export default {
  props: {
    deviceType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "",
      params: {},
      isButtonDisabled: false //初始状态不禁用
    };
  },

  mounted() {},
  computed: {
    getSearchData() {
      return this.$store.getters.getSearchData;
    }
  },
  methods: {
    removeComponent() {
      this.$emit("closeComm", "");
    },
    getDataInfo(sessionName) {
      console.log(sessionName, "sessionName");
      const getOldData = JSON.parse(sessionStorage.getItem(sessionName));
      console.log(getOldData, "getOldDatagetOldDatagetOldDatagetOldData");
      let paramsType = [],
        paramsArr = [];
      let key = getOldData.code;
      let paramsCode = getOldData.paramsCode;
      paramsType.push(getOldData.code);
      paramsArr.push(paramsCode);
      let startTime = new Date(getOldData.chartTimeRange[0]).getTime().toString();
      let endTime = new Date(getOldData.chartTimeRange[1]).getTime().toString();
      this.params.paramType = paramsType;
      this.params.createdStartTime = startTime.substring(0, startTime.length - 3);
      this.params.createdEndTime = endTime.substring(0, endTime.length - 3);
      this.params.deviceId = getOldData.childrenId;
      this.params.params = paramsArr;
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = true;
        allEquipmentSearch(this.params).then(res => {
          this.isButtonDisabled = false;
          console.log(res.data[key], "datassssssssss");
          console.log(getOldData.code, "getOldData.code ");
          if (getOldData.code == "PV") {
            if (res.data[key].length != 0 && res.data[key] != undefined) {
              const data = res.data[key][0].data;
              this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
              console.log(res.data[key], res.data[key]);
              /**修改坐标代码数据 */

              // 提取名称和数据
              if (res.data[key] && Array.isArray(res.data[key])) {
                const data = res.data[key];
                const names = data.map(item => item.name);
                const legendArr = data[0].data.map(item => item.createTime);
                const seriesData = legendArr.map(legItem => {
                  const datas = data.flatMap(item => {
                    // console.log(item, "item ----data.flatMap将数组展平后");
                    const dataItem = item.data.find(itemElement => itemElement.createTime === legItem);
                    return dataItem ? parseFloat(dataItem.powerGenerationNum) : null;
                  });
                  return {
                    name: legItem,
                    type: "line",
                    data: datas
                  };
                });
                let xAxisArr = names;
                const yAxisName = res.data.params[paramsCode] === "" ? getOldData.params : `${getOldData.params}(${res.data.params[paramsCode]})`;
                this.charts(xAxisArr, legendArr, "系统名", yAxisName, seriesData);
              } else {
                console.error("data is not properly initialized");
              }
            } else {
              this.$message.error("暂无数据");
            }
          } else {
            if (res.data[key].length != 0 && res.data[key] != undefined) {
              const data = res.data[key][0].data;
              this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
              let xAxisArr = [],
                seriesData = [],
                legendArr = [];
              res.data[key].forEach((element, index) => {
                let barData = [];
                element.data.forEach(item => {
                  barData.push(item[paramsCode]);
                });
                legendArr.push(element.name);
                seriesData.push({
                  name: element.name,
                  barWidth: 10,
                  type: "bar",
                  data: barData
                });
              });
              data.forEach(element => {
                xAxisArr.push(element.createTime);
              });
              let yAxisName = res.data.params[paramsCode] == "" ? getOldData.params : getOldData.params + "(" + res.data.params[paramsCode] + ")";
              this.charts(xAxisArr, legendArr, "天", yAxisName, seriesData);
            } else {
              this.$message.error("暂无数据");
            }
          }
        }).catch(() => {
          this.isButtonDisabled = false;
        });
      }
    },
    getData2() {
      let paramsType = [],
        paramsArr = [];
      let key = this.getSearchData.code;
      let paramsCode = this.getSearchData.paramsCode;
      paramsType.push(this.getSearchData.code);
      paramsArr.push(paramsCode);
      let startTime = this.getSearchData.chartTimeRange[0].getTime().toString();
      let endTime = this.getSearchData.chartTimeRange[1].getTime().toString();
      this.params.paramType = paramsType;
      this.params.createdStartTime = startTime.substring(0, startTime.length - 3);
      this.params.createdEndTime = endTime.substring(0, endTime.length - 3);
      this.params.deviceId = this.getSearchData.childrenId;
      this.params.params = paramsArr;
      allEquipmentSearch(this.params).then(res => {
        console.log(res.data[key], "datassssssssss");
        console.log(this.getSearchData.code, "this.getSearchData.code ");
        if (this.getSearchData.code == "PV") {
          if (res.data[key].length != 0 && res.data[key] != undefined) {
            const data = res.data[key][0].data;
            this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
            console.log(res.data[key], res.data[key]);
            /**修改坐标代码数据 */

            // 提取名称和数据
            if (res.data[key] && Array.isArray(res.data[key])) {
              const data = res.data[key];
              const names = data.map(item => item.name);
              const legendArr = data[0].data.map(item => item.createTime);
              const seriesData = legendArr.map(legItem => {
                const datas = data.flatMap(item => {
                  // console.log(item, "item ----data.flatMap将数组展平后");
                  const dataItem = item.data.find(itemElement => itemElement.createTime === legItem);
                  return dataItem ? parseFloat(dataItem.powerGenerationNum) : null;
                });
                return {
                  name: legItem,
                  type: "line",
                  data: datas
                };
              });
              let xAxisArr = names;
              const yAxisName = res.data.params[paramsCode] === "" ? this.getSearchData.params : `${this.getSearchData.params}(${res.data.params[paramsCode]})`;
              this.charts(xAxisArr, legendArr, "系统名", yAxisName, seriesData);
            } else {
              console.error("data is not properly initialized");
            }
          } else {
            this.$message.error("暂无数据");
          }
        } else {
          if (res.data[key].length != 0 && res.data[key] != undefined) {
            const data = res.data[key][0].data;
            this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
            let xAxisArr = [],
              seriesData = [],
              legendArr = [];
            res.data[key].forEach((element, index) => {
              let barData = [];
              element.data.forEach(item => {
                barData.push(item[paramsCode]);
              });
              legendArr.push(element.name);
              seriesData.push({
                name: element.name,
                barWidth: 10,
                type: "bar",
                data: barData
              });
            });
            data.forEach(element => {
              xAxisArr.push(element.createTime);
            });
            let yAxisName = res.data.params[paramsCode] == "" ? this.getSearchData.params : this.getSearchData.params + "(" + res.data.params[paramsCode] + ")";
            this.charts(xAxisArr, legendArr, "天", yAxisName, seriesData);
          } else {
            this.$message.error("暂无数据");
          }
        }
      }).catch();
    },
    charts(xAxisArr, legendArr, xAxisName, yAxisName, seriesData) {
      const chart = this.$echarts.init(this.$refs.chart2);
      chart.clear();
      if (this.getSearchData.code == "PV") {
        chart.setOption({
          tooltip: {
            trigger: "axis",
            confine: true,
            enterable: true,
            //允许鼠标进入提示框浮层中，默认为false
            extraCssText: "max-height:83%;overflow:auto" //出现滚动条
          },

          legend: {
            width: 350,
            type: "scroll",
            top: "0px",
            left: "center",
            textStyle: {
              color: "#eee"
            },
            pageTextStyle: {
              // 设置上一页、下一页文字样式
              color: "#eee"
            },
            pageIconColor: "#eee",
            pageIconInactiveColor: "#999",
            data: legendArr
          },
          grid: {
            // top: legendArr.length <= 3 ? "25%" : "30%",
            top: legendArr.length <= 3 ? "18%" : "23%",
            bottom: "17%",
            left: "12%",
            right: "10%"
          },
          color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
          xAxis: {
            name: xAxisName,
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#d8d8d8",
                width: 1
              }
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 12,
              padding: [8, 0, 0, -15],
              verticalAlign: "top"
            },
            data: xAxisArr
          },
          yAxis: {
            name: yAxisName,
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#c3d2dd",
                width: 1
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0,124,226,0.8)",
                type: "dotted"
              }
            }
          },
          series: seriesData
        });
      } else {
        chart.setOption({
          tooltip: {
            trigger: "axis"
          },
          grid: {
            //  top: legendArr.length <= 3 ? "25%" : "40%",
            top: legendArr.length <= 3 ? "18%" : "32%",
            bottom: "17%",
            left: "12%",
            right: "5%"
          },
          legend: {
            top: "0px",
            icon: "circle",
            itemHeight: 10,
            itemWidth: 10,
            textStyle: {
              color: []
            },
            data: legendArr
          },
          color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
          xAxis: [{
            name: xAxisName,
            type: "category",
            data: xAxisArr,
            axisPointer: {
              type: "shadow"
            },
            axisLine: {
              lineStyle: {
                color: "#d8d8d8",
                width: 1
              }
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 12,
              padding: [8, 0, 0, -20],
              verticalAlign: "top"
            }
          }],
          yAxis: [{
            type: "value",
            name: yAxisName,
            axisLine: {
              lineStyle: {
                color: "#c3d2dd",
                width: 1
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0,124,226,0.8)",
                type: "dotted"
              }
            }
          }],
          series: seriesData
        });
      }
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    }
  }
};