// 第一步引入插件(本质是一个构造函数)
import VueRouter from 'vue-router'

//导入路由需要的组件
import Home from '../views/Home.vue'
import Voice from '../views/Voice.vue'
// import Cube from '../views/Cube.vue'
// import News from '../views/News.vue'

// 第二步创建router实例对象并暴露
const router = new VueRouter({
  routes:[
    {path:'/',redirect:'/Home'},
    {
      name:"首页",
      path:'/Home',
      component:Home,
      meta:{
        title:"智博综合能源智慧管理平台"
      }
    },
    {
      name:"语音",
      path:'/Voice',
      component:Voice
    },
    // {
    //   name:"管道",
    //   path:'/Cube',
    //   component:Cube
    // },
    // 子路由****************************
    // {
    //   name:"首页",
    //   path:'/Home',
    //   component:Home,
      // children:[
      //   {
      //     path:'news',
      //     component:News,
      //   },
      //   {
      //     name:'message',
      //     path:'message',   
      //     component:Message,
      //     children:[
      //      path:detail/:id/:title,    传params参数   取参数：{{$route.params.id}}
      //      component:Detail,
      //      props:true       //只能收到params参数   接受：props:['id','title']
      //      props({query}){
      //         return {id:query.id,title:query.title}    接受：props:['id','title']
      //      }
      //     ]
      //   }
      // ]
      // <router-link to="/home/news"> 必须加父级路径
      // <router-link :to="{name:message}"> 简化路径
    // },

    
  ]
})
 // 在路由导航守卫中动态修改页面标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title; // 根据路由的元信息动态修改页面标题
  }
  next();
});
export default router