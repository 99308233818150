import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allEquipmentSearch } from "../../../api/charts";
export default {
  props: {
    deviceType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "高温水源热泵机房",
      value: "",
      params: {},
      timer: null,
      isButtonDisabled: false
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.timer = setInterval(() => {
    //     const getOldData = JSON.parse(sessionStorage.getItem("gaugeData"));
    //     let paramsType = [];
    //     let key = getOldData.code;
    //     let paramsCode = getOldData.paramsCode;
    //     paramsType.push(getOldData.code);
    //     this.params.paramType = paramsType;
    //     allEquipmentSearch(this.params)
    //       .then((res) => {
    //         // console.log(res,"表盘图dataInterval")
    //         if (res.data[key].length != 0 && res.data[key] != undefined) {
    //           const data = res.data.waterPump[0].waterPumpCOP;
    //           this.value = data;
    //           this.charts(data);
    //         } else {
    //           this.charts("");
    //         }
    //       })
    //       .catch();
    //     // console.log(this.params,"setintervalParams")
    //   }, 60000);
    // });
  },
  beforeDestroy() {
    clearInterval(this.timer); // 取消定时器
  },

  computed: {
    getSearchData() {
      return this.$store.getters.getSearchData;
    }
  },
  methods: {
    removeComponent() {
      this.$emit("closeComm", "");
    },
    getDataInfo(sessionName) {
      let name = `${sessionName}`;
      //console.log(name);
      const getOldData = JSON.parse(sessionStorage.getItem(name));
      console.log(getOldData, "getOldDatagetOldDatagetOldDatagetOldData");
      let paramsType = [];
      let key = getOldData.code;
      paramsType.push(getOldData.code);
      this.params.paramType = paramsType;
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = true; //获取到数据之前禁用
        allEquipmentSearch(this.params).then(res => {
          this.isButtonDisabled = false; //获取到数据之后可以继续操作
          if (res.data[key].length != 0 && res.data[key] != undefined) {
            const data = res.data.waterPump[0].waterPumpCOP;
            this.value = data;
            this.charts(data);
          } else {
            this.charts("");
          }
        }).catch(() => {
          this.isButtonDisabled = false; //获取到数据之后可以继续操作
        });
      }
    },

    getData3() {
      // console.log(this.getSearchData)
      let paramsType = [];
      let key = this.getSearchData.code;
      let paramsCode = this.getSearchData.paramsCode;
      paramsType.push(this.getSearchData.code);
      this.params.paramType = paramsType;
      // console.log(this.params,"Params")
      allEquipmentSearch(this.params).then(res => {
        // console.log(res,"表盘图返回值")
        if (res.data[key].length != 0 && res.data[key] != undefined) {
          const data = res.data.waterPump[0].waterPumpCOP;
          this.value = data;
          this.charts(data);
        } else {
          this.charts("");
          this.$message.error("暂无数据");
        }
      }).catch();
    },
    charts(data) {
      var axislineColor = new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0.1,
        color: "#E43F3D"
      }, {
        offset: 0.15,
        color: "#E98E2C"
      }, {
        offset: 0.2,
        color: "#DDBD4D"
      }, {
        offset: 0.8,
        color: "#7CBB55"
      }, {
        offset: 1,
        color: "#9CD6CE"
      }]);
      const chart = this.$echarts.init(this.$refs.gaugechart1);
      chart.clear();
      chart.setOption({
        series: [{
          name: "车辆总数",
          type: "gauge",
          z: 3,
          min: 0,
          max: 3,
          center: ["33%", "47%"],
          radius: window.innerHeight > 900 ? "75%" : "90%",
          splitNumber: 10,
          axisLine: {
            // 坐标轴线
            lineStyle: {
              // 属性lineStyle控制线条样式
              width: 15,
              color: [[1, axislineColor]]
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            distance: 0,
            textStyle: {
              color: "#fff",
              fontSize: 12
            }
          },
          splitLine: {
            // 分隔线
            show: false
          },
          detail: {
            show: false
          },
          pointer: {
            show: true,
            width: 3,
            length: "60%"
          },
          itemStyle: {
            normal: {
              color: "#367bad"
            }
          },
          data: [{
            value: data,
            name: ""
          }]
        }, {
          name: "",
          type: "gauge",
          z: 2,
          min: 0,
          max: 3,
          center: ["33%", "47%"],
          radius: window.innerHeight > 900 ? "58%" : "68%",
          splitNumber: 10,
          axisLine: {
            // 坐标轴线
            show: false,
            lineStyle: {
              // 属性lineStyle控制线条样式
              width: 0,
              color: [[1, axislineColor]]
            }
          },
          title: {
            show: false
          },
          detail: {
            show: false
          },
          axisTick: {
            show: true,
            length: 5,
            lineStyle: {
              width: 1,
              color: "#EBF3FE"
            }
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            // 分隔线
            show: false
          }
        }]
      });
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    }
  }
};