/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./simulateControl.vue?vue&type=template&id=48b3911c&scoped=true"
import script from "./simulateControl.vue?vue&type=script&lang=js"
export * from "./simulateControl.vue?vue&type=script&lang=js"
import style0 from "./simulateControl.vue?vue&type=style&index=0&id=48b3911c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b3911c",
  null
  
)

export default component.exports