import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allEquipmentSearch } from "../../../api/charts";
export default {
  props: {
    deviceType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "",
      params: {},
      xAxisArr: [],
      chartData: [],
      timer: null,
      isButtonDisabled: false
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.timer = setInterval(() => {
    //     const getOldData = JSON.parse(sessionStorage.getItem("lineData"));
    //     let paramsType = [],
    //       paramsArr = [];
    //     let key = getOldData.code;
    //     let paramsCode = getOldData.paramsCode;
    //     paramsType.push(getOldData.code);
    //     paramsArr.push(paramsCode);
    //     this.params.paramType = paramsType;
    //     this.params.createdStartTime = "";
    //     this.params.createdEndTime = "";
    //     this.params.deviceId = getOldData.childrenId;
    //     this.params.params = paramsArr;
    //     this.getData(key, paramsCode);
    //   }, 60000);
    // });
  },
  computed: {
    getSearchData() {
      return this.$store.getters.getSearchData;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer); // 取消定时器
  },

  methods: {
    removeComponent() {
      this.$emit("closeComm", "");
    },
    getDataPvInf(sessionName) {
      const getOldData = JSON.parse(sessionStorage.getItem(sessionName));
      let paramsType = [],
        paramsArr = [];
      let key = getOldData.code;
      let paramsCode = getOldData.paramsCode;
      paramsType.push(getOldData.code);
      paramsArr.push(paramsCode);
      this.params.paramType = paramsType;
      this.params.createdStartTime = "";
      this.params.createdEndTime = "";
      this.params.deviceId = getOldData.childrenId;
      this.params.params = paramsArr;
      console.log(this.params, " 光伏入参参数");
      this.getData(key, paramsCode);
    },
    getDataPv() {
      // console.log(this.getSearchData)
      let paramsType = [],
        paramsArr = [];
      let key = this.getSearchData.code;
      let paramsCode = this.getSearchData.paramsCode;
      paramsType.push(this.getSearchData.code);
      paramsArr.push(paramsCode);
      this.params.createdStartTime = "";
      this.params.createdEndTime = "";
      this.params.paramType = paramsType;
      this.params.deviceId = this.getSearchData.childrenId;
      this.params.params = paramsArr;
      this.getData(key, paramsCode);
      // console.log(this.params,"]]]]]]]]]]]]]]]]]]]]]]]]]]]")
    },

    getData(key, paramsCode) {
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = true;
        allEquipmentSearch(this.params).then(res => {
          this.isButtonDisabled = false;
          // console.log(res,"tttttttttttttttttttttttt")
          if (res.data[key].length != 0 && res.data[key] != undefined) {
            const data = res.data[key][0].data;
            this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
            let xAxisArr = [],
              seriesData = [],
              legendArr = [],
              lineData = [];
            res.data[key].forEach(element => {
              lineData.push(element[paramsCode]);
              xAxisArr.push(element.name);
              if (key == "PV") {
                legendArr.push(element.name.match(/\d+/g)[0]);
              } else {
                legendArr.push(element.name);
              }
            });
            seriesData.push({
              data: lineData,
              type: "line",
              smooth: true,
              areaStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(55,162,218,0.3)"
                  }, {
                    offset: 1,
                    color: "rgba(55,162,218,0)"
                  }], false),
                  shadowColor: "rgba(55,162,218, 0.5)",
                  shadowBlur: 1
                }
              }
            });
            const yAxisName = res.data.params[paramsCode] === "" ? "发电量" : `发电量(${res.data.params[paramsCode]})`;
            this.charts(xAxisArr, legendArr, "设备", yAxisName, seriesData);
          } else {
            this.$message.error("暂无数据");
            this.charts([], [], "设备", "", []);
          }
        }).catch(() => {
          this.isButtonDisabled = false;
        });
      }
    },
    charts(xAxisArr, legendArr, xAxisName, yAxisName, seriesData) {
      const chart = this.$echarts.init(this.$refs.chart1);
      chart.clear();
      chart.setOption({
        tooltip: {
          trigger: "axis",
          confine: true,
          enterable: true,
          //允许鼠标进入提示框浮层中，默认为false
          extraCssText: "max-height:83%;overflow:auto" //出现滚动条
        },

        legend: {
          width: 350,
          type: "scroll",
          top: "0px",
          left: "center",
          textStyle: {
            color: "#eee"
          },
          pageTextStyle: {
            // 设置上一页、下一页文字样式
            color: "#eee"
          },
          pageIconColor: "#eee",
          pageIconInactiveColor: "#999",
          data: legendArr
        },
        grid: {
          // top: legendArr.length <= 3 ? "25%" : "30%",
          top: legendArr.length <= 3 ? "10%" : "15%",
          bottom: "25%",
          left: "12%",
          right: "10%"
        },
        color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
        xAxis: {
          name: xAxisName,
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#d8d8d8",
              width: 1
            }
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 12,
            padding: [8, 0, 0, -15],
            verticalAlign: "top"
          },
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              return value.match(/\d+/g);
            }
          },
          data: xAxisArr
        },
        yAxis: {
          name: yAxisName,
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#c3d2dd",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,124,226,0.8)",
              type: "dotted"
            }
          }
        },
        dataZoom: [{
          type: "slider",
          start: 0,
          end: 20,
          bottom: 10,
          //下滑块距离x轴底部的距离
          height: 10,
          //下滑块手柄的高度调节
          fillerColor: "rgba(167,183,204,0.4)",
          textStyle: {
            color: "#eee"
          }
        }, {
          start: 0,
          end: 20,
          bottom: 10,
          //下滑块距离x轴底部的距离
          height: 10,
          //下滑块手柄的高度调节
          fillerColor: "rgba(167,183,204,0.4)",
          textStyle: {
            color: "#eee"
          }
        }],
        series: seriesData
      });

      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    }
  }
};