import Vue from "vue";
import App from "./App.vue";

// import api from './api/index.js'

import VueRouter from "vue-router";

import * as echarts from "echarts";
import * as Three from "three";

import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

Vue.use(VueRouter);

import store from "./store/index";

import "../src/css/iconfont/iconfont.css";
Vue.config.productionTip = false;

Vue.prototype.$echarts = echarts;
Vue.prototype.$three = Three;
// Vue.prototype.$api = api;
//
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
Vue.component("VueSlider", VueSlider);
// 仅在开发环境中引入
if (process.env.NODE_ENV === "development") {
  require("@/js/mock.js");
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
