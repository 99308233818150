//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { resetAlarmInfo } from "../api/charts";
export default {
  name: "AlarmDetail",
  data() {
    return {};
  },
  props: {
    alarmDetailInfo: {
      type: Object,
      required: false // 如果这个属性是必须的
    }
  },

  methods: {
    formatTimestamp(cellValue) {
      // console.log(cellValue, "cellValue");
      if (!cellValue) return "";
      const date = new Date(cellValue * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    showAlarmDetailInfo() {},
    onclickProcess() {
      console.log("一键处理@@@@@@@@@@@@@@");
      //0.异步执行
      //1.调用一键处理接口
      //2.隐藏本窗口
      const params = {
        id: this.alarmDetailInfo.id,
        status: 1
      };
      resetAlarmInfo(params).then(res => {
        console.log(res, "resetAlarmInfo res");
        if (res.data.code === 200) {
          this.$emit("closeAlarmDetailEvent", null);
        }
      });
    }
  },
  watch: {
    alarmDetailInfo() {
      console.log(this.alarmDetailInfo, "alramDetailInfo数据进来了");
      this.showAlarmDetailInfo();
    }
  }
};