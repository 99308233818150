import Vue from 'vue'
import Vuex from 'vuex'
// import twoOptions from './twoOptions'
Vue.use(Vuex)

const state = {
  searchData:{},
}

// 唯一取值state方法，计算属性
const getters = {
  getSearchData(state){
    return state.searchData
  }
}

// 唯一修改state方法，同步阻塞
const mutations = {
  updateSearchData(state,searchData){
    state.searchData = searchData
  },
}

// 异步调用mutations方法 dispatch调用
const actions = {
  asyncUpdateSearchData(context,searchData){
    context.commit('updateSearchData',searchData)
  }
}


// // 创建并暴露store
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
}) 
