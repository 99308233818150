import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//

import { Color, MOUSE, PerspectiveCamera, Scene, WebGLRenderer, Vector3, AmbientLight, DirectionalLight, Tween } from "three";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import TWEEN from '@tweenjs/tween.js';
export default {
  name: "Test",
  data() {
    return {
      camera: null,
      //相机对象
      scene: null,
      //场景对象
      renderer: null,
      //渲染器对象
      mesh: null,
      //网格模型对象Mesh
      sphere: null,
      sphereGeometry: new THREE.SphereGeometry(0.28, 32, 16),
      pathConvergeData: {
        //汇聚路线
        pathOne: [[22.032, -0.037, 45.586], [23.126, -0.037, 45.586], [23.126, -0.037, 13.071], [-24.632, -0.037, 13.071], [-24.636, -0.037, 3.037]],
        // [-24.508, - 0.037, - 58.862], [-18.557, -0.037, -58.717]
        pathTwo: [[-50.319, -0.037, 3.053], [-24.636, -0.037, 3.039], [-24.508, -0.037, -58.862], [-19.029, -0.037, -58.627]],
        pathThree: [[116.985, -0.037, -27.127], [119.117, -0.037, -27.127], [119.255, -0.037, 6.726], [-24.632, -0.037, 6.735]]
        // [-24.508, - 0.037, - 58.862], [-19.029, -0.037, -58.627]
      },

      pathDisperseData: {
        pathTwo: [[-19.029, -0.037, -58.627], [-24.508, -0.037, -58.862], [-24.636, -0.037, 3.039], [-50.319, -0.037, 3.053]],
        pathThree: [[-24.632, -0.037, 6.848], [119.255, -0.037, 6.726], [119.117, -0.037, -27.127], [116.985, -0.037, -27.127]],
        //[-19.029, - 0.037, -58.627], [-24.508,  - 0.037, - 58.862],
        pathOne: [[-24.636, -0.037, 3.039], [-24.632, -0.037, 13.071], [23.126, -0.037, 13.071], [23.126, -0.037, 45.586], [22.032, -0.037, 45.586]]
        // [-24.508, - 0.037, - 58.862], [-18.557, -0.037, -58.717]
      },

      timers: [],
      tweens: []
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.timers.forEach(el => clearInterval(el));
    this.tweens.forEach(el => el.stop());
    this.scene.traverse(obj => {
      if (!obj.isMesh) return;
      obj.geometry.dispose();
      obj.material.dispose();
    });
    this.scene = null;
  },
  methods: {
    moveObjectBetweenCoordinates(coordinateSystem1, coordinateSystem2, speed, object, p = null) {
      const distance = object.position.distanceTo(coordinateSystem2);
      const time = distance / speed;
      const tween = new TWEEN.Tween(coordinateSystem1).to(coordinateSystem2, time).easing(TWEEN.Easing.Linear.None).onUpdate(e => {
        object.position.set(e.x, e.y, e.z);
        const c1 = new THREE.Color('#ff0000');
        const c2 = new THREE.Color(0x00ffff);
        const timeDifference = Date.parse(new Date()) - object.userData.startTime;
        const percent = timeDifference / 27000; //点索引值相对所有点数量的百分比
        //根据顶点位置顺序大小设置颜色渐变                 
        const c = c1.clone().lerp(c2, percent); //颜色插值计算                  
        const material = new THREE.MeshBasicMaterial({
          color: c
        });
        // const material = new THREE.SpriteMaterial({ map: null, color: c, side: THREE.DoubleSide });
        object.material = material;
      }).start();
      tween.onComplete(() => {
        if (p) {
          this.createObjM(p.obj, object, p.path, p.indexD + 1);
        }
      });
      this.tweens.push(tween);
    },
    start() {
      this.objectMotion(this.pathConvergeData, 'sb-gd-01');
      this.objectMotion(this.pathDisperseData, 'sb-gd-022');
    },
    loadModels() {
      const loader = new GLTFLoader();
      loader.load('/static/b.gltf', obj => {
        this.scene.add(obj.scene);
        this.scene.traverse(el => {
          if (el.name == 'sb-gd-01') {
            el.material.transparent = true;
            const gd = el.clone();
            gd.name = 'sb-gd-022';
            gd.position.y = el.position.y + 2;
            this.scene.add(gd);
          }
        });
      });
    },
    createObjM(obj, sphere, path, indexD) {
      if (path[indexD]) {
        this.moveObjectBetweenCoordinates({
          x: path[indexD - 1][0],
          y: path[indexD - 1][1],
          z: path[indexD - 1][2]
        }, {
          x: path[indexD][0],
          y: path[indexD][1],
          z: path[indexD][2]
        }, 0.01, sphere, {
          obj,
          path,
          indexD
        });
      } else {
        console.log('删除');
        // this.scene.remove(sphere)
      }
    },

    objectMotion(pathData, name) {
      this.scene.traverse(el => {
        if (el.name == name) {
          el.material.opacity = 0.3;
          const timer = setInterval(() => {
            for (let key in pathData) {
              const material = new THREE.MeshBasicMaterial({
                color: new THREE.Color('#ff0000')
              });
              const sphere = new THREE.Mesh(this.sphereGeometry, material);
              sphere.userData.startTime = Date.parse(new Date());
              sphere.userData.isSphere = true;
              sphere.position.set(...pathData[key][0]);
              el.add(sphere);
              this.createObjM(el, sphere, pathData[key], 1);
            }
          }, 1000);
          this.timers.push(timer);
        }
      });
    },
    stopObjMove() {
      this.tweens.forEach(el => el.stop());
      this.timers.forEach(el => clearInterval(el));
      this.scene.traverse(obj => {
        if (obj.name == 'sb-gd-01' || obj.name == 'sb-gd-022') {
          obj.children = [];
          obj.material.opacity = 1;
        }
      });
    },
    init() {
      let container = document.getElementById('three-canvas');
      this.renderer = new WebGLRenderer({
        //开启抗锯齿
        antialias: true
      });
      //将渲染器挂载到dom
      container.appendChild(this.renderer.domElement);
      this.renderer.setSize(container.offsetWidth, container.offsetHeight, true);
      //实例化场景
      this.scene = new Scene();
      this.scene.background = new Color('#88B9DD');
      //实例化相机
      this.camera = new PerspectiveCamera(45, container.offsetWidth / container.offsetHeight, 1, 1000);
      //设置相机位置
      this.camera.position.set(50, 50, 50);
      //设置相机看先中心点
      this.camera.lookAt(new Vector3(0, 0, 0));
      //设置相机自身方向
      this.camera.up = new Vector3(0, 1, 0);
      //初始化轨道控制器
      let orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
      //设置鼠标功能键
      orbitControls.mouseButtons = {
        //左键无功能
        LEFT: null,
        //中键缩放
        MIDDLE: MOUSE.DOLLY,
        //右键旋转
        RIGHT: MOUSE.ROTATE
      };
      //添加环境光
      const directionalLight = new DirectionalLight(0xffffff, 5);
      this.scene.add(directionalLight);
      const light = new AmbientLight(0x404040, 10); // 柔和的白光
      this.scene.add(light);
      const ambientLight = new AmbientLight('rgb(255,255,255)', 8);
      this.scene.add(ambientLight);
      let animate = () => {
        this.renderer.render(this.scene, this.camera);
        requestAnimationFrame(animate);
        TWEEN.update();
      };
      animate();
      this.loadModels();
      let that = this;
      this.$nextTick(() => {
        that.$refs.clickA.click();
        that.start();
        that.objectMotion(that.pathConvergeData, 'sb-gd-01');
        that.objectMotion(that.pathDisperseData, 'sb-gd-022');
      });
    }
  }
};