import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allEquipmentSearch } from "../../../api/charts";
export default {
  props: {
    deviceType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "",
      params: {},
      xAxisArr: [],
      chartData: [],
      isButtonDisabled: false
    };
  },
  mounted() {},
  computed: {
    getSearchData() {
      return this.$store.getters.getSearchData;
    }
  },
  methods: {
    removeComponent() {
      this.$emit("closeComm", "");
    },
    getDataInfo(sessionName) {
      console.log(sessionName, "sessionName");
      const getOldData = JSON.parse(sessionStorage.getItem(sessionName));
      let paramsType = [],
        paramsArr = [];
      let key = getOldData.code;
      let paramsCode = getOldData.paramsCode;
      paramsType.push(getOldData.code);
      paramsArr.push(paramsCode);
      //  console.log(getOldData, "getoldData INFO");
      // 将字符串转换为 Date 对象
      let startTime = new Date(getOldData.chartTimeRange[0]).getTime().toString();
      let endTime = new Date(getOldData.chartTimeRange[1]).getTime().toString();
      this.params.createdStartTime = startTime.substring(0, startTime.length - 3);
      this.params.createdEndTime = endTime.substring(0, endTime.length - 3);
      this.params.paramType = paramsType;
      this.params.deviceId = getOldData.childrenId;
      this.params.params = paramsArr;
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = true;
        allEquipmentSearch(this.params).then(res => {
          this.isButtonDisabled = false;
          const resultData = res.data[key];
          if (resultData && resultData.length !== 0) {
            const firstItem = resultData[0];
            this.title = firstItem.name.match(/[\u4e00-\u9fa5]+/g).join("");
            const xAxisArr = firstItem.data.map(item => item.createTime);
            const legendArr = resultData.map(element => {
              return getOldData.code === "PV" ? element.name.match(/\d+/g)[0] : element.name;
            });
            const seriesData = resultData.map(element => ({
              name: getOldData.code === "PV" ? element.name.match(/\d+/g)[0] : element.name,
              data: element.data.map(item => item[paramsCode]),
              type: "line",
              smooth: true,
              areaStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(55,162,218,0.3)"
                  }, {
                    offset: 1,
                    color: "rgba(55,162,218,0)"
                  }], false),
                  shadowColor: "rgba(55,162,218, 0.5)",
                  shadowBlur: 1
                }
              }
            }));
            const yAxisName = res.data.params[paramsCode] ? `${getOldData.params}(${res.data.params[paramsCode]})` : getOldData.params;
            this.charts(xAxisArr, legendArr, "时间/h", yAxisName, seriesData, getOldData.code);
          } else {
            this.$message.error("暂无数据");
            this.charts([], [], "时间/h", "", [], getOldData.code);
          }
        }).catch(() => {
          this.isButtonDisabled = false;
        });
      }
    },
    getData1() {
      // console.log(this.getSearchData)
      let paramsType = [],
        paramsArr = [];
      let key = this.getSearchData.code;
      let paramsCode = this.getSearchData.paramsCode;
      paramsType.push(this.getSearchData.code);
      paramsArr.push(paramsCode);
      let startTime = this.getSearchData.chartTimeRange[0].getTime().toString();
      let endTime = this.getSearchData.chartTimeRange[1].getTime().toString();
      this.params.createdStartTime = startTime.substring(0, startTime.length - 3);
      this.params.createdEndTime = endTime.substring(0, endTime.length - 3);
      this.params.paramType = paramsType;
      this.params.deviceId = this.getSearchData.childrenId;
      this.params.params = paramsArr;
      allEquipmentSearch(this.params).then(res => {
        //  console.log(res, "tttttttttttttttttttttttt");
        if (res.data[key].length != 0 && res.data[key] != undefined) {
          const data = res.data[key][0].data;
          this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
          let xAxisArr = [],
            seriesData = [],
            legendArr = [];
          res.data[key].forEach(element => {
            let lineData = [];
            element.data.forEach(item => {
              lineData.push(item[paramsCode]);
            });
            if (this.getSearchData.code == "PV") {
              legendArr.push(element.name.match(/\d+/g)[0]);
            } else {
              legendArr.push(element.name);
            }
            seriesData.push({
              name: this.getSearchData.code == "PV" ? element.name.match(/\d+/g)[0] : element.name,
              data: lineData,
              type: "line",
              smooth: true,
              areaStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(55,162,218,0.3)"
                  }, {
                    offset: 1,
                    color: "rgba(55,162,218,0)"
                  }], false),
                  shadowColor: "rgba(55,162,218, 0.5)",
                  shadowBlur: 1
                }
              }
            });
          });
          data.forEach(element => {
            xAxisArr.push(element.createTime);
          });
          let yAxisName = res.data.params[paramsCode] == "" ? this.getSearchData.params : this.getSearchData.params + "(" + res.data.params[paramsCode] + ")";
          this.charts(xAxisArr, legendArr, "时间/h", yAxisName, seriesData, this.getSearchData.code);
        } else {
          this.$message.error("暂无数据");
          this.charts([], [], "时间/h", "", [], this.getSearchData.code);
        }
      }).catch();
    },
    charts(xAxisArr, legendArr, xAxisName, yAxisName, seriesData, isPv) {
      console.log(isPv, "ispv");
      const chart = this.$echarts.init(this.$refs.chart1);
      chart.clear();
      if (isPv == "PV") {
        chart.setOption({
          tooltip: {
            trigger: "axis",
            confine: true,
            enterable: true,
            //允许鼠标进入提示框浮层中，默认为false
            extraCssText: "max-height:83%;overflow:auto" //出现滚动条
          },

          legend: {
            width: 350,
            type: "scroll",
            top: "0px",
            left: "center",
            textStyle: {
              color: "#eee"
            },
            pageTextStyle: {
              // 设置上一页、下一页文字样式
              color: "#eee"
            },
            pageIconColor: "#eee",
            pageIconInactiveColor: "#999",
            data: legendArr
          },
          grid: {
            // top: legendArr.length <= 3 ? "25%" : "30%",
            top: legendArr.length <= 3 ? "15%" : "20",
            bottom: "17%",
            left: "12%",
            right: "10%"
          },
          color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
          xAxis: {
            name: xAxisName,
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#d8d8d8",
                width: 1
              }
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 12,
              padding: [8, 0, 0, -15],
              verticalAlign: "top"
            },
            data: xAxisArr
          },
          yAxis: {
            name: yAxisName,
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#c3d2dd",
                width: 1
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0,124,226,0.8)",
                type: "dotted"
              }
            }
          },
          series: seriesData
        });
      } else {
        chart.setOption({
          tooltip: {
            trigger: "axis"
          },
          legend: {
            top: "0px",
            left: "center",
            textStyle: {
              color: []
            },
            data: legendArr
          },
          grid: {
            //top: legendArr.length <= 3 ? "28%" : "40%",
            top: legendArr.length <= 3 ? "20%" : "30%",
            bottom: "17%",
            left: "12%",
            right: "10%"
          },
          color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
          xAxis: {
            name: xAxisName,
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#d8d8d8",
                width: 1
              }
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 12,
              padding: [8, 0, 0, -15],
              verticalAlign: "top"
            },
            data: xAxisArr
          },
          yAxis: {
            name: yAxisName,
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#c3d2dd",
                width: 1
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0,124,226,0.8)",
                type: "dotted"
              }
            }
          },
          series: seriesData
        });
      }
      console.log("新增监视，修复echarts自适应的问题");
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    }
  }
};