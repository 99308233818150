//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "headerCom",
  data() {
    return {
      //表示每个导航窗口的选中状态
      titleSelectState: [{
        isSelected: true
      }, {
        isSelected: false
      }]
    };
  },
  components: {},
  watch: {},
  methods: {
    onclickLiItem() {
      this.changeSelcetState(0);
    },
    onclickLiItem2() {
      this.changeSelcetState(1);
    },
    changeSelcetState(liindex) {
      this.titleSelectState[liindex].isSelected = true;
      this.titleSelectState.forEach((item, index) => {
        if (index != liindex) {
          item.isSelected = false;
        }
      });
      //通过emit上传liindex。铜鼓inlidex控制某些标签页的显示
      this.$emit("changeHeader", liindex);
    }
  }
};