//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { ElButton, ElDialog } from "element-ui";

export default {
  data() {
    return {
      dialogVisible: false
    };
  },
  components: {
    // ElButton,
    // ElDialog,
  },
  methods: {
    closeDialog() {
      console.log("单击了关闭按钮");
      this.$emit("closeSimulateControl", null);
    },
    handleClose() {
      console.log("单击了关闭按钮");
      this.$emit("closeSimulateControl", null);
    }
  }
};