import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allEquipmentSearch } from "../../../api/charts";
export default {
  props: {
    deviceType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "",
      xAxisArr: [],
      eValueData: [],
      pValueData: [],
      tValueData: [],
      params: {},
      timer: null,
      isButtonDisabled: false
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.timer = setInterval(() => {
    //     const getOldData = JSON.parse(sessionStorage.getItem("barData"));
    //     // console.log(getOldData,"getOldData")
    //     let paramsType = [],
    //       paramsArr = [];
    //     let key = getOldData.code;
    //     let paramsCode = getOldData.paramsCode;
    //     paramsType.push(getOldData.code);
    //     paramsArr.push(paramsCode);
    //     this.params.paramType = paramsType;
    //     this.params.createdStartTime = "";
    //     this.params.createdEndTime = "";
    //     this.params.deviceId = getOldData.childrenId;
    //     this.params.params = paramsArr;
    //     let yAxisName = getOldData.params;
    //     console.log("一分钟到了，要执行一次数据更新了");
    //     this.getData(key, paramsCode, yAxisName);
    //   }, 60000);
    // });
  },
  beforeDestroy() {
    clearInterval(this.timer); // 取消定时器
  },

  computed: {
    getSearchData() {
      return this.$store.getters.getSearchData;
    }
  },
  methods: {
    removeComponent() {
      this.$emit("closeComm", "");
    },
    getDataInfo(sessionName) {
      const getOldData = JSON.parse(sessionStorage.getItem(sessionName));
      let paramsType = [],
        paramsArr = [];
      let key = getOldData.code;
      let paramsCode = getOldData.paramsCode;
      paramsType.push(getOldData.code);
      paramsArr.push(paramsCode);
      this.params.paramType = paramsType;
      this.params.createdStartTime = "";
      this.params.createdEndTime = "";
      this.params.deviceId = getOldData.childrenId;
      this.params.params = paramsArr;
      let yAxisName = getOldData.params;
      this.getData(key, paramsCode, yAxisName, getOldData);
    },
    getData4() {
      console.log(this.getSearchData, "进入getDate4");
      let paramsType = [],
        paramsArr = [];
      let key = this.getSearchData.code;
      let paramsCode = this.getSearchData.paramsCode;
      paramsType.push(this.getSearchData.code);
      paramsArr.push(paramsCode);
      this.params.paramType = paramsType;
      this.params.createdStartTime = "";
      this.params.createdEndTime = "";
      this.params.deviceId = this.getSearchData.childrenId;
      this.params.params = paramsArr;
      let yAxisName = this.getSearchData.params;
      this.getData(key, paramsCode, yAxisName, this.getSearchData);
    },
    getData(key, paramsCode, yAxisNames, dataInfo) {
      //console.log("进入getDate");
      // console.log(key,paramsCode,"key,paramsCode")
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = true;
        allEquipmentSearch(this.params).then(res => {
          // console.log(res,"res无时间柱状图返回值")
          // console.log("进入getDate,收到返回数据res", res);
          this.isButtonDisabled = false;
          if (res.data[key].length != 0 && res.data[key] != undefined) {
            const data = res.data[key];
            this.title = res.data[key][0].name.match(/[\u4e00-\u9fa5]+/g).join("");
            let xAxisArr = [],
              seriesData = [],
              barData = [];
            data.forEach(element => {
              xAxisArr.push(element.name);
              barData.push(element[paramsCode]);
            });
            seriesData.push({
              // name: element.name,
              barWidth: 10,
              type: "bar",
              data: barData
            });
            let yAxisName = res.data.params[paramsCode] == "" ? yAxisNames : yAxisNames + "(" + res.data.params[paramsCode] + ")";
            console.log(xAxisArr, yAxisName, seriesData);
            this.charts(xAxisArr, "设备名称", yAxisName, seriesData);
          } else {
            this.title = dataInfo.radio;
            let xAxisArr = [];
            dataInfo.children.forEach(element => {
              xAxisArr.push(element);
            });
            this.charts(xAxisArr, "设备名称", dataInfo.params, []);
            this.$message.error("暂无数据");
          }
        }).catch(() => {
          this.isButtonDisabled = false;
        });
      }
    },
    charts(xAxisArr, xAxisName, yAxisName, seriesData) {
      //console.log("charts()初始化", this.$refs);
      const chart = this.$echarts.init(this.$refs.chart2);
      chart.clear();
      chart.setOption({
        tooltip: {
          trigger: "axis"
        },
        grid: {
          top: "20%",
          bottom: window.innerHeight > 900 ? "20%" : "25%",
          left: "15%",
          right: "10%"
        },
        color: ["#006cd6", "#019eff", "#33eaf5", "#11d613", "#fd9a3b", "#fdfb39"],
        xAxis: [{
          name: this.returnName(xAxisName),
          type: "category",
          data: xAxisArr,
          axisPointer: {
            type: "shadow"
          },
          axisLine: {
            lineStyle: {
              color: "#d8d8d8",
              width: 1
            }
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 12,
            padding: [8, 0, 0, -15],
            verticalAlign: "top"
          },
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              var ret = ""; //拼接加\n返回的类目项
              var maxLength = 4; //每项显示文字个数
              var valLength = value.length; //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1) {
                //如果类目项的文字大于5,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; //每次截取的字符串
                  var start = i * maxLength; //开始截取的位置
                  var end = start + maxLength; //结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }

                return ret;
              } else {
                return value;
              }
            }
          }
        }],
        yAxis: [{
          name: yAxisName,
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#c3d2dd",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,124,226,0.8)",
              type: "dotted"
            }
          }
        }],
        series: seriesData
      });
      //新增监视，修复echarts自适应的问题
      window.addEventListener("resize", () => {
        if (chart) {
          chart.resize();
        }
      });
    },
    returnName(name) {
      const len = name.length;
      if (len > 2) {
        return name.substring(0, 2) + "\n" + name.substring(2, 4);
      }
    }
  }
};