import request from "@/utils/request";

// 搜索条件列表
export function deviceList(params = {}, config = { showLoading: false }) {
  return request({
    method: "GET",
    url: "/device/information/list",
    params,
    config: config,
  });
}

/* 
  两侧图表搜索功能
  参数：
  paramType[]        string     设备类型(gasBoiler:燃气锅炉,steamer:蒸锅,caloric_recup:余热回收系统,waterPump:高温水源热泵机房,PV:光伏系统,airPump:空气源热泵)
  createdStartTime   string     查询开始时间（示例：1677600000）
  createdEndTime     string     查询结束时间（示例：1677686400）
  deviceId[]         int        子设备Id
*/
export function allEquipmentSearch(params, config = { showLoading: false }) {
  return request({
    method: "GET",
    url: "/device/data",
    params,
    config: config,
  });
}

/* 
  点击弹出设备信息 
  参数：
  paramType           string     设备类型(gasBoiler:燃气锅炉,steamer:蒸锅,caloric_recup:余热回收系统,waterPump:高温水源热泵机房,PV:光伏系统,airPump:空气源热泵)
  deviceId[]          int        子设备Id
*/
export function equipmentMsg(params = {}, config = { showLoading: false }) {
  return request({
    method: "GET",
    url: "/device/single/data",
    params,
    config: config,
  });
}

/* 
  筛选子设备
  参数：
  deviceType         string     设备类型(gasBoiler:燃气锅炉,steamer:蒸锅,caloric_recup:余热回收系统,waterPump:高温水源热泵机房,PV:光伏系统,airPump:空气源热泵)
  param              string     参数（steamVolume,stearmCaloric等）
  paramType          string     参数类型（greaterThan,lessThan,equal,range）
  paramValue         int        参数值 
*/
export function filterChildEquipment(
  params = {},
  config = { showLoading: false }
) {
  return request({
    method: "GET",
    url: "/device/son/information/list",
    params,
    config: config,
  });
}

/*
  时间轴 - 设备单个所有数据
  参数：
  paramType          string     设备类型(gasBoiler:燃气锅炉,steamer:蒸锅,caloric_recup:余热回收系统,waterPump:高温水源热泵机房,PV:光伏系统,airPump:空气源热泵)
  deviceId[]         int        子设备Id
  createdStartTime   string     查询开始时间（示例：1677600000）
  createdEndTime     string     查询结束时间（示例：1677686400）
*/
export function timelineData(params = {}, config = { showLoading: true }) {
  return request({
    method: "GET",
    url: "/device/single/all/data",
    params,
    config: config,
  });
}
//根据分页获取设备所有数据
export function timelineDataByPage(
  params = {},
  config = { showLoading: true }
) {
  return request({
    method: "GET",
    url: "/device/single/list/data",
    params,
    config: config,
  });
}

// 右上角信息接口
export function msgData(params = {}, config = { showLoading: false }) {
  return request({
    method: "GET",
    url: "/device/day/data",
    params,
    config: config,
  });
}

// 语音转文字
export function voiceToWord(params = {}, config = { showLoading: false }) {
  return request({
    method: "GET",
    url: "/device/son/by/word",
    params,
    config: config,
  });
}

// 语音转图表
export function voiceToChart(params = {}, config = { showLoading: false }) {
  return request({
    method: "GET",
    url: "/device/by/word/data",
    params,
    config: config,
  });
}
export function getAlarmsType(params = {}, config = { showLoading: false }) {
  return request({
    method: "GET",
    url: "/device/getAlarmTypeList",
    params,
    config: config,
  });
}
export function resetAlarmInfo(params = {}, config = { showLoading: true }) {
  return request({
    method: "POST",
    url: "/device/alarmMessageHandle",
    data: params,
    config: config,
  });
}
